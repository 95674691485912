import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import SpinnerButton from "Components/Common/Fields/SpinnerButton";
import TextArea from "Components/Common/Fields/TextArea";
import useEscapeClose from "Components/Common/Hooks/useEscapeClose";
import { useFormik } from "formik";
import { isValidObject, showErrorMsg, showSuccessMsg } from "Modules/util";
import { Fragment } from "react";
import { useLeadFollowUPMessageEditMutation } from "Redux/Leads/lead";
import * as Yup from "yup";

export default function AutoFollowUpEditModal(props) {
  const { isOpen, setIsOpen, message, leadId } = props;

  const [editFollowUpMessage, { isLoading: isEditFollowUpMessageLoading }] =
    useLeadFollowUPMessageEditMutation();

  useEscapeClose(setIsOpen);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      followUpMessage: message,
      leadId: leadId,
    },
    validationSchema: Yup.object().shape({
      followUpMessage: Yup.string().required(
        "Auto Follow-up Message Is Required"
      ),
    }),
    onSubmit: (values) => {
      handleEditFollowUpMessage(values);
      setIsOpen(false);
    },
  });

  async function handleEditFollowUpMessage(values) {
    try {
      const res = await editFollowUpMessage(values);
      const editAutoFollowUpResponse = res?.data?.data?.leadFollowUPMessageEdit;
      if (isValidObject(editAutoFollowUpResponse)) {
        const { type, message } = editAutoFollowUpResponse;
        if (type === "success") {
          showSuccessMsg(message);
        } else if (type === "error") {
          showErrorMsg(message);
        }
      } else {
        showErrorMsg("Error occurred while edit auto follow-up message!");
      }
    } catch (error) {
      showErrorMsg("Error occurred while edit auto follow-up message");
    }
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[9999]" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-5xl border-b-2 py-2 transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-5 ">
                <div className="mt-1 border-b-2 py-2 sm:mt-0 flex justify-between items-center">
                  <div className="sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-800 flex justify-between items-center"
                    >
                      Auto Follow-up Preview
                    </Dialog.Title>
                  </div>
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 "
                    onClick={() => {
                      setIsOpen(false);
                      formik.resetForm();
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-5">
                  <TextArea
                    label=""
                    name="followUpMessage"
                    formik={formik}
                    className="border-1 border-gray-300 bg-gray-100 placeholder-gray-700 "
                    rows={6}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="followUpMessage"
                    placeholder="Preview Message"
                    isNotRemoveExtraSpace={true}
                  />
                </div>
                <div className="flex justify-end pt-2">
                  <SpinnerButton
                    className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                    title={"Save Preview"}
                    action={() => formik.handleSubmit()}
                    type={"button"}
                    loading={isEditFollowUpMessageLoading}
                    isDisable={
                      isEditFollowUpMessageLoading ||
                      isValidObject(formik?.errors) ||
                      !formik?.dirty
                    }
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
