import { Dialog, Transition } from "@headlessui/react";
import SelectField from "Components/Common/Fields/SelectField";
import SpinnerButton from "Components/Common/Fields/SpinnerButton";
import { useFormik } from "formik";
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  addTimeToDate,
  convertDate,
  convertDatetoDateAndtime,
  dateButtonData,
  defaultTodoMatch,
  formatDatestamp,
  formatTimestamp,
  getTodoList,
} from "./assetData";
import {
  getTimeZone,
  isValidArray,
  isValidObject,
  showErrorMsg,
  showSuccessMsg,
  showTaskDateFormat,
  showTaskDateTimeFormat,
} from "Modules/util";
import {
  useAddTaskMutation,
  useAddTodoMutation,
  useEditTaskMutation,
  useGetTaskMutation,
} from "Redux/Task/TaskQuery";
import { addTaskValidationForm } from "Modules/validation";
import {
  CheckIcon,
  ListBulletIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import moment from "moment-timezone";
import ActionButton from "Components/Common/Fields/ActionButton";
import SearchLeadSelect from "Components/Common/Fields/SearchLeadSelect";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import Skeleton from "Components/Common/Fields/Skeleton";

const AddTaskForm = ({
  isOpen,
  setIsOpen,
  editTaskId = null,
  refetch,
  isLeadClear,
  listTodosState,
  isRefetch,
  lead = null,
  fromLeadDeatils = false,
}) => {
  // Use Redux query hooks to fetch data from the backend
  // Define mutation hooks for adding, editing tasks, and adding todos
  const [addTask] = useAddTaskMutation();
  const [
    getTask,
    { data: TaskData, isLoading: isTaskLoading, isSuccess: isTaskSuccess },
  ] = useGetTaskMutation();
  const [editTask] = useEditTaskMutation();
  const [addTodo] = useAddTodoMutation();
  const editTaskData = isTaskSuccess ? TaskData?.data?.task?.response[0] : {};

  const { refetchTodoList, isListSuccess, getListTodo } = listTodosState;

  // Define local state variables
  const [listTodo, setListTodo] = useState([]);
  const [activeDateButton, setActiveDateButton] = useState("");
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [selectTime, setSelectTime] = useState(false);
  const [dayToAdd, setdayToAdd] = useState("");
  const [selectedDate, setSelectedDate] = useState(false);
  const [selectedLeadData, setSelectedLeadData] = useState([]);
  const dateInputRef = useRef(null); // Define useRef for handling date picker input
  const agentList = useSelector((s) => s?.option?.agent);
  const navigate = useNavigate();

  useEffect(() => {
    // Define the event handler function for the Escape key press
    function handleKeyDown(event) {
      // Check if the pressed key is the Escape key
      if (event?.key === "Escape") {
        // Set the modal state to closed (false)
        isOpen && setIsOpen(false);
      }
    }

    // Add the keydown event listener when the component mounts
    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]); // Dependency array to re-run the effect if setIsOpen changes

  // Handle selected Date bg-color on modal open
  useEffect(() => {
    if (isOpen) {
      setSelectedDate(false);
    }
  }, [isOpen]);

  function getAgentId() {
    let agentId = editTaskId
      ? editTaskData?.Agent?._id
      : lead?.agentResponse?.[0]?._id
      ? lead?.agentResponse?.[0]?._id
      : lead?.agent?._id
      ? lead?.agent?._id
      : "";
    return agentId;
  }

  function getLeadId() {
    let leadId = editTaskId
      ? editTaskData?.Lead?._id
      : lead?.leadResponse?.[0]?._id
      ? lead?.leadResponse?.[0]?._id
      : lead?._id
      ? lead?._id
      : "";
    return leadId;
  }

  // Use Formik for form state management
  const formik = useFormik({
    initialValues: {
      agent: getAgentId(),
      date: editTaskId ? formatDatestamp(editTaskData?.date) : "",
      description: editTaskId ? editTaskData?.description : "",
      leadId: getLeadId(),
      changeTime: false,
      isLeadAgent: true,
      toDoId: editTaskId
        ? editTaskData?.Todos?._id
        : // Map defaultTodoMatch values to corresponding listTodo values
        isValidArray(listTodo)
        ? defaultTodoMatch
            ?.map(
              (value) =>
                listTodo?.find((option) => option?.label === value)?.value
            )
            ?.find((value) => value !== undefined)
        : "",
    },
    validationSchema: addTaskValidationForm(),
    onSubmit: async (values) => {
      if (formik?.values?.toDoId === "defaultCustomType") {
        formik.setFieldValue("toDoId", "");
        return;
      }
      setIsSaveLoading(true);
      const { customInput, changeTime, ...value } = values;
      try {
        let res = null;
        function removeEmptyAgent(obj) {
          if (obj.agent === "") {
            delete obj.agent;
          }
          return obj;
        }
        if (editTaskId) {
          res = await editTask({
            _id: editTaskId,
            isTime: selectTime,
            ...removeEmptyAgent(value),
          });
        } else {
          res = await addTask({
            ...removeEmptyAgent(value),
            isTime: selectTime,
          });
        }
        const responseData =
          res?.data?.data?.[editTaskId ? "updateTask" : "addTask"];
        setIsSaveLoading(false);
        if (responseData) {
          if (responseData?.type === "success") {
            setIsOpen(false);
            // navigate("");
            refetch();
            showSuccessMsg(responseData?.message);
          } else if (responseData?.type === "error") {
            showErrorMsg(responseData?.message);
          }
        }
      } catch (error) {
        showErrorMsg("An error occurred while Task.");
        setIsSaveLoading(false);
        setIsOpen(false);
        // navigate("");
      }
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (
      formik?.values?.agent === selectedLeadData?.agent?._id ||
      formik?.values?.agent === lead?.agentResponse?.[0]?._id ||
      formik?.values?.agent === selectedLeadData?.agentResponse?.[0]?._id
    ) {
      formik?.setFieldValue("isLeadAgent", true);
    } else {
      formik?.setFieldValue("isLeadAgent", false);
    }
  }, [formik.values.agent, selectedLeadData]);

  // Define functions for handling button clicks and selecting dates
  const handleButtonClick = (daysToAdd, isSelectTime, isCheckboxClick) => {
    setdayToAdd(daysToAdd);
    // Get the current date in the specified time zone
    const currentDate = moment.tz(
      isCheckboxClick ? formik.values?.date || moment() : moment(),
      getTimeZone()
    );
    const newDate = currentDate;
    // Add days to the date while considering the time zone
    if (!isCheckboxClick) {
      const newDateWithDaysAdded = newDate.add(daysToAdd, "days");
      formik.setFieldValue("date", newDateWithDaysAdded.toISOString());
      setActiveDateButton(daysToAdd);
    }
  };

  // Show the date picker when clicking on "Select Date"
  const handleSelectDateClick = () => {
    if (dateInputRef.current) {
      dateInputRef.current.showPicker();
    }
  };

  // Function for adding a custom todo
  const handleAddTodo = async () => {
    let res = await addTodo({
      title: formik?.values?.customInput,
    });
    const responseData = res?.data?.data?.addToDo;
    if (responseData) {
      if (responseData?.type === "success") {
        showSuccessMsg(responseData?.message);
        refetchTodoList();
        formik?.setFieldValue("toDoId", res?.data?.data?.addToDo?.id);
      } else if (responseData?.type === "error") {
        showErrorMsg(responseData?.message);
      }
    }
  };

  useEffect(() => {
    if (isListSuccess) {
      setListTodo(getTodoList(getListTodo));
    }
  }, [isListSuccess, getListTodo]);

  useEffect(() => {
    if (isOpen) {
      formik.resetForm();
      if (editTaskId) {
        // setActiveDateButton("");
        getTask({ _id: editTaskId });
      } else {
        const currentDate = moment.tz(getTimeZone()).toDate(); // Get current date in the specified time zone
        const newDate = moment.tz(currentDate, getTimeZone()).toDate();
        setActiveDateButton(0);
        setSelectTime(false);
        formik.setFieldValue("date", newDate.toISOString());
      }
    }
    // if (isOpen) {
    //   navigate(`${editTaskId ? "#edit" : "#create"}Task`);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (isTaskSuccess) {
      setSelectTime(editTaskData?.isTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editTaskData]);

  const handleTimeChange = (newTime) => {
    formik.setFieldValue("date", addTimeToDate(formik?.values?.date, newTime));
  };
  const DaysFromCurrent = (inputDate) => {
    if (!inputDate) {
      return -1;
    }
    // Assume getTimeZone() correctly retrieves the desired timezone
    const timeZone = getTimeZone();
    // Get current Moment.js object with the specified time zone
    const currentDate = moment().tz(timeZone).startOf("day");
    // Parse inputDate into Moment.js object with the specified time zone
    const inputMoment = moment(inputDate).tz(timeZone).startOf("day");
    // Calculate the difference in days between inputDate and current date
    const diffDays = inputMoment.diff(currentDate, "days");
    return diffDays;
  };

  useEffect(() => {
    // Check if editTaskData exists and has a date value
    if (editTaskData?.date) {
      // Calculate the number of days from the current date
      const dayFromCurrent = DaysFromCurrent(editTaskData?.date ?? "");
      // Define an array of days to check against
      const myDays = [0, 1, 2, 3, 7, 14, 21, 30, 60, 90, 180, 365];
      // Check if dayFromCurrent is not in the array myDays
      if (!myDays?.includes(dayFromCurrent)) {
        setSelectedDate(true); // Set selected date state to true
      } else {
        setSelectedDate(false); // Set selected date state to false
      }
      setActiveDateButton(dayFromCurrent); // Set active date button based on dayFromCurrent
    }
  }, [editTaskData?.date]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[999]" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform overflow-visible rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-4">
                  <Dialog.Title
                    as="div"
                    className="text-lg font-medium leading-6 text-gray-900 flex justify-between items-center mb-3"
                  >
                    <h6 className="flex">
                      <ListBulletIcon className="w-6 text-orange-500 text-semibold mr-2" />
                      {editTaskId ? "Edit " : "Create "}
                      Task
                    </h6>
                    <button
                      onClick={() => {
                        setIsOpen(false);
                        // navigate("");
                      }}
                      className="focus:outline-none"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-gray-500 hover:text-gray-700 transition duration-300"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3.293 3.293a1 1 0 011.414 0L10 8.586l5.293-5.293a1 1 0 111.414 1.414L11.414 10l5.293 5.293a1 1 0 01-1.414 1.414L10 11.414l-5.293 5.293a1 1 0 01-1.414-1.414L8.586 10 3.293 4.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </Dialog.Title>
                  {isTaskLoading ? (
                    <Skeleton counter={5} height={"100px"} />
                  ) : (
                    <form
                      className="p-2 mb-2"
                      onSubmit={formik.handleSubmit}
                      noValidate
                    >
                      {/* Lead */}
                      <div className="mb-4">
                        <SearchLeadSelect
                          formik={formik}
                          isLeadClear={isLeadClear}
                          editLeadId={getLeadId()}
                          setFieldValue={formik.setFieldValue}
                          name="leadId"
                          selectedLeadData={selectedLeadData}
                          setSelectedLeadData={setSelectedLeadData}
                        />
                      </div>
                      <div className="flex">
                        {/* Agent */}
                        <div className="mb-4 w-1/2 mr-2">
                          <SelectField
                            formik={formik}
                            label={"Agent"}
                            setFieldValue={formik.setFieldValue}
                            name="agent"
                            isSearchable={false}
                            options={agentList}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder="Select Agent"
                          />
                        </div>
                        {/* toDoId */}
                        {formik?.values?.toDoId === "defaultCustomType" ? (
                          <>
                            <div className="mb-4 w-1/2 mr-2 mt-1">
                              <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="customInput"
                              >
                                Type
                              </label>
                              <div className="relative flex items-center">
                                <input
                                  type="text"
                                  id="customInput"
                                  name="customInput"
                                  className="block w-full rounded-md border-0 py-1.5 pr-14 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#475361] sm:text-sm sm:leading-6"
                                  placeholder="Enter Custom Type"
                                  onChange={formik.handleChange}
                                  value={formik?.values?.customInput}
                                  onBlur={(e) => {
                                    const values = e.target.value;
                                    const removeSpace = values
                                      .replace(/\s+/g, " ")
                                      .trim();
                                    formik.handleChange(e);
                                    formik.setFieldValue(
                                      "customInput",
                                      removeSpace
                                    );
                                  }}
                                />
                                <button
                                  type="button"
                                  className="absolute top-0 right-6 h-full px-2 text-gray-500 hover:text-gray-700 focus:outline-none disabled:opacity-40"
                                  disabled={
                                    formik?.values?.hasOwnProperty(
                                      "customInput"
                                    ) === false ||
                                    formik?.values?.customInput?.trim() === ""
                                      ? true
                                      : false
                                  }
                                  onClick={() => {
                                    formik?.values?.customInput?.trim() !==
                                      "" && handleAddTodo();
                                  }}
                                >
                                  <CheckIcon className="w-5 h-5" />
                                </button>
                                <button
                                  type="button"
                                  className="absolute bottom-0 right-0 h-full px-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                                  onClick={() => {
                                    formik.setFieldValue("toDoId", "");
                                    formik.setFieldValue("customInput", "");
                                  }}
                                >
                                  <XMarkIcon className="w-5 h-5" />
                                </button>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="mb-4 w-1/2 ml-2">
                            <div className="flex w-full">
                              <div className="w-full">
                                <SelectField
                                  formik={formik}
                                  label={"Type"}
                                  setFieldValue={formik.setFieldValue}
                                  name="toDoId"
                                  isSearchable={false}
                                  options={listTodo}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  placeholder="Select Type"
                                />
                              </div>
                              <div className="flex item-buttom pt-8 pb-2 pl-2 h-20">
                                <ActionButton
                                  Icon={PlusIcon}
                                  className="px-3 bg-gray-300 rounded"
                                  iconClassname={`h-3 w-4`}
                                  onClick={() => {
                                    formik.setFieldValue(
                                      "toDoId",
                                      "defaultCustomType"
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* Date */}
                      <div className="mb-4">
                        <div className=" w-full justify-center mb-2 text-sm">
                          {formik?.values?.date && (
                            <>
                              <label className="flex w-full justify-between text-sm font-medium leading-6 text-gray-900">
                                Selected Date {selectTime && " And Time"}
                              </label>
                              <p className="w-full text-start text-gray-800">
                                  {selectTime
                                    ? showTaskDateTimeFormat(
                                        formik?.values?.date
                                      )
                                    : showTaskDateFormat(formik?.values?.date)}
                              </p>
                            </>
                          )}
                        </div>
                        <div className="text-sm">
                          <div className="grid grid-cols-4 gap-1">
                            {dateButtonData?.map((date, key) => (
                              <div
                                key={key + 1}
                                className={`${
                                  activeDateButton === date?.day
                                    ? "bg-gray-400"
                                    : "bg-gray-200"
                                } px-auto py-1 text-center cursor-pointer hover:bg-gray-400 text-gray-700`}
                                onClick={() => {
                                  setSelectedDate(false); //set selected date false (deselect)
                                  handleButtonClick(
                                    date?.day,
                                    selectTime,
                                    false
                                  );
                                }}
                              >
                                {date?.title}
                              </div>
                            ))}
                            <div
                              className={`flex col-span-4 bg-gray-200 p-1 text-center cursor-pointer hover:bg-gray-400 relative items-center justify-center text-sm text-gray-700 ${
                                selectedDate ? "bg-gray-400" : "bg-gray-200"
                              }`}
                              onClick={handleSelectDateClick}
                            >
                              <div>Select Date</div>
                              <input
                                id="date"
                                name="date"
                                type="date"
                                ref={dateInputRef}
                                className={`mt-2 p-2 border rounded absolute top-0 right-0 opacity-0 pointer-events-none `}
                                onChange={(e) => {
                                  setActiveDateButton(null);
                                  setSelectedDate(true); //set selected date true (select)
                                  formik.setFieldValue(
                                    "date",
                                    convertDatetoDateAndtime(
                                      e.target.value,
                                      formik.values.date
                                    )
                                  );
                                }}
                                value={convertDate(formik.values.date)}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </div>
                          <div className="mt-3 flex w-full items-center justify-between text-sm">
                            <div className="flex items-center">
                              <input
                                type="checkbox"
                                id="selectTime"
                                checked={selectTime}
                                onChange={() => {
                                  formik?.setFieldValue("changeTime", true);
                                  setSelectTime((prev) => !prev);
                                  handleButtonClick(
                                    dayToAdd,
                                    !selectTime,
                                    true
                                  );
                                }}
                                className="cursor-pointer appearance-none p-[9px] mr-2 rounded-sm focus:border-orange-500 checked:border-orange-500 text-orange-500 focus:ring-orange-500  disabled:opacity-50 ml-2"
                              />
                              <label
                                htmlFor="selectTime"
                                className="cursor-pointer"
                              >
                                Select Time
                              </label>
                            </div>
                            <div>
                              {selectTime && (
                                <TimePicker
                                  format="h:mm a"
                                  clearIcon={null}
                                  autoFocus={editTaskId ? false : true}
                                  disableClock={true}
                                  value={
                                    formatTimestamp(formik?.values?.date) ||
                                    "00:00"
                                  }
                                  onChange={(value) => handleTimeChange(value)}
                                />
                              )}
                            </div>
                          </div>
                          {formik.touched.date && formik.errors.date && (
                            <div className="text-red-500 text-sm mt-1">
                              {formik.errors.date}
                            </div>
                          )}
                        </div>
                      </div>
                      {/* Description */}
                      <div className="mb-4">
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor="description"
                        >
                          Description
                        </label>
                        <textarea
                          className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-offset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-offset focus:ring-[#475361] sm:text-sm sm:leading-6"
                          id="description"
                          placeholder="Enter Description"
                          rows={4}
                          onBlur={(e) => {
                            const values = e.target.value;
                            const removeSpace = values
                              .replace(/\s+/g, " ")
                              .trim();
                            formik.handleChange(e);
                            formik.setFieldValue("description", removeSpace);
                          }}
                          onChange={formik.handleChange}
                          value={formik.values.description || ""}
                        />
                        {formik.touched.description &&
                          formik.errors.description && (
                            <div className="text-red-500 text-sm mt-1">
                              {formik.errors.description}
                            </div>
                          )}
                      </div>
                      <div className="flex justify-end">
                        <div className="w-16 h-8">
                          <SpinnerButton
                            type="submit"
                            className="btn-orange"
                            title="Save"
                            loading={isSaveLoading}
                            isDisable={!formik.dirty}
                          />
                        </div>
                      </div>
                    </form>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default AddTaskForm;
