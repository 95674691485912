import React from "react";
import { PatternFormat } from "react-number-format";

const FormatPhoneField = (props) => {
  const {
    name = "phone",
    value,
    placeholder = "",
    onChange = () => {},
    onBlur = () => {},
    onKeyDown = () => {},
    className = "",
    onClick = () => {},
    readOnly = false,
    onDoubleClick = () => {},
    autoComplete = "",
  } = props;

  return (
    <PatternFormat
      name={name}
      value={value}
      onValueChange={onChange}
      onBlur={onBlur}
      format={"(%%%) %%%-%%%%"}
      patternChar="%"
      placeholder={placeholder}
      className={className}
      onKeyDown={onKeyDown}
      onClick={onClick}
      readOnly={readOnly}
      onDoubleClick={onDoubleClick}
      autoComplete={autoComplete}
    />
  );
};

export default FormatPhoneField;
