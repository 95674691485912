import { createSlice } from "@reduxjs/toolkit";

// Initial state for the theme slice
const initialState = {
  breadcrumb: [], // Initial value for the breadcrumb array
  isNavigator: true,
  isSweetAlert: false,
  sweetAlertNavigate: "",
  isChangePassword: false,
  isLeadDetailsModalOpen: false,
  isLoading: false,
  loadingMessage: "Please wait, your request is being processed...",
};

// Creating a Redux slice using createSlice
export const themeSlice = createSlice({
  name: "theme", // Name of the slice
  initialState, // Initial state for the slice
  reducers: {
    // Reducer function to handle the setBreadcrumb action
    setBreadcrumb: (state, action) => {
      // Updating the breadcrumb array in the state with the payload from the action
      state.breadcrumb = action?.payload;
    },
    setIsNavigator: (state, action) => {
      state.isNavigator = action?.payload;
    },
    setIsSweetAlert: (state, action) => {
      state.isSweetAlert = action?.payload?.isSweetAlert;
      state.sweetAlertNavigate = action?.payload?.navigateLink;
    },
    setNavigateReset: (state) => {
      state.isNavigator = true;
      state.isSweetAlert = false;
      state.sweetAlertNavigate = "";
    },
    setChangePassword: (state, action) => {
      state.isChangePassword = action?.payload;
    },
    setSweetAlert: (state, action) => {
      state.isSweetAlert = action?.payload;
    },
    setIsLeadDetailsModalOpen: (state, action) => {
      state.isLeadDetailsModalOpen = action?.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action?.payload?.isLoading;
      state.loadingMessage = action?.payload?.loadingMessage;
    },
  },
});

// Exporting the setBreadcrumb action from the slice
export const {
  setBreadcrumb,
  setIsNavigator,
  setIsSweetAlert,
  setNavigateReset,
  setChangePassword,
  setSweetAlert,
  setIsLeadDetailsModalOpen,
  setIsLoading,
} = themeSlice.actions;

// Exporting the reducer function generated by createSlice
export default themeSlice.reducer;
