import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import SpinnerButton from "Components/Common/Fields/SpinnerButton";
import TextArea from "Components/Common/Fields/TextArea";
import useEscapeClose from "Components/Common/Hooks/useEscapeClose";
import QuillEditor from "Components/Common/TextEditor/QuillEditor";
import Select from "Components/Drips/DripInputs/Select";
import { useFormik } from "formik";
import { sentAfterOptions } from "Modules/data";
import {
  hasNonEmptyValuesInObj,
  isValidArray,
  isValidObject,
  removeUndefined,
  replaceNewlinesWithBR,
  showErrorMsg,
  showSuccessMsg,
} from "Modules/util";
import { Fragment, useEffect, useRef, useState } from "react";
import { PulseLoader } from "react-spinners";
import { useGetDripQuery, useGetNodesAndEdgesListQuery } from "Redux/Drip/drip";
import {
  useAddDripPreviewMutation,
  useGenerateOpenAIDripResponseMutation,
  useScheduleDripLeadsInputMutation,
} from "Redux/Leads/lead";
import * as Yup from "yup";

export default function AssignDripPreviewModal(props) {
  const {
    isOpen,
    setIsOpen,
    selectedDripForAssign,
    lead,
    scheduleDripData,
    filterData,
    setIsModifyDripFlowModal = () => {},
  } = props;
  useEscapeClose(setIsOpen); // Custom hook to close a modal when the Escape key is pressed.
  const quillRef = useRef(null);
  const [generateAddDripPreview, { isLoading: isAddDripPreviewLoading }] =
    useAddDripPreviewMutation();
  const [fieldValues, setFieldValues] = useState({
    time: "",
    type: "",
  });
  const [
    generateOpenAIDripPreview,
    { isLoading: isGenerateOpenAIDripPreviewLoading },
  ] = useGenerateOpenAIDripResponseMutation();

  const [node, setNode] = useState();
  const [assignLeadTodrip, { isLoading: isAssignDripLoading }] =
    useScheduleDripLeadsInputMutation();
  const leadId = isValidArray(lead?.leadResponse)
    ? lead?.leadResponse[0]?._id
    : null;

  const { data: getDrip } = useGetDripQuery(
    {
      id: selectedDripForAssign,
    },
    {
      skip: !selectedDripForAssign ? true : false,
    }
  );

  const { data: getNodesAndEdges, isLoading: isNodeAndedgesLoading } =
    useGetNodesAndEdgesListQuery(
      { id: selectedDripForAssign },
      { skip: !selectedDripForAssign ? true : false }
    );

  useEffect(() => {
    if (!isNodeAndedgesLoading) {
      let nodes = getNodesAndEdges?.data?.nodeAndEdgesDetails?.nodesData;
      let node = {};
      if (isValidObject(scheduleDripData)) {
        node = nodes?.filter(
          (node) => node?.id === scheduleDripData?.scheduledNodes?.[0]
        )?.[0];
      } else {
        node = nodes?.[0];
      }
      setFieldValues({
        time: node?.data?.time,
        type: node?.data?.type,
        isDripTimeChange: false,
      });
      setNode(node);
    }
  }, [getNodesAndEdges, scheduleDripData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      dripId: selectedDripForAssign,
      isDripTimeChange: false,
    },
    validationSchema: Yup.object().shape({
      dripId: Yup.string().required("Please Select Drip"),
      message: Yup.string().required("Preview Message Is Required"),
    }),

    onSubmit: async (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    let payload = {
      ...values,
      ...fieldValues,
      ...scheduleDripData,
      leadIds: leadId,
      isSelectedAll: false,
      isFilter: hasNonEmptyValuesInObj(filterData),
      Filter: removeUndefined(filterData),
    };

    try {
      const res = await assignLeadTodrip(payload);
      const reAssignDrip = res?.data?.data?.scheduleDripLeads;
      if (isValidObject(reAssignDrip)) {
        const { type, message } = reAssignDrip;
        if (type === "success") {
          showSuccessMsg(message);
        } else if (type === "error") {
          showErrorMsg(message);
        }
      } else {
        showErrorMsg("Something went wrong!");
      }
    } catch (error) {
      showErrorMsg("Error occurred while re-assigning drip");
    }
    setIsOpen(false);
    formik.resetForm();
  };

  //manage sent after time on change of time type ( minutes , hour , days)
  function manageSentAfterTime(type) {
    let time = "1";
    if (type === "minutes" && fieldValues?.time === "0") {
      time = fieldValues?.time;
    }
    setFieldValues({
      ...fieldValues,
      type: type,
      time: time,
      isDripTimeChange: validateTimeChange(time, type),
    });
  }

  // Validating Time Change
  function validateTimeChange(time, type) {
    if (type === node?.data?.type && time === node?.data?.time) {
      return false;
    } else {
      return true;
    }
  }

  function setInput(inputValue) {
    inputValue = Number(inputValue);

    if (fieldValues?.type === "minutes" && inputValue <= 60) {
      setFieldValues({
        ...fieldValues,
        time: inputValue.toString(),
        isDripTimeChange: validateTimeChange(
          inputValue.toString() || "0",
          "minutes"
        ),
      });
    }
    if (fieldValues?.type === "hours" && inputValue <= 24) {
      setFieldValues({
        ...fieldValues,
        time: inputValue.toString(),
        isDripTimeChange: validateTimeChange(
          inputValue.toString() || "0",
          "hours"
        ),
      });
    }
    if (fieldValues?.type === "days" && inputValue <= 100) {
      setFieldValues({
        ...fieldValues,
        time: inputValue.toString(),
        isDripTimeChange: validateTimeChange(
          inputValue.toString() || "0",
          "days"
        ),
      });
    }
    if (fieldValues?.type === "weeks" && inputValue <= 99) {
      setFieldValues({
        ...fieldValues,
        time: inputValue.toString(),
        isDripTimeChange: validateTimeChange(
          inputValue.toString() || "0",
          "weeks"
        ),
      });
    }
    if (fieldValues?.type === "months" && inputValue <= 99) {
      setFieldValues({
        ...fieldValues,
        time: inputValue.toString(),
        isDripTimeChange: validateTimeChange(
          inputValue.toString() || "0",
          "months"
        ),
      });
    }
    if (fieldValues?.type === "years" && inputValue <= 10) {
      setFieldValues({
        ...fieldValues,
        time: inputValue.toString(),
        isDripTimeChange: validateTimeChange(
          inputValue.toString() || "0",
          "years"
        ),
      });
    }
  }

  async function generatDripPreview() {
    let payload = {
      dripId: selectedDripForAssign,
      leadId: lead?.leadResponse?.[0]?._id,
      nodeId: scheduleDripData?.scheduledNodes?.[0],
    };
    try {
      const res = await generateAddDripPreview(payload);
      const DripPreview = res?.data?.data?.addDripPreview;
      if (isValidObject(DripPreview)) {
        const { type, message, preview } = DripPreview;
        if (type === "success") {
          if (node?.type === "message") {
            formik.setFieldValue("message", preview);
          } else {
            formik.setFieldValue("message", replaceNewlinesWithBR(preview));
          }
          showSuccessMsg(message);
        } else if (type === "error") {
          showErrorMsg(message);
        }
      } else {
        showErrorMsg("Error occurred while generating preview!");
      }
    } catch (error) {
      showErrorMsg("Error occurred while generating preview");
    }
  }

  async function generateOpenAIDripResponse() {
    let payload = {
      dripId: selectedDripForAssign,
      leadId: lead?.leadResponse?.[0]?._id,
      nodeId: node?.id,
      nodeType: node?.type,
      isSuggestedDrip: false,
    };

    const res = await generateOpenAIDripPreview(payload);
    const openAIDripPreview = res?.data?.data?.generateOpenAIDripResponse;
    try {
      if (isValidObject(openAIDripPreview)) {
        const { type, message, response } = openAIDripPreview;
        if (type === "success") {
          showSuccessMsg(message);
          if (node?.type === "message") {
            formik.setFieldValue("message", response);
          } else {
            formik.setFieldValue("message", replaceNewlinesWithBR(response));
          }
        } else if (type === "error") {
          showErrorMsg(message);
        }
      } else {
        showErrorMsg("Error occurred while generating preview!");
      }
    } catch (error) {
      showErrorMsg("Error occurred while generating preview");
    }
  }

  function handleAssignDrip() {
    if (
      !isGenerateOpenAIDripPreviewLoading ||
      !isAddDripPreviewLoading ||
      !(fieldValues?.time === "0" && fieldValues?.type !== "minutes")
    ) {
      formik?.handleSubmit();
    }
  }

  useEffect(() => {
    if (isOpen && node) {
      if (isValidObject(node?.data)) {
        if (node?.data?.isOpenAI) {
          generateOpenAIDripResponse();
        } else {
          generatDripPreview();
        }
      }
    }
  }, [isOpen, node]);

  function isRegenerateButtonDisable() {
    if (isValidObject(node?.data)) {
      if (node?.data?.isOpenAI) {
        return false;
      } else {
        return true;
      }
    }
  }

  useEffect(() => {
    if (node?.type === "mail") {
      if (formik?.values?.message?.trim() == "<p><br></p>") {
        formik?.setFieldError("message", "Please Enter Mail");
      }
    }
  }, [formik?.values?.message]);

  useEffect(() => {
    if (isOpen) {
      setIsModifyDripFlowModal(false);
    }
  }, [isOpen]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[1112]" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-5xl border-b-2 py-2 transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-5 ">
                <div className="mt-1 border-b-2 py-2 sm:mt-0 flex justify-between items-center">
                  <div className="sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-800 flex justify-between items-center"
                    >
                      Are you sure you want to add this lead to{" "}
                      {getDrip?.data?.drip?.drip?.title}?
                    </Dialog.Title>
                  </div>
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 "
                    onClick={() => {
                      setIsOpen(false);
                      formik.resetForm();
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-5 ">
                  <div className="flex space-x-2">
                    <label className="pt-2">
                      Here is a preview of the first{" "}
                      {node?.type === "message" ? "text" : node?.type} which
                      will be sent in
                    </label>
                    <div className="">
                      <div className="flex">
                        <input
                          type="text"
                          className="text-right text-sm rounded-l-md w-[50px] h-[38px] ring-0 outline-none border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 "
                          value={fieldValues?.time}
                          onChange={(e) => setInput(e.target.value)}
                          min={1}
                          max={
                            fieldValues?.type === "minutes"
                              ? 60
                              : fieldValues?.type === "hours"
                              ? 24
                              : fieldValues?.type === "days"
                              ? 100
                              : fieldValues?.type === "weeks"
                              ? 99
                              : fieldValues?.type === "months"
                              ? 99
                              : 10
                          }
                          maxLength={3}
                        />
                        <Select
                          className="!w-[200px] !rounded-r-lg"
                          options={sentAfterOptions}
                          value={fieldValues?.type}
                          onChange={(e) => manageSentAfterTime(e.target.value)}
                        />
                      </div>
                      {fieldValues?.time === "0" &&
                        fieldValues?.type !== "minutes" && (
                          <span className="text-sm text-start text-red-600">
                            can not set 0 for {fieldValues?.type}
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="mt-3 ">
                    <span className="flex font-medium">
                      Preview:{" "}
                      {isGenerateOpenAIDripPreviewLoading ||
                      isAddDripPreviewLoading ? (
                        <span>
                          <label className="ml-2">Generating Preview</label>
                          <span className="mt-0.5">
                            <PulseLoader size={3} color="#000000" />
                          </span>
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                    {!isGenerateOpenAIDripPreviewLoading &&
                    !isAddDripPreviewLoading ? (
                      <div>
                        {node?.type === "message" ? (
                          <TextArea
                            name="message"
                            formik={formik}
                            className="border-1 border-gray-300 bg-gray-100 placeholder-gray-700 "
                            rows={6}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="message"
                            placeholder="Preview Message"
                            isNotRemoveExtraSpace={true}
                          />
                        ) : (
                          <div>
                            <div className="mb-4">
                              <QuillEditor
                                formik={formik}
                                name="message"
                                forwardedRef={quillRef}
                                toolbar={false}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="flex justify-end pt-6">
                  {node?.data?.isOpenAI ? (
                    <SpinnerButton
                      className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                      title={"Regenerate Preview"}
                      action={() => generateOpenAIDripResponse()}
                      type={"button"}
                      loading={false}
                      isDisable={isRegenerateButtonDisable()}
                    />
                  ) : (
                    <></>
                  )}
                  <SpinnerButton
                    className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                    title={"See Drip Campaign"}
                    action={() =>
                      window.open(
                        `/drip-flow/${selectedDripForAssign}`,
                        "_blank"
                      )
                    }
                    type={"button"}
                    loading={false}
                    isDisable={
                      isAddDripPreviewLoading ||
                      isGenerateOpenAIDripPreviewLoading
                    }
                  />
                  <SpinnerButton
                    className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                    title={"Assign To Drip"}
                    action={() => handleAssignDrip()}
                    type={"button"}
                    loading={isAssignDripLoading}
                    isDisable={
                      isAddDripPreviewLoading ||
                      isGenerateOpenAIDripPreviewLoading ||
                      isValidObject(formik?.errors) ||
                      (fieldValues?.time === "0" &&
                        fieldValues?.type !== "minutes")
                    }
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
