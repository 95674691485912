import classNames from "classnames";
import React from "react";

const LeadNavigation = ({
  handleNavigationButton = {},
  handleLeadNavigation = () => {},
}) => {
  return (
    <div className="w-full flex justify-end ">
      <div className="flex gap-x-1 mr-6">
        <button
          className={classNames(
            "bg-white px-2 py-1 shadow-md border text-orange-400 border-gray-200 hover:bg-orange-200 hover:text-orange-600",
            {
              "cursor-not-allowed opacity-50":
                handleNavigationButton?.type === "prev" &&
                handleNavigationButton?.isDisable,
            }
          )}
          onClick={() => handleLeadNavigation("prev")}
          title="Previous Lead"
          disabled={
            handleNavigationButton?.type === "prev" &&
            handleNavigationButton?.isDisable
          }
        >
          <span className="font-bold ">{"<"}</span>
        </button>
        <button
          className={classNames(
            "bg-white px-2 py-1 shadow-md border text-orange-400 border-gray-200 hover:bg-orange-200 hover:text-orange-600",
            {
              "cursor-not-allowed opacity-50":
                handleNavigationButton?.type === "next" &&
                handleNavigationButton?.isDisable,
            }
          )}
          onClick={() => handleLeadNavigation("next")}
          title="Next Lead"
          disabled={
            handleNavigationButton?.type === "next" &&
            handleNavigationButton?.isDisable
          }
        >
          <span className="font-bold">{">"}</span>
        </button>
      </div>
    </div>
  );
};

export default LeadNavigation;
