export default function ActionButton({
  Icon,
  title,
  iconClassname,
  label,
  isDisabled,
  ...props
}) {
  return (
    <>
      <button
        className="btn-orange disabled:opacity-70"
        title={title}
        {...props}
        disabled={isDisabled}
      >
        <Icon className={`w-4 h-4 ${iconClassname ?? ""}`} />
        {label}
      </button>
    </>
  );
}
