export const gqlBody = {
  getSMSByLeadId(payload) {
    return {
      query: `query GetSMSByLeadId($input: inputSingleLead) {
        getSMSByLeadId(input: $input) {
          type
          message
          code
          smsResponse {
            from
            to
            message
            lead
            sId
            status
            response
            createdAt
            updatedAt
            type
            errorURL
            errorCode
            errorMessage
          }
        }
      }`,
      variables: { input: payload },
    };
  },
};
