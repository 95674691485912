import { Dialog, Transition } from "@headlessui/react";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import useEscapeClose from "Components/Common/Hooks/useEscapeClose";
import LeadDetails from "Components/Leads/LeadDetails/LeadDetails";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// LeadDetailsModal component is a modal for displaying lead details using Headless UI components.
const LeadDetailsModal = ({
  isOpen,
  setIsOpen,
  isLeadId,
  taskRefetch,
  setCompletedTaskList,
  completedTaskList,
  deletedTask,
  setDeletedTask,
  isOpenNestModal,
  setIsOpenNestModal,
}) => {
  // State to store a unique identifier for modal clicks
  const [handleModalClick, setHandleModalClick] = useState("78676678");
  const root = document.getElementById("root");
  const [isCompleteTaskInProcess, setIsCompleteTaskInProcess] = useState(false);
  const navigate = useNavigate();

  // function to check any nested modal is open or not
  const hasAnyModalOpen = (obj) => {
    return Object.values(obj).some((value) => value === true);
  };
  useEffect(() => {
    // Define the event handler function for the Escape key press
    function handleKeyDown(event) {
      // Check if the pressed key is the Escape key
      if (
        event?.key === "Escape" &&
        isOpen &&
        !hasAnyModalOpen(isOpenNestModal)
      ) {
        // Set the modal state to closed (false)
        navigate("");
        setIsOpen(false);
      }
    }
    // Add the keydown event listener when the component mounts
    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [setIsOpen, isOpenNestModal]); // Dependency array to re-run the effect if setIsOpen changes

  // Handle Toaser not closing
  useEffect(() => {
    root.removeAttribute("inert");
    root.removeAttribute("aria-hidden");
  }, []);

  function handleModalClose() {
    // preventing close button
    if (!isCompleteTaskInProcess) {
      navigate("");
      setIsOpen(false);
    }
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[99] "
        onClose={() => {}}
        onClick={(e) => e.stopPropagation()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div
          className="fixed inset-0 overflow-y-auto"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                onClick={() =>
                  setHandleModalClick(
                    `${Math.floor(10000000 + Math.random() * 90000000)}`
                  )
                }
                className="w-full transform overflow-visible rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-5"
              >
                <Dialog.Title
                  as="div"
                  className="text-lg font-medium leading-6 text-gray-900 flex justify-between items-center"
                >
                  <h6 className="flex mb-4">
                    <PaperAirplaneIcon className="w-6 text-orange-500 text-semibold mr-2" />
                    Lead Details
                  </h6>
                  <button
                    onClick={() => handleModalClose()}
                    className={`focus:outline-none ${
                      isCompleteTaskInProcess && "cursor-not-allowed"
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-gray-500 hover:text-gray-700 transition duration-300"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3.293 3.293a1 1 0 011.414 0L10 8.586l5.293-5.293a1 1 0 111.414 1.414L11.414 10l5.293 5.293a1 1 0 01-1.414 1.414L10 11.414l-5.293 5.293a1 1 0 01-1.414-1.414L8.586 10 3.293 4.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </Dialog.Title>
                {/* Modal content, LeadDetails component */}
                <div className="w-full bg-gray-100 max-h-[90vh] overflow-y-auto rounded-sm border-2 border-gray-200 p-4">
                  <LeadDetails
                    leadId={isLeadId}
                    isModal={true}
                    handleModalClick={handleModalClick}
                    taskRefetch={taskRefetch}
                    setCompletedTaskList={setCompletedTaskList}
                    completedTaskList={completedTaskList}
                    deletedTask={deletedTask}
                    setDeletedTask={setDeletedTask}
                    isCompleteTaskInProcess={isCompleteTaskInProcess}
                    setIsCompleteTaskInProcess={setIsCompleteTaskInProcess}
                    isOpen={isOpen}
                    setIsOpenNestModal={setIsOpenNestModal}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default LeadDetailsModal;
