import React from "react";
import moveIcon from "Assets/icons/moveIcon.svg";
import notFound from "Assets/icons/notFound.svg";

export default function PropertySearches(props) {
  const { drag, canDrop } = props;
  return (
    <div className="!min-w-[475px] rounded-2xl shadow-md ">
      <div
        className={`leadDeatils-card-header !h-[40px] rounded-t-2xl flex items-center justify-between px-4 ${
          canDrop && "!bg-orange-300 opacity-85"
        }`}
      >
        <div className="space-x-2 flex items-center ">
          <div className="flex">
            <div className="bg-white w-0.5"></div>
            <div className="text-white pl-[7px] font-medium">
              Property Searches
            </div>
          </div>
        </div>
        <div
          className="bg-orange-600 rounded-md flex items-center p-1.5 h-7 w-7 cursor-move"
          ref={drag}
        >
          <img
            alt="edit-icon"
            src={moveIcon}
            className="h-4 w-4 rounded-md filter invert brightness-0"
            title={"Move Segment"}
          />
        </div>
      </div>
      <div
        className={`leadDeatils-card-body !h-[390px] rounded-b-2xl overflow-y-auto py-2 px-1 ${
          canDrop && "!bg-orange-300 opacity-85"
        }`}
      >
        <div className="flex justify-center pt-[100px]">
          <img
            alt="edit-icon"
            src={notFound}
            className="w-72 grayscale "
            title={"Move Segment"}
          />
        </div>
        <span className="flex justify-center">
          <label className="font-semibold text-gray text-lg">
            No Property Found 😔
          </label>
        </span>
      </div>
    </div>
  );
}
