import React from "react";
import MultiSelectField from "./MultiSelectField";

export default function CustomMultiSelect({
  options,
  formik,
  label,
  name,
  id,
  setId,
  isOpen = false,
  defaultSelect = "",
  placeholder = "",
}) {
  return (
    <div className="mb-2">
      <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">
        {label}
      </label>
      <MultiSelectField
        isOpen={isOpen}
        options={options}
        formik={formik}
        name={name}
        id={id}
        setId={setId}
        defaultSelect={defaultSelect}
        placeholder={placeholder}
      />
      {formik?.touched?.[name] && formik?.errors?.[name] && (
        <div className="text-red-500 text-sm mt-1">
          {formik?.errors?.[name]}
        </div>
      )}
    </div>
  );
}
