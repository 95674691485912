import React from "react";

export default function Button(
  { title, action, isDisable, className = "mr-3" },
  props
) {
  return (
    <button
      className={`btn-orange  ${className}`}
      disabled={isDisable}
      onClick={action}
      {...props}
    >
      {title}
    </button>
  );
}
