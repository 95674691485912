import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { AgGridReact } from "ag-grid-react";

import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import { getLocalStorage, isValidArray, setLocalStorage } from "Modules/util";

export default function CustomTable({
  columns,
  rows,
  isLoading,
  filter,
  pagination,
  setFilter,
  totalRecords,
  listName,
  className,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(
    Number(getLocalStorage(`${listName}Page`)) || 20
  );

  useEffect(() => {
    if (pagination) {
      setFilter({ ...filter, page: currentPage, limit: perPage });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, perPage]);

  useEffect(() => {
    if (pagination) {
      setCurrentPage(1);
      setFilter({ ...filter, page: 1, limit: perPage });
    }
    setLocalStorage(`${listName}Page`, perPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage]);

  const onChangeInSelect = (event) => {
    const page = event.target.value ? Number(event.target.value) : 20;
    setPerPage(page);
  };

  function calculatePage(perPage, totalRecords) {
    return Math.ceil(totalRecords / perPage) || 1;
  }

  const totalPages = calculatePage(perPage, totalRecords);

  const showCurrentPage = currentPage <= totalPages ? currentPage : 1;
  const isDisableNext = showCurrentPage >= totalPages;
  const isDisablePrevious = showCurrentPage <= 1;

  const onSortChanged = ({ api: { sortController } }) => {
    const sortModel = sortController.getSortModel();
    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      setFilter({ ...filter, column: colId, sort: sort });
    } else {
      setFilter((current) => {
        const { column, sort, ...rest } = current;
        return rest;
      });
    }
  };

  const defaultColDef = {
    suppressMovable: false,
    autoHeight: true,
  };

  return (
    <div
      className={`ag-theme-alpine w-full max-h-[400px] custom-table-height ${className} `}
    >
      <AgGridReact
        columnDefs={columns}
        rowData={isValidArray(rows) ? rows : []}
        enableCellTextSelection={true}
        headerHeight={40}
        onSortChanged={onSortChanged}
        pagination={false}
        suppressDragLeaveHidesColumns={true}
        defaultColDef={defaultColDef}
        className="mb-0 border-b-0 rounded-lg rounded-b-none "
        overlayNoRowsTemplate={`<div class='ag-overlay-no-rows-center'>${
          isLoading ? "Loading..." : "No Data Found"
        }<div/>`}
      />
      {pagination ? (
        <div className="flex items-center justify-end gap-x-4 p-3 leading-3 bg-white border-[1px] rounded-t-none rounded-sm border-t-0 border-zinc-300 shadow-sm">
          <div className="flex items-center gap-x-2">
            <button
              onClick={() => setCurrentPage(1)}
              disabled={isDisablePrevious}
              className={classNames("bg-gray-200 text-base px-1 rounded-lg", {
                "cursor-not-allowed opacity-50": isDisablePrevious,
                "hover:bg-gray-300": !isDisablePrevious,
              })}
            >
              {"<<"}
            </button>
            <button
              onClick={() => setCurrentPage(showCurrentPage - 1)}
              disabled={showCurrentPage <= 1}
              className={classNames(
                "bg-gray-200 text-base mr-1 px-1 rounded-lg",
                {
                  "cursor-not-allowed opacity-50": isDisablePrevious,
                  "hover:bg-gray-300": !isDisablePrevious,
                }
              )}
            >
              {"<"}
            </button>
            <span className="text-sm">
              {
                <>
                  {totalRecords ? (
                    <>{(showCurrentPage - 1) * perPage + 1} - </>
                  ) : (
                    <>0 - </>
                  )}
                </>
              }
              {Math.min(showCurrentPage * perPage, totalRecords)} of{" "}
              {totalRecords}
            </span>
          </div>

          <div className="flex gap-x-2 items-center">
            <button
              color="primary"
              onClick={() => setCurrentPage(showCurrentPage + 1)}
              disabled={isDisableNext}
              className={classNames("bg-gray-200 text-base px-1 rounded-lg", {
                "hover:bg-gray-300": !isDisableNext,
                "cursor-not-allowed opacity-50": isDisableNext,
              })}
            >
              {">"}
            </button>
            <button
              onClick={() => setCurrentPage(totalPages)}
              disabled={isDisableNext}
              className={classNames(
                "bg-gray-200 text-base mr-1 px-1 rounded-lg",
                {
                  "cursor-not-allowed opacity-50": isDisableNext,
                  "hover:bg-gray-300": !isDisableNext,
                }
              )}
            >
              {">>"}
            </button>
            <span className="text-sm ml-2 mr-2">
              Total : <span className="font-semibold">{totalRecords ?? 0}</span>
            </span>
            <select
              type="select"
              value={perPage}
              onChange={onChangeInSelect}
              className="text-sm py-1 h-7 w-30"
            >
              {[10, 20, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
