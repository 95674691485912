import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";

export const OptOutDropDown = (props) => {
  const {
    Icon,
    iconColor,
    Options,
    action,
    id,
    setPayload,
    status,
    extraProps = {},
  } = props;


  return (
    <>
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className="flex focus:ring-0 focus:border-none focus:outline-none "
              title={status}
            >
              <Icon
                className={`w-4 h-4 focus:ring-0 focus:border-none focus:outline-none ${iconColor}`}
                data-tooltip-id={`Icon-${id}`}
              />
                <ChevronDownIcon
                  className={`${open && `rotate-180`}
              ml-0.5 mt-1 h-3 w-3 transition duration-150 ease-in-out group-hover:text-orange-300/80`}
                />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="fixed z-10 w-[170px] bg-white mt-1">
                <div className="overflow-hidden rounded shadow-lg p-1">
                  {Options?.map((option, index) => (
                    <p
                      key={index}
                      className={`flex gap-1 items-center w-full cursor-pointer text-[12px] !rounded px-2 py-0.5 hover:bg-orange-300 `}
                      onClick={() => {
                        action({ ...option, id: id, ...extraProps });
                        setPayload({ ...option, id: id, ...extraProps });
                        close();
                      }}
                    >
                      <option.icon className={`w-4 h-4 ${option?.iconColor}`} />
                      {option?.label}
                      {option?.value === status && (
                        <CheckIcon className="w-4 h-4 text-gray-800" />
                      )}
                    </p>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
};
