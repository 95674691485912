import { serverApi } from "Redux/serverApi";
import { gqlBody } from "./gqlBody";

// Create a settingsQuery object using serverApi.injectEndpoints
const emailTemplateQuery = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    addEmailTemplate: builder.mutation({
      query: (payload) => ({
        method: "POST",
        body: gqlBody.AddEmailTemplate(payload),
      }),
    }),
    getEmailTemplates: builder.query({
      query: (payload) => ({
        method: "POST",
        body: gqlBody.getEmailTemplates(payload),
      }),
      providesTags: ["EmailTemplateList"],
    }),
    getMailTemplate: builder.query({
      query: (payload) => ({
        method: "POST",
        body: gqlBody.getEmailTemplate(payload),
      }),
    }),
    getEmailTemplate: builder.mutation({
      query: (payload) => ({
        method: "POST",
        body: gqlBody.getEmailTemplate(payload),
      }),
    }),
    editEmailTemplate: builder.mutation({
      query: (payload) => ({
        method: "POST",
        body: gqlBody.UpdateEmailTemplate(payload),
      }),
      invalidatesTags: ["EmailTemplateList"],
    }),
    deleteEmailTemplate: builder.mutation({
      query: (payload) => ({
        method: "POST",
        body: gqlBody.DeleteEmailTemplate(payload),
      }),
    }),
  }),
  // Do not override existing endpoints
  overrideExisting: false,
});

export const {
  useAddEmailTemplateMutation,
  useGetMailTemplateQuery,
  useGetEmailTemplatesQuery,
  useGetEmailTemplateMutation,
  useEditEmailTemplateMutation,
  useDeleteEmailTemplateMutation,
} = emailTemplateQuery;
