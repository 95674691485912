import { serverApi } from "Redux/serverApi";
import { gqlBody } from "./gqlBody";

// Create a userAuth object using serverApi.injectEndpoints
const Lead = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllLeads: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.getAllLeads(payload),
        };
      },
      providesTags: ["LeadList"],
    }),
    addLead: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.addLead(payload),
        };
      },
      invalidatesTags: ["LeadList", "Lead"],
    }),
    editLead: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.editLead(payload),
        };
      },
      invalidatesTags: ["LeadList", "Lead", "HistoryByLeadId"],
    }),
    deleteLead: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.deleteLead(payload),
        };
      },
      invalidatesTags: ["LeadList", "Lead"],
    }),
    getCategoryList: builder.query({
      query: () => {
        return {
          method: "post",
          body: gqlBody.getAllCategory(),
        };
      },
      providesTags: ["CategoryList"],
    }),
    getTagList: builder.query({
      query: () => {
        return {
          method: "post",
          body: gqlBody.getTagList(),
        };
      },
      providesTags: ["TagList", "Lead"],
    }),
    sendMail: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.sendMail(payload),
        };
      },
      invalidatesTags: [
        "LeadList",
        "Lead",
        "EmailHistoryByLeadId",
        "HistoryByLeadId",
      ],
    }),
    sendMessage: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.sendMessage(payload),
        };
      },
      invalidatesTags: [
        "LeadList",
        "Lead",
        "SMSByLeadId",
        "HistoryByLeadId",
        "TaskByLeadId",
      ],
    }),
    makeCall: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.makeCall(payload),
        };
      },
      invalidatesTags: ["LeadList", "Lead"],
    }),
    getLead: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.getLead(payload),
        };
      },
      providesTags: ["Lead"],
    }),
    editLeadDetails: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.editLeadDetails(payload),
        };
      },
      invalidatesTags: ["LeadList", "TaskList", "Lead", "HistoryByLeadId"],
    }),
    editLeadPhone: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.editLeadDetails(payload),
        };
      },
    }),
    getFilter: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.getFilter(payload),
        };
      },
      providesTags: ["Filter"],
    }),
    addFilter: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.addFilter(payload),
        };
      },
      invalidatesTags: ["Filter"],
    }),
    scheduleDripLeadsInput: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.scheduleDripLeadsInput(payload),
        };
      },
      invalidatesTags: [
        "LeadList",
        "Drip",
        "NodesAndEdges",
        "Lead",
        "HistoryByLeadId",
        "SMSByLeadId",
        "TaskByLeadId",
        "EmailHistoryByLeadId",
        "DripByLeadId",
      ],
    }),
    importLeadCsv: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.importLeadCsv(payload),
          formData: true,
        };
      },
      invalidatesTags: ["LeadList"],
    }),
    getAllLeadsMutation: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.getAllLeads(payload),
        };
      },
      providesTags: ["LeadList"],
    }),
    addSaveFilter: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.SaveFilter(payload),
        };
      },
    }),
    getSaveFilters: builder.query({
      query: () => {
        return {
          method: "post",
          body: gqlBody.getSaveFilter(),
        };
      },
    }),
    getSaveFilter: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.getLeadFilters(payload),
        };
      },
    }),
    deleteSaveFilter: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.deleteSaveFilter(payload),
        };
      },
    }),
    resetLeadFilter: builder.mutation({
      query: () => {
        return {
          method: "post",
          body: gqlBody.resetLeadFilter(),
        };
      },
    }),
    editLeadFilter: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.editLeadFilter(payload),
        };
      },
    }),
    leadNavigation: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.leadNavigation(payload),
        };
      },
    }),
    closeLead: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.closeLead(payload),
        };
      },
      invalidatesTags: ["LeadList", "Lead"],
    }),
    leadBirthday: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.leadBirthday(payload),
        };
      },
      invalidatesTags: ["LeadList", "Lead", "HistoryByLeadId"],
    }),
    leadPlayPause: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.leadPlayPause(payload),
        };
      },
      invalidatesTags: ["LeadList", "Lead", "HistoryByLeadId"],
    }),
    LeadSource: builder.query({
      query: () => {
        return {
          method: "post",
          body: gqlBody.LeadSource(),
        };
      },
    }),
    phoneStatusUpdate: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.phoneStatusUpdate(payload),
        };
      },
      invalidatesTags: ["LeadList", "Lead", "TaskByLeadId"],
    }),
    emailStatusUpdate: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.emailStatusUpdate(payload),
        };
      },
      invalidatesTags: ["LeadList", "Lead"],
    }),
    getLeadDetailsSegment: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.getLeadDetailsSegment(payload),
        };
      },
      providesTags: ["LeadSegments"],
    }),
    saveLeadDetailsSegments: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.saveLeadDetailsSegments(payload),
        };
      },
      invalidatesTags: ["LeadList", "Lead", "LeadSegments"],
    }),
    leadStoryPrompt: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.leadStoryPrompt(payload),
        };
      },
      invalidatesTags: ["LeadList", "Lead", "LeadSegments"],
    }),
    bulkAddAgent: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.bulkAddAgent(payload),
        };
      },
      invalidatesTags: ["LeadList"],
    }),
    bulkAddLender: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.bulkAddLender(payload),
        };
      },
      invalidatesTags: ["LeadList"],
    }),
    checkScheduleLeadCount: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.checkScheduleLeadCount(payload),
        };
      },
    }),
    NextDripPreview: builder.mutation({
      query: (payload, signal) => {
        return {
          method: "post",
          body: gqlBody.NextDripPreview(payload),
          signal,
        };
      },
    }),
    AddDripPreview: builder.mutation({
      query: (payload, signal) => {
        return {
          method: "post",
          body: gqlBody.AddDripPreview(payload),
          signal,
        };
      },
    }),
    GenerateOpenAIDripResponse: builder.mutation({
      query: (payload, signal) => {
        return {
          method: "post",
          body: gqlBody.GenerateOpenAIDripResponse(payload),
          signal,
        };
      },
    }),
    TwilioErrorCode: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.TwilioErrorCode(payload),
        };
      },
      providesTags: ["Filter"],
    }),
    ScheduleDripLeadTask: builder.mutation({
      query: (payload, signal) => {
        return {
          method: "post",
          body: gqlBody.ScheduleDripLeadTask(payload),
          signal,
        };
      },
      invalidatesTags: ["TaskByLeadId", "DripByLeadId", "HistoryByLeadId"],
    }),
    LeadFollowUp: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.LeadFollowUp(payload),
        };
      },
      invalidatesTags: ["TaskByLeadId", "HistoryByLeadId", "Lead"],
    }),
    SuggestDrip: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.SuggestDrip(payload),
        };
      },
      invalidatesTags: [
        "TaskByLeadId",
        "DripByLeadId",
        "HistoryByLeadId",
        "TaskList",
        "Task",
      ],
    }),
    mergeLead: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.mergeLead(payload),
        };
      },
      invalidatesTags: [
        "Lead",
        "TaskByLeadId",
        "DripByLeadId",
        "HistoryByLeadId",
        "SMSByLeadId",
        "EmailHistoryByLeadId",
        "CallByLeadId",
        "NotesByLeadId",
        "LeadList",
      ],
    }),
    MergeLeadCountAndRunningDrips: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.MergeLeadCountAndRunningDrips(payload),
        };
      },
    }),
    LeadFollowUPMessageGenerate: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.LeadFollowUPMessageGenerate(payload),
        };
      },
      invalidatesTags: ["Lead"],
    }),
    LeadFollowUPMessageEdit: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.LeadFollowUPMessageEdit(payload),
        };
      },
      invalidatesTags: ["Lead"],
    }),
    DomainList: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.DomainList(payload),
        };
      },
    }),
    // Do not override existing endpoints
    overrideExisting: false,
  }),
});

export const {
  useGetFilterQuery,
  useAddFilterMutation,
  useMakeCallMutation,
  useGetAllLeadsQuery,
  useGetLeadQuery,
  useGetCategoryListQuery,
  useGetTagListQuery,
  useEditLeadMutation,
  useAddLeadMutation,
  useDeleteLeadMutation,
  useSendMailMutation,
  useSendMessageMutation,
  useEditLeadDetailsMutation,
  useScheduleDripLeadsInputMutation,
  useImportLeadCsvMutation,
  useGetAllLeadsMutationMutation,
  useAddSaveFilterMutation,
  useGetSaveFiltersQuery,
  useGetSaveFilterMutation,
  useDeleteSaveFilterMutation,
  useResetLeadFilterMutation,
  useEditLeadFilterMutation,
  useLeadNavigationMutation,
  useCloseLeadMutation,
  useLeadBirthdayMutation,
  useLeadPlayPauseMutation,
  useLeadSourceQuery,
  useGetDripByLeadIdQuery,
  useGetHistoryByLeadIdQuery,
  usePhoneStatusUpdateMutation,
  useEmailStatusUpdateMutation,
  useGetLeadDetailsSegmentQuery,
  useSaveLeadDetailsSegmentsMutation,
  useLeadStoryPromptMutation,
  useBulkAddAgentMutation,
  useBulkAddLenderMutation,
  useCheckScheduleLeadCountQuery,
  useNextDripPreviewMutation,
  useAddDripPreviewMutation,
  useGenerateOpenAIDripResponseMutation,
  useTwilioErrorCodeQuery,
  useScheduleDripLeadTaskMutation,
  useLeadFollowUpMutation,
  useSuggestDripMutation,
  useEditLeadPhoneMutation,
  useMergeLeadMutation,
  useMergeLeadCountAndRunningDripsMutation,
  useLeadFollowUPMessageGenerateMutation,
  useLeadFollowUPMessageEditMutation,
  useDomainListQuery,
} = Lead;
