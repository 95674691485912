import { createSlice } from "@reduxjs/toolkit";

// Define the initial state of the user slice
const initialState = {
  id: "", // User's ID
  permission: [], // User's Permission
  currentUser: {},
  notificationCount: 0,
};

// Create the user slice using user Permission
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    user: (state, { payload }) => {
      state.id = payload?.id;
      state.permission = payload?.permission;
    },
    currentUser: (state, { payload }) => {
      state.currentUser = payload;
    },
    notificationCount: (state, { payload }) => {
      state.notificationCount = payload;
    },
  },
});

// Export the action creators
export const { user, currentUser, notificationCount } = userSlice.actions;

// Export the user slice reducer
export default userSlice.reducer;
