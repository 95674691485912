import { serverApi } from "Redux/serverApi";
import { gqlBody } from "./gqlBody";

// Create a userAuth object using serverApi.injectEndpoints
const userAuth = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.login(payload?.email, payload?.password),
        };
      },
    }),
    logout: builder.mutation({
      query: () => {
        return {
          method: "post",
          body: gqlBody.logout(),
        };
      },
    }),
    forgotPassword: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.forgotPassword(payload?.email),
        };
      },
    }),
    changePassword: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.changePassword(payload),
        };
      },
    }),
    updateProfile: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.updateProfile(payload),
        };
      },
    }),
  }),
  // Do not override existing endpoints
  overrideExisting: false,
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useForgotPasswordMutation,
  useChangePasswordMutation,
  useUpdateProfileMutation,
} = userAuth;
