import { PatternFormat } from "react-number-format";

const CustomPhoneInput = (props) => {
  const { formik, label, values, name = "phone", isFilter = false } = props;

  return (
    <div className="my-2 w-full">
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>

      <PatternFormat
        name={name}
        value={values?.[name] ?? ""}
        onValueChange={(values) => {
          formik?.setFieldValue(name, values?.value);
        }}
        onBlur={formik?.handleBlur}
        format={isFilter ? "%%%%%%%%%%" : "+1 (%%%) %%%-%%%%"}
        patternChar="%"
        placeholder="Enter Phone"
        className="input-field block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#475361] sm:text-sm sm:leading-6"
      />
      {formik?.touched?.[name] && formik?.errors?.[name] && (
        <div className="text-red-500 text-sm">{formik?.errors?.[name]}</div>
      )}
    </div>
  );
};

export default CustomPhoneInput;
