import { useEffect } from "react";
/**
 * Custom hook to close a modal when the Escape key is pressed.
 *
 * @param {Function} setIsOpen - State setter function to control the open state of the modal.
 */
function useEscapeClose(
  setIsOpen,
  isOpenNestModal = false,
  handleClose = () => {}
) {
  useEffect(() => {
    // Define the event handler function for the Escape key press
    function handleKeyDown(event) {
      // Check if the pressed key is the Escape key
      if (event.key === "Escape") {
        // Set the modal state to closed (false)
        handleClose();
        if (!isOpenNestModal) {
          setIsOpen(false);
        }
      }
    }
    // Add the keydown event listener when the component mounts
    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [setIsOpen, isOpenNestModal]); // Dependency array to re-run the effect if setIsOpen changes
}

export default useEscapeClose;
