import { serverApi } from "Redux/serverApi";
import { gqlBody } from "./gqlBody";

// Create a userAuth object using serverApi.injectEndpoints
const user = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.getAllUsers(payload),
        };
      },
      providesTags: ["userList"],
    }),
    getUser: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.getUser(payload),
        };
      },
      providesTags: ["userList"],
    }),
    user: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.getUser(payload),
        };
      },
    }),
    getRole: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.getRole(payload),
        };
      },
      providesTags: ["roleList"],
    }),
    addUser: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.addUser(payload),
        };
      },
      invalidatesTags: ["userList", "AgentList", "LenderList"],
    }),
    editUser: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.editUser(payload),
        };
      },
      invalidatesTags: ["userList", "AgentList", "LenderList"],
    }),
    deleteUser: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.deleteUser(payload),
        };
      },
      invalidatesTags: ["userList", "AgentList", "LenderList"],
    }),
    leanderUserList: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.LenderList(payload),
        };
      },
      providesTags: ["LenderList"],
    }),
    agentAndAgentManagerList: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.AgentAndAgentManager(payload),
        };
      },
      providesTags: ["AgentList"],
    }),
    listOfAvailableNumbers: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.listOfAvailableNumbers(payload),
        };
      },
      providesTags: ["NumberList"],
    }),
    buyNumberForUser: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.buyNumberForUser(payload),
        };
      },
      invalidatesTags: ["NumberList", "userList", "LeadList"],
    }),
    addUserNumber: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.addUserNumber(payload),
        };
      },
      invalidatesTags: ["NumberList", "userList", "LeadList"],
    }),
    getSingleRole: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.getSingleRole(payload),
        };
      },
      providesTags: ["role"],
    }),
    UpdateUsersPassword: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.UpdateUsersPassword(payload),
        };
      },
    }),
    checkUser: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.checkUser(payload),
        };
      },
    }),
    checkUserBeforeDelete: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.checkUserBeforeDelete(payload),
        };
      },
    }),
    resendEmailAccountSetup: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.ResendEmailAccountSetup(payload),
        };
      },
    }),
    checkUserMutation: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.checkUser(payload),
        };
      },
    }),
  }),
  // Do not override existing endpoints
  overrideExisting: false,
});

export const {
  useGetAllUsersQuery,
  useGetUserQuery,
  useAddUserMutation,
  useEditUserMutation,
  useGetRoleQuery,
  useDeleteUserMutation,
  useLeanderUserListQuery,
  useAgentAndAgentManagerListQuery,
  useListOfAvailableNumbersQuery,
  useBuyNumberForUserMutation,
  useAddUserNumberMutation,
  useGetSingleRoleQuery,
  useUserMutation,
  useUpdateUsersPasswordMutation,
  useCheckUserQuery,
  useCheckUserBeforeDeleteMutation,
  useResendEmailAccountSetupMutation,
  useCheckUserMutationMutation
} = user;
