import { BeatLoader } from "react-spinners";

export default function SpinnerButton(props) {
  const {
    Icon = false,
    label,
    title,
    action = () => {},
    isDisable = false,
    className = "",
    isLoading,
    type = "button",
  } = props;
  return (
    <button
      className={`${className} `}
      disabled={isDisable}
      onClick={action}
      title={title}
      type={type}
    >
      {isLoading ? (
        <BeatLoader
          className="flex items-center"
          color="#ffff"
          size={5}
          width={10.5}
          height={8}
        />
      ) : Icon ? (
        <Icon className="w-4 h-4" />
      ) : (
        label
      )}
    </button>
  );
}
