import { convertToUTC, getLocalStorage } from "Modules/util";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomDatePicker = (props) => {
  const {
    label,
    formik,
    name,
    placeholder = "Select Date",
    initialValue,
    isClearable = false,
    className = "",
  } = props;
  const [startDate, setStartDate] = useState(initialValue);
  const timeZone = getLocalStorage("timezone");

  useEffect(() => {
    if (initialValue) {
      formik?.setFieldValue(name, convertToUTC(initialValue, timeZone));
    }
  }, []);

  const handleChange = (date) => {
    setStartDate(date);
    if (date) {
      formik?.setFieldValue(name, convertToUTC(date, timeZone));
    } else {
      formik?.setFieldValue(name, null);
    }
  };

  return (
    <div className={`space-y-2`}>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <ReactDatePicker
        onChangeRaw={(e) => e.preventDefault()}
        showIcon
        isClearable={isClearable}
        selected={startDate}
        onChange={(date) => handleChange(date)}
        className={`rounded w-[600px] outline-none focus:border-gray-800 focus:ring-gray-500 text-sm  ${className}`}
        dateFormat="dd/MM/YYYY"
        placeholderText={placeholder}
      />
      {!startDate && formik?.errors?.[name] && (
        <div className="text-red-500 text-sm">{formik?.errors[name]}</div>
      )}
    </div>
  );
};

export default CustomDatePicker;
