import { XMarkIcon } from "@heroicons/react/24/outline";
import { CheckIcon, PencilIcon } from "@heroicons/react/24/solid";
import { isValidArray, showErrorMsg } from "Modules/util";
import { useEffect, useRef, useState } from "react";
import FormatPhoneField from "./FormatPhoneField";

const EditMultiInputField = ({
  label,
  name,
  formik,
  format,
  type = format,
  handleSubmit,
  isSubmit = false,
  disabled = false,
  initialValues,
  setIsEditableOpen,
  isEditableOpen = "",
  showTags = true,
  setEditFiledName = () => {},
}) => {
  const [inputValue, setInputValue] = useState("");
  const [isEditing, setEditing] = useState(false);
  const [tags, setTags] = useState(initialValues ?? []);
  const inputRef = useRef(null);
  const containerRef = useRef(null); // Ref for the entire component

  const handleInputChange = (e) => {
    // if (type.test(e.target.value)) {
    let value = name === "email" ? e.target.value : e?.value;
    setInputValue(value);
    if (name === "email") {
      setEvent(e);
    }
  };

  function isValidFormat(value) {
    return format?.test(value);
  }
  const handleInputKeyDown = (e, flag, isSubmit) => {
    let value = "";
    if (name == "phone") {
      setEvent(e);
      value = e.target.value?.replace(/\+\d+(?=.*\()|\s|[()+-]/g, "");
    } else {
      value = e.target.value;
    }
    if (value) {
      setInputValue(value);
    }
    if (flag && isValidFormat(value)) {
      e?.preventDefault();
      addTag();
      setInputValue("");
      setEditing(false);
    } else {
      if (e.target.value && isSubmit) {
        showErrorMsg(
          `Please enter a valid ${name === "phone" ? "phone number" : name}`
        );
      }
    }
  };
  const addTag = () => {
    let trimmedValue = inputValue.trim();
    if (name === "phone") {
      trimmedValue = trimmedValue?.replace(/\+\d+(?=.*\()|\s|[()+-]/g, "");
    }
    if (trimmedValue) {
      setTags([...tags, trimmedValue]);
    }
    if (isSubmit) {
      handleSubmit({
        [name]: [...tags, trimmedValue]?.filter((tag) => tag?.length > 0),
      });
    }
  };

  const removeTag = (index) => {
    const newTags = [...tags];
    newTags?.splice(index, 1);
    setTags(newTags);
    if (isSubmit) {
      handleSubmit({
        [name]: newTags,
      });
    }
  };

  const [event, setEvent] = useState(null);

  useEffect(() => {
    formik?.setFieldValue(name, [...tags]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isValidArray(initialValues)) {
      setTags(initialValues);
    } else {
      setTags([]);
    }
  }, [initialValues]);

  const handleAdd = (isSubmit) => {
    if (event) {
      handleInputKeyDown(event, true, isSubmit);
    }
  };
  useEffect(() => {
    if (isEditing) {
      const listener = (event) => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
          handleAdd(isSubmit);
        }
      };
      document.addEventListener("keydown", listener);
      return () => {
        document.removeEventListener("keydown", listener);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  // Function to handle clicks outside the input field
  const handleOutsideClick = (event) => {
    if (
      containerRef?.current &&
      !containerRef?.current?.contains(event?.target)
    ) {
      if (inputValue?.trim()) {
        handleAdd(true);
      } else {
        setInputValue("");
        setEditing(false);
      }
    }
  };

  useEffect(() => {
    if (name !== isEditableOpen) {
      handleOutsideClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditableOpen]);

  useEffect(() => {
    setEditFiledName(isEditing);
    if (isEditing) {
      setIsEditableOpen(name);
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isEditableOpen, isEditing, inputValue]);

  return (
    <div className="w-full" ref={containerRef}>
      <label className="block text-xs font-medium leading-6 text-gray-900 mb-2">
        {label}
      </label>
      <div
        className={` gap-2 p-1 rounded-md items-start ring-gray-300 focus-visible:outline-none ${
          showTags && "flex flex-col ring-1 p-2 "
        }`}
        onClick={() => !disabled && inputRef.current.focus()}
      >
        {showTags &&
          tags?.map((tag, index) => (
            <div
              key={index}
              className="text-xs bg-orange-300 text-black px-2 py-1 rounded-md h-full flex items-center "
            >
              {tag}
              {disabled ? (
                <></>
              ) : (
                <button type="button" onClick={() => removeTag(index)}>
                  <XMarkIcon className="h-4 w-4 bg-white text-red-700 ml-2 rounded-full flex items-center border-1 border-black hover:text-red-600 hover:bg-gray-100" />
                </button>
              )}
            </div>
          ))}
        {disabled ? (
          <></>
        ) : (
          <>
            <div className="relative">
              {name === "phone" ? (
                <div ref={inputRef}>
                  <FormatPhoneField
                    name={name}
                    value={inputValue}
                    placeholder={`Add ${label ?? name}`}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    onClick={(e) => e?.stopPropagation()}
                    readOnly={!isEditing}
                    onDoubleClick={() => setEditing(true)}
                    className={`h-8 w-full px-0.5 pr-16 outline-none rounded border border-gray-300 shadow-sm py-2 bg-white ring-gray-300 focus:border-gray-800 focus:ring-gray-500 placeholder:pl-2 placeholder:text-gray-400 sm:text-sm sm:leading-6 ${
                      isEditing ? "bg-white" : "bg-gray-100"
                    } 
                  `}
                    autoComplete="off"
                  />
                </div>
              ) : (
                <input
                  name={name}
                  type="text"
                  ref={inputRef}
                  className={`h-8 w-full px-0.5 pr-16 outline-none rounded border border-gray-300 shadow-sm py-2 bg-white ring-gray-300 focus:border-gray-800 focus:ring-gray-500 placeholder:pl-2 placeholder:text-gray-400 sm:text-sm sm:leading-6 ${
                    isEditing ? "bg-white" : "bg-gray-100"
                  } 
                `}
                  placeholder={`Add ${label ?? name}`}
                  onClick={(e) => e?.stopPropagation()}
                  value={inputValue}
                  onDoubleClick={() => setEditing(true)}
                  onChange={handleInputChange}
                  readOnly={!isEditing}
                  onKeyDown={handleInputKeyDown}
                ></input>
              )}
              {!isEditing ? (
                <button
                  onClick={(e) => {
                    e?.stopPropagation();
                    setEditing(true);
                    inputRef.current.focus();
                  }}
                  className="absolute z-99 right-2 h-6 w-6 top-4 transform -translate-y-1/2  bg-orange-100 text-orange flex justify-center items-center rounded-full border border-orange-400 p-1"
                >
                  <PencilIcon className="w-5 h-5" />
                </button>
              ) : (
                <>
                  <button
                    onClick={() => {
                      setEditing(false);
                      setInputValue("");
                    }}
                    className="absolute right-8 h-6 w-6 top-4 transform -translate-y-1/2 bg-red-100 rounded-md border border-red-400 text-red-700 hover:bg-red-200 hover:text-red-900"
                  >
                    <XMarkIcon className="w-5 h-5 font-semibold" />
                  </button>
                  <button
                    onClick={() => handleAdd(true)}
                    className="absolute right-1 h-6 w-6 top-4 transform -translate-y-1/2 bg-green-100 rounded-md border border-green-400 text-green-700 hover:bg-green-200 hover:text-green-900"
                  >
                    <CheckIcon className="w-5 h-5 font-semibold" />
                  </button>
                </>
              )}
            </div>
          </>
        )}
      </div>
      {formik?.touched?.[name] && formik?.errors?.[name] && (
        <div className="text-red-500 text-sm">{formik?.errors?.[name]}</div>
      )}
    </div>
  );
};

export default EditMultiInputField;
