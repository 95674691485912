import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import SelectField from "Components/Common/Fields/SelectField";
import SpinnerButton from "Components/Common/Fields/SpinnerButton";
import useEscapeClose from "Components/Common/Hooks/useEscapeClose";
import ConfirmationModal from "Components/Common/Modals/ConfirmationModal";
import {
  getOptionData,
  hasNonEmptyValuesInObj,
  isValidArray,
  removeUndefined,
  showErrorMsg,
  showSuccessMsg,
} from "Modules/util";
import { useGetDripsListQuery } from "Redux/Drip/drip";
import {
  useScheduleDripLeadsInputMutation,
  useCheckScheduleLeadCountQuery,
} from "Redux/Leads/lead";
import { setIsLoading } from "Redux/themeSlice";
import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import Skeleton from "Components/Common/Fields/Skeleton";

export default function AssignLeadModal({
  isOpen,
  setIsOpen,
  selectedRows = false,
  refetch,
  isAllChecked,
  lead,
  leadWiseDripList,
  deselected,
  handleResetCheckbox,
  filterData,
  isBulkAction,
  isFromLeadDeatails = false,
  setIsModifyDripFlowModal = () => {},
  setDripForModifyFlow = () => {},
  setIsAssignDripPreviewModal = () => {},
  setScheduleDripData = () => {},
}) {
  const [dripList, setDripList] = useState();
  const [isConfirmModal, setConfirmModal] = useState(false); // state for confirmation modal
  const dispatch = useDispatch();
  useEscapeClose(setIsOpen); // Custom hook to close a modal when the Escape key is pressed.

  // const [selectedDripForAssign, setSelectedDripForAssign] = useState(null);

  const [id, setId] = useState();

  // query fot count of leads that are scheduled to be sent
  const { data: scheduleLeadCount, isLoading: isCountLoading } =
    useCheckScheduleLeadCountQuery(
      {
        leadIds: id,
        isSelectedAll: isAllChecked,
        isFilter: hasNonEmptyValuesInObj(filterData),
        Filter: removeUndefined(filterData),
      },
      {
        skip: !id,
      }
    ); // count of leads that are scheduled to be sent

  useEscapeClose(setIsOpen); // Custom hook to close a modal when the Escape key is pressed.

  const [scheduleDripCount, setScheduleDripCount] = useState(0);

  const [selectedDripForAssign, setSelectedDripForAssign] = useState(0); // state for selected drip
  const [dripId, setDripId] = useState();

  useEffect(() => {
    let ids = isValidArray(leadWiseDripList)
      ? leadWiseDripList?.map((d) => d?.dripId)
      : [];

    setDripId(ids);
  }, [leadWiseDripList]);

  const { data: getDripsList, isLoading } = useGetDripsListQuery({
    search: "",
    page: -1,
  });
  const [assignLeadTodrip, { isLoading: isAssignDripLoading }] =
    useScheduleDripLeadsInputMutation();

  useEffect(() => {
    if (!isLoading) {
      let dripId = isValidArray(lead?.dripsResponse)
        ? lead?.dripsResponse
            ?.filter(
              (drip) => drip?.dripSortOrder !== 4 && drip?.dripSortOrder !== 5
            )
            .map((drip) => drip?.dripId)
        : [];

      let activeDrips = getDripsList?.data?.drips?.drips?.filter(
        (data) => data?.status === true
      );
      // Filter the options based on whether their value is in dripIdSet
      const filteredDripList = getOptionData(activeDrips)?.filter(
        (drip) => !dripId?.includes(drip?.value)
      );
      setDripList(filteredDripList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDripsList, leadWiseDripList, dripId]);

  const handleSubmit = (values) => {
    let payload = {
      ...values,
      leadIds: id,
      isSelectedAll: isAllChecked,
      isFilter: hasNonEmptyValuesInObj(filterData),
      Filter: removeUndefined(filterData),
    };
    assignLeadTodrip(payload)
      .then((response) => {
        if (response?.data?.data?.scheduleDripLeads?.type === "success") {
          showSuccessMsg(response?.data?.data?.scheduleDripLeads?.message);
          handleResetCheckbox && handleResetCheckbox();
          if (refetch?.isRefetch) {
            refetch?.refetch();
          }
        } else if (response?.data?.data?.scheduleDripLeads?.type === "error") {
          showErrorMsg(response?.data?.data?.scheduleDripLeads?.message);
        }
        if (isBulkAction) {
          dispatch(
            setIsLoading({
              isLoading: false,
              loadingMessage: "",
            })
          );
        }
      })
      .catch((error) => {
        showErrorMsg("Error occurred while Scheduling Drip.");
        if (isBulkAction) {
          dispatch(
            setIsLoading({
              isLoading: false,
              loadingMessage: "",
            })
          );
        }
      });
    setIsOpen(false);
    formik.resetForm();
  };

  // setting id as if selected All is true then id is set to deselected id array else set to selected rows
  useEffect(() => {
    if (isAllChecked) {
      setId(deselected);
    } else {
      setId(selectedRows);
    }
  }, [selectedRows, isAllChecked]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      dripId: "",
      isDripTimeChange: false,
    },
    validationSchema: Yup.object().shape({
      dripId: Yup.string().required("Please Select Drip"),
      message:
        !isBulkAction && Yup.string().required("Preview Message Is Required"),
    }),

    onSubmit: async (values) => {
      if (isBulkAction) {
        setConfirmModal(true);
      } else {
        handleSubmit(values);
      }
    },
  });

  // useEffect(() => {
  //   if (formik?.values?.dripId && !isBulkAction) {
  // setIsOpen(false);
  // setIsModifyDripFlowModal(true);
  // setDripForModifyFlow(formik?.values?.dripId);
  //   }
  // }, [formik?.values?.dripId]);

  // handle
  function handleContinue() {
    setDripForModifyFlow(formik?.values?.dripId);
    setScheduleDripData({});
    setIsOpen(false);
    setIsAssignDripPreviewModal(true);
  }

  function handleSkipNodes() {
    setDripForModifyFlow(formik?.values?.dripId);
    setIsOpen(false);
    setIsModifyDripFlowModal(true);
  }

  useEffect(() => {
    if (!isCountLoading && isOpen && isBulkAction) {
      setScheduleDripCount(scheduleLeadCount?.data?.checkLeadCount?.leadCount);
    }
  }, [scheduleLeadCount, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      formik.resetForm();
    }
  }, [isOpen]);

  return (
    <>
      {/* <Skeleton className="!w-[120px] !h-[18px] !m-0 !p-0" /> */}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[1111]"
          onClose={() => {
            setIsOpen(false);
            formik.resetForm();
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>
          <ConfirmationModal
            isModalOpen={isConfirmModal}
            setModalOpen={setConfirmModal}
            handleAction={() => handleSubmit(formik?.values)}
            isLoading={false}
            message={
              isCountLoading ? (
                <Skeleton className="my-0 mx-1" counter={1} height={"24px"} />
              ) : (
                `You are about to assign drips to ${scheduleDripCount} leads, proceed?`
              )
            }
          />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl border-b-2 py-2 transform overflow-visible rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-5">
                  <div className="mt-1 border-b-2 py-2 sm:mt-0 flex justify-between items-center">
                    <div className="sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-800 flex justify-between items-center"
                      >
                        Assign Leads
                      </Dialog.Title>
                    </div>
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 "
                      onClick={() => {
                        setIsOpen(false);
                        formik.resetForm();
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  <form
                    className="space-y-4 mt-3 max-h-[900px] overflow-visible p-1"
                    onSubmit={formik.handleSubmit}
                  >
                    {isCountLoading ? (
                      <Skeleton
                        className="my-0 mx-1 w-[100px]"
                        counter={1}
                        height={"24px"}
                      />
                    ) : (
                      scheduleDripCount > 0 && (
                        <span className="text-sm">
                          <span>{scheduleDripCount}</span>
                          <span>{isBulkAction ? " Lead Selected" : ""}</span>
                        </span>
                      )
                    )}

                    <div className="overflow-visible">
                      <SelectField
                        formik={formik}
                        options={dripList}
                        name="dripId"
                        label="Select Drip"
                        isSearchable={false}
                        setFieldValue={formik.setFieldValue}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      {!isFromLeadDeatails ? (
                        <SpinnerButton
                          className="inline-flex w-full justify-center rounded-md btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                          title={"Assign"}
                          action={() => {}}
                          type={"submit"}
                          loading={
                            isLoading || isCountLoading || isAssignDripLoading
                          }
                        />
                      ) : (
                        <>
                          <SpinnerButton
                            className="inline-flex w-full justify-center rounded-md btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                            title={"Countinue"}
                            action={() => handleContinue()}
                            type={"submit"}
                            isDisable={!formik?.values?.dripId}
                            loading={
                              isLoading || isCountLoading || isAssignDripLoading
                            }
                            toolTip="Countinue"
                          />
                          <SpinnerButton
                            className="inline-flex w-full justify-center rounded-md btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                            title={"Skip Nodes"}
                            action={() => handleSkipNodes()}
                            type={"submit"}
                            isDisable={!formik?.values?.dripId}
                            toolTip="Skip Nodes"
                          />
                        </>
                      )}
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => {
                          setIsOpen(false);
                          formik.resetForm();
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
