export const gqlBody = {
  getConfiguration() {
    return {
      query: `query GetConfiguration {
        getConfiguration {
          response {
            smtp {
              EMAIL_FROM
              SMTP_HOST
              SMTP_PORT
              SMTP_USERNAME
              SMTP_PASSWORD
              SMTP_FROMNAME
            }
            twilio {
              TWILIO_ACCOUNT_SID
              TWILIO_AUTH_TOKEN
              TWILIO_SENDER_PHONE_NUMBER
            }
            dripTime {
              DRIP_START_TIME
              DRIP_END_TIME
            }
            timezone
            openAIKey
            notificationEmail
            notificationSource
          }
          type
          message
          code
        }
      }`,
    };
  },
  editConfiguration(payload) {
    return {
      query: `mutation AddEditConfiguration($input: AddEditConfigurationInput!) {
        addEditConfiguration(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
};
