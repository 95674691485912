import { useCallback, useState, useRef, useEffect } from "react";
import crossIcon from "Assets/icons/crossIcon.svg";
import searchIcon from "Assets/icons/searchIcon.svg";

const SearchInputField = (props) => {
  const {
    globalSearch = () => {}, // Function to perform global search
    setSearchInput = () => {},
    searchInput: externalSearchInput = "", // External search input value
    isSmall = false, // Boolean to determine input size
    placeholder = "Search", // Placeholder text for input
    autoFocus = false, // Boolean to determine autofocus
  } = props;
  const [searchInput, setSearchInputInternal] = useState(externalSearchInput); // Internal state for search input
  const timeoutIdRef = useRef(null); // Reference to store timeout ID

  // Function to clear the search input
  const clearSearch = () => {
    setSearchInputInternal(""); // Clear internal state
    globalSearch(""); // Clear global search results
    setSearchInput("");
  };

  // Handle input change
  const handleChange = (value) => {
    setSearchInputInternal(value); // Update internal state
    delayedSearch(value); // Trigger delayed search
    setSearchInput(value);
  };

  // Function to handle delayed search
  const delayedSearch = useCallback(
    (value) => {
      clearTimeout(timeoutIdRef.current); // Clear previous timeout
      timeoutIdRef.current = setTimeout(() => {
        globalSearch(value);
      }, 700); // Set new timeout for 700ms to perform global search
    },
    [globalSearch]
  );

  // Effect to sync internal state with external prop
  useEffect(() => {
    setSearchInputInternal(externalSearchInput);
  }, [externalSearchInput]);

  return (
    <div className="flex relative text-gray-600">
      <input
        className={`border-1 border-gray-300 focus:ring-0 bg-white w-full min-w-[30px] px-4 pr-16 text-sm focus:outline-none rounded ${
          isSmall ? " !py-[8px] h-8" : ""
        }`}
        type="text"
        name="search"
        placeholder={placeholder}
        value={searchInput}
        autoFocus={autoFocus}
        onChange={(e) => handleChange(e?.target?.value)}
      />
      {!!searchInput && (
        <img
          alt="archive"
          src={crossIcon}
          className={`hover:cursor-pointer absolute right-0 mr-10 ${
            isSmall ? "h-4 w-4 -top-[1px] mt-[10px]" : "h-5 w-5 -top-[3px] mt-3"
          }`}
          onClick={clearSearch}
        />
      )}
      <img
        alt="archive"
        src={searchIcon}
        className={`absolute right-0 mr-4 ${
          isSmall ? "h-4 w-4 -top-[1px] mt-[10px]" : "h-5 w-5 -top-[3px] mt-3"
        }`}
        onClick={() => {}}
      />
    </div>
  );
};

export default SearchInputField;
