import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import SpinnerButton from "Components/Common/Fields/SpinnerButton";
import TextArea from "Components/Common/Fields/TextArea";
import useEscapeClose from "Components/Common/Hooks/useEscapeClose";
import QuillEditor from "Components/Common/TextEditor/QuillEditor";
import { useFormik } from "formik";
import {
  isValidObject,
  replaceNewlinesWithBR,
  showErrorMsg,
  showSuccessMsg,
} from "Modules/util";
import { Fragment, useEffect, useRef } from "react";
import { useLeadDripMessageSaveMutation } from "Redux/pendingMessages/pendingMessagesQuery";
import * as Yup from "yup";

export default function DripHoverEditModal(props) {
  const {
    isOpen,
    setIsOpen,
    dripTooltipInfo,
    leadId,
    refetch,
    generateOpenAIDripResponse,
    isGenerateOpenAIDripPreviewLoading,
  } = props;
  const quillRef = useRef(null);

  const [leadDripMessageSave, { isLoading: isLeadDripMessageSaveLoading }] =
    useLeadDripMessageSaveMutation();
  useEscapeClose(setIsOpen);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      message: dripTooltipInfo?.preview,
      mail: replaceNewlinesWithBR(dripTooltipInfo?.preview),
    },
    validationSchema: Yup.object().shape({
      message:
        dripTooltipInfo?.type === "message" &&
        Yup.string().required("Message Is Required"),
      mail:
        dripTooltipInfo?.type === "mail" &&
        Yup.string().required("Mail Is Required"),
    }),
    onSubmit: (values) => {
      savePreviewMessage(values);
      setIsOpen(false);
    },
  });

  async function savePreviewMessage(values) {
    let payload = {
      dripId: dripTooltipInfo?.dripId,
      leadId: leadId,
      message:
        dripTooltipInfo?.type === "mail" ? values?.mail : values?.message,
    };

    try {
      const res = await leadDripMessageSave(payload);
      const dripPreviewSave = res?.data?.data?.leadDripMessageSave;
      if (isValidObject(dripPreviewSave)) {
        const { type, message } = dripPreviewSave;
        if (type === "success") {
          showSuccessMsg(message);
          refetch();
        } else if (type === "error") {
          showErrorMsg(message);
        }
      } else {
        showErrorMsg("Error occurred while saving preview!");
      }
    } catch (error) {
      showErrorMsg("Error occurred while saving preview");
    }
  }

  useEffect(() => {
    if (dripTooltipInfo?.type === "mail") {
      if (formik?.values?.mail?.trim() == "<p><br></p>") {
        formik.setFieldError("mail", "Please Enter Mail");
      }
    }
  }, [formik?.values?.mail]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[9999]" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-5xl border-b-2 py-2 transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-5 ">
                <div className="mt-1 border-b-2 py-2 sm:mt-0 flex justify-between items-center">
                  <div className="sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-800 flex justify-between items-center"
                    >
                      {dripTooltipInfo?.dripTitle ?? " Edit Preview "}
                    </Dialog.Title>
                  </div>
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 "
                    onClick={() => {
                      setIsOpen(false);
                      formik.resetForm();
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-2">
                  {dripTooltipInfo?.type === "message" ? (
                    <TextArea
                      label="Message Preview"
                      name="message"
                      formik={formik}
                      className="border-1 border-gray-300 bg-gray-100 placeholder-gray-700 "
                      rows={6}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      autoComplete="message"
                      placeholder="Preview Message"
                      isNotRemoveExtraSpace={true}
                    />
                  ) : (
                    <div>
                      <label className="block text-sm font-medium leading-6 text-gray-900 pb-2">
                        Mail Preview
                      </label>
                      <div className="mb-4">
                        <QuillEditor
                          formik={formik}
                          name="mail"
                          forwardedRef={quillRef}
                          toolbar={false}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex justify-end pt-6">
                  {dripTooltipInfo?.isOpenAI ? (
                    <SpinnerButton
                      className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                      title={"Regenerate Preview"}
                      action={() => {
                        if (!isGenerateOpenAIDripPreviewLoading) {
                          generateOpenAIDripResponse();
                        }
                      }}
                      type={"button"}
                      loading={isGenerateOpenAIDripPreviewLoading}
                      isDisable={isGenerateOpenAIDripPreviewLoading}
                      toolTip={"Regenerate Preview"}
                    />
                  ) : (
                    <></>
                  )}
                  <SpinnerButton
                    className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                    title={"Save Preview"}
                    action={() => formik.handleSubmit()}
                    type={"button"}
                    loading={isLeadDripMessageSaveLoading}
                    isDisable={
                      isGenerateOpenAIDripPreviewLoading ||
                      isLeadDripMessageSaveLoading ||
                      isValidObject(formik.errors)
                    }
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
