const { serverApi } = require("Redux/serverApi");
const { gqlBody } = require("./gqlBody");

// Create a openAI object using serverApi.injectEndpoints
const openAI = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    generateOpenAISMSResponse: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.generateOpenAISMSResponse(payload),
        };
      },
    }),
    toggleOpenAIAutomation: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.toggleOpenAIAutomation(payload),
        };
      },
    }),
  }),
  // Do not override existing endpoints
  overrideExisting: false,
});

export const {
  useGenerateOpenAISMSResponseMutation,
  useToggleOpenAIAutomationMutation,
} = openAI;
