// CustomDragLayer.js
import React, { useEffect, useState } from "react";
import { useDragLayer } from "react-dnd";

const CustomDragLayer = () => {
  const { item, isDragging, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    isDragging: monitor.isDragging(),
    currentOffset: monitor.getSourceClientOffset(),
  }));

  const cardColorMap = {
    Drip: "text-purple-500 border-purple-400",
    SMS: "text-green-500 border-green-400",
    Tasks: "text-red-500 border-red-400",
    EmailHistory: "text-teal-500 border-teal-400",
    CallLogsHistory: "text-cyan-500 border-cyan-400",
    History: "text-indigo-500 border-indigo-400",
    Note: "text-emerald-500 border-emerald-400",
  };

  const getCardColors = () =>
    cardColorMap[item?.dragData?.name] || "text-orange-500 border-orange-400";

  if (!isDragging || !currentOffset) {
    return null;
  }

  const layerStyles = {
    position: "fixed",
    pointerEvents: "none",
    left: currentOffset.x,
    top: currentOffset.y,
    transform: "translate(-50%, -50%)",
    zIndex: 999999999,
  };

  return (
    <div style={layerStyles}>
      <div
        className={`rounded-lg bg-white p-3 font-semibold border opacity-100 ${getCardColors()}`}
      >
        {item?.dragData?.name}
      </div>
    </div>
  );
};

export default CustomDragLayer;
