import { createSlice } from "@reduxjs/toolkit";
import { isValidArray } from "Modules/util";

// Define the initial state of the user slice
const initialState = {
  agent: [],
  lender: [],
  source: [],
  todo: [],
  drip: [],
  dripList: [],
  refetchNeeded: {
    agent: false,
    lender: false,
    source: false,
    drip: false,
  },
};

// Create the user slice using user Permission
export const optionSlice = createSlice({
  name: "option",
  initialState,
  reducers: {
    agentOption: (state, { payload }) => {
      state.agent = isValidArray(payload) ? payload : [];
    },
    lenderOption: (state, { payload }) => {
      state.lender = isValidArray(payload) ? payload : [];
    },
    sourceOption: (state, { payload }) => {
      state.source = isValidArray(payload) ? payload : [];
    },
    dripOption: (state, { payload }) => {
      state.drip = isValidArray(payload) ? payload : [];
    },
    dripList: (state, { payload }) => {
      state.dripList = isValidArray(payload) ? payload : [];
    },
    
    setRefetchNeeded: (state, { payload }) => {
      state.refetchNeeded = {
        ...payload,
      };
    },
  },
});

// Export the action creators
export const {
  agentOption,
  lenderOption,
  sourceOption,
  dripOption,
  setRefetchNeeded,
  dripList
} = optionSlice.actions;

// Export the user slice reducer
export default optionSlice.reducer;
