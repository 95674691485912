import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { serverApi } from "./serverApi";
import userReducer from "./userSlice";
import themeReducer from "./themeSlice";
import optionSlice from "./slice/optionSlice";

// Combine reducers from serverApi and userReducer into a single appReducer
const appReducer = combineReducers({
  [serverApi.reducerPath]: serverApi.reducer, // Include serverApi reducer
  user: userReducer, // Include userReducer
  theme: themeReducer, // Include theme Reducer
  option: optionSlice, // Include Option Slice
});

// Create a rootReducer that wraps the appReducer
const rootReducer = (state, action) => {
  return appReducer(state, action);
};

// Configure the Redux store with rootReducer and middleware
export const store = configureStore({
  reducer: rootReducer, // Set the rootReducer as the main reducer for the store
  middleware: (
    getDefaultMiddleware // Configure middleware, extending the default middleware with serverApi.middleware
  ) => getDefaultMiddleware(getDefaultMiddleware).concat(serverApi.middleware),
});

// Setup listeners for the store to handle dispatched actions
setupListeners(store.dispatch);
