export default function Button(
  { label, title, action, isDisable = false, className = "" },
  props
) {
  return (
    <button
      className={` ${className}`}
      disabled={isDisable}
      onClick={action}
      title={title}
      {...props}
    >
      {label}
    </button>
  );
}
