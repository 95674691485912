import { serverApi } from "Redux/serverApi";
import { gqlBody } from "./gqlBody";

// Create a History object using serverApi.injectEndpoints
const History = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    deleteHistory: builder.mutation({
      query: (payload) => {
        return {
          method: "POST",
          body: gqlBody.deleteHistory(payload),
        };
      },
      invalidatesTags: ["HistoryByLeadId"],
    }),
    getHistoryByLeadId: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.getHistoryByLeadId(payload),
        };
      },
      providesTags: ["HistoryByLeadId"],
    }),
  }),
  // Do not override existing endpoints
  overrideExisting: false,
});

export const { useDeleteHistoryMutation, useGetHistoryByLeadIdQuery } = History;
