import { Dialog, Transition } from "@headlessui/react";
import {
  PencilSquareIcon,
  ShieldExclamationIcon,
} from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import ActionButton from "Components/Common/Fields/ActionButton";
import GroupBySelect from "Components/Common/Fields/GroupBySelect";
import SpinnerButton from "Components/Common/Fields/SpinnerButton";
import useEscapeClose from "Components/Common/Hooks/useEscapeClose";
import {
  createSliceOptions,
  isValidArray,
  isValidObject,
  showErrorMsg,
  showSuccessMsg,
} from "Modules/util";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetDripListByLeadIdMutation } from "Redux/Leads/Drip/drip";
import {
  useLeadFollowUpMutation,
  useSuggestDripMutation,
} from "Redux/Leads/lead";
import { useGetSingleTaskQuery } from "Redux/Task/TaskQuery";

export default function AISuggestedDripApproveModal(props) {
  const {
    isOpen,
    setIsOpen,
    task,
    leadId,
    selectedDrip,
    setSelectedDrip,
    isSmsSuggestedDrip = false,
    setIsModifyDripFlowModal = () => {},
    setIsAISuggestedDripAssignModal = () => {},
    setScheduleDripData = () => {},
  } = props;
  useEscapeClose(setIsOpen); // Custom hook to close a modal when the Escape key is pressed.

  const dripList = useSelector((s) => s?.option?.dripList);
  const [dripOptions, setDripOptions] = useState([]);
  const [isTaskHasDripId, setIsTaskHasDripId] = useState(false);
  const [isChecked, setIsChecked] = useState(task?.isLeadFollowUp || false);
  const [getDripsList] = useGetDripListByLeadIdMutation();
  const [isRunningDripSelect, setIsRunningDripSelect] = useState(false);

  const [autoFolloUp, { isLoading: isAutoFollowUpLoading }] =
    useLeadFollowUpMutation();

  const [suggestAIDrip, { isLoading: isSuggestDripLoading }] =
    useSuggestDripMutation();

  const {
    data: getTask,
    isLoading: isTaskLoading,
    refetch: refetchTask,
  } = useGetSingleTaskQuery(
    { _id: task?._id },
    { skip: isSmsSuggestedDrip || false }
  );

  useEffect(() => {
    if (!isTaskLoading) {
      let dripId = getTask?.data?.task?.response?.[0]?.dripId;
      if (dripId) {
        setIsTaskHasDripId(true);
      } else {
        setIsTaskHasDripId(false);
      }
      let selectedDrip = dripList?.filter((drip) => drip?.id === dripId);
      setSelectedDrip(...selectedDrip);
    }
  }, [getTask]);

  // use effect for creating Grouped option
  useEffect(() => {
    const AIDripOption = dripList?.filter(
      (drip) =>
        drip?.DripAIPrompt &&
        drip?.status === true &&
        drip?.isSuggested === true
    );
    const customDripOption = dripList?.filter(
      (drip) =>
        !drip?.DripAIPrompt &&
        drip?.status === true &&
        drip?.isSuggested === true
    );
    const groupedOptions = [
      ...(isValidArray(AIDripOption)
        ? [
            {
              label: "AI Drips",
              options: createSliceOptions(AIDripOption, "title", "id"),
            },
          ]
        : []),
      ...(isValidArray(customDripOption)
        ? [
            {
              label: "Custom Drip",
              options: createSliceOptions(customDripOption, "title", "id"),
            },
          ]
        : []),
    ];
    if (isValidArray(AIDripOption) || isValidArray(customDripOption)) {
      setDripOptions(groupedOptions);
    }
  }, [dripList]);

  // manage change of drip selection drop down
  function handleChange(dripInfo) {
    let selectedDrip = dripList?.filter((drip) => drip?.id === dripInfo?.value);
    setSelectedDrip(...selectedDrip);
  }

  const handleCheckboxChange = async (event) => {
    let checked = event.target.checked;
    let playLoad = { isFollowUp: checked, leadId: leadId, taskId: task?._id };
    try {
      // Making an API call to autoFolloUp the lead
      const res = await autoFolloUp(playLoad);
      const leadFollowUpRes = res?.data?.data?.leadFollowUp;
      if (leadFollowUpRes) {
        const { type, message } = leadFollowUpRes; // Showing success or error message based on the response
        if (type === "success") {
          showSuccessMsg(message);
          setIsChecked(checked);
        } else if (type === "error") {
          showErrorMsg(message);
        }
      }
    } catch (error) {
      showErrorMsg("Error While Changing Auto Follow-up");
    }
  };

  async function handleSuggestDrip() {
    try {
      const res = await suggestAIDrip({
        leadId: leadId,
        ...(isSmsSuggestedDrip ? {} : { taskId: task?._id }),
      });
      const suggestedDripRes = res?.data?.data?.suggestDrip;
      if (isValidObject(suggestedDripRes)) {
        const { type, message } = suggestedDripRes;
        if (type === "success") {
          if (suggestedDripRes?.suggestedDripId && isSmsSuggestedDrip) {
            let selectDrip = dripList?.filter(
              (drip) => drip?.id === suggestedDripRes?.suggestedDripId
            );
            setSelectedDrip(...selectDrip);
          }
          showSuccessMsg(message);
        } else if (type === "error") {
          showErrorMsg(message);
        }
      } else {
        showErrorMsg("Something went wrong!");
      }
    } catch (error) {
      showErrorMsg("Error occurred while suggesting drip");
    }
  }

  const checkRunningDrip = async () => {
    try {
      const res = await getDripsList({ _id: leadId });
      if (res?.data?.data?.getDripByLeadId?.type === "success") {
        // Filter and map to get the IDs of running drips (not terminated and is playing)
        const runningDripIds =
          res?.data?.data?.getDripByLeadId?.singleDripResponse?.[0]?.dripsResponse
            ?.filter((drip) => drip?.isPlay && !drip?.isTerminated && drip?.leadDripStatus !== "completed")
            ?.map((drip) => drip?.dripId);
        setIsRunningDripSelect(runningDripIds?.includes(selectedDrip?.id)); // Check if the selected drip ID is among the running drips
      }
    } catch (error) {
      console.error("Error fetching drips:", error);
      setIsRunningDripSelect(false);
    }
  };

  useEffect(() => {
    // Check if selectedDrip and leadId are valid before making the API call
    if (isValidObject(selectedDrip) && leadId && selectedDrip) {
      checkRunningDrip();
    } else {
      setIsRunningDripSelect(false);
    }
    // Cleanup function to reset the state
    return () => {
      setIsRunningDripSelect(false);
    };
  }, [selectedDrip, leadId]);

  function handleAction() {
    if (isValidObject(selectedDrip)) {
      setIsAISuggestedDripAssignModal(true);
      setScheduleDripData({});
      setIsOpen(false);
    }
  }

  function handleSkipNodes() {
    if (isValidObject(selectedDrip)) {
      setIsModifyDripFlowModal(true);
      setIsOpen(false);
    }
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[1111]" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-5xl border-b-2 py-2 transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-5 ">
                <div className="mt-1 border-b-2 py-2 sm:mt-0 flex justify-between items-center">
                  <div className="sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-800 flex justify-between items-center"
                    >
                      Approve AI Suggested Drip
                    </Dialog.Title>
                  </div>
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 "
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-5">
                  <div className="flex space-x-2 mb-2">
                    <lable className="font-bold mt-3.5 mr-9">
                      Select Drip :
                    </lable>
                    <div className="w-[460px]">
                      <GroupBySelect
                        options={dripOptions}
                        handleChange={handleChange}
                        initialvalue={
                          isValidObject(selectedDrip)
                            ? createSliceOptions([selectedDrip], "title", "id")
                            : null
                        }
                        placeholder="Select Drip"
                      />
                    </div>
                    {!isTaskHasDripId ? (
                      <div className="flex justify-end items-center mt-2">
                        <SpinnerButton
                          type="submit"
                          toolTip="Suggest Drip Using AI"
                          title="Suggest Drip"
                          action={() => handleSuggestDrip()}
                          className="btn-orange rounded-lg text-sm font-medium disabled:cursor-not-allowed disabled:bg-orange-400 disabled:opacity-75"
                          loading={isSuggestDripLoading}
                          isDisable={false}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {selectedDrip?.description ? (
                    <div className="space-x-2">
                      <lable className="font-bold">Drip Description :</lable>
                      <lable>{selectedDrip?.description}</lable>
                    </div>
                  ) : (
                    <></>
                  )}
                  {!isSmsSuggestedDrip && (
                    <div className="space-x-4">
                      <lable className="font-bold">Auto Follow-up :</lable>
                      <input
                        type="checkbox"
                        className="text-orange-500 bg-gray-200 checked:border-orange-500 focus:ring-orange-500"
                        checked={isChecked}
                        onClick={(e) => e.stopPropagation()}
                        onChange={handleCheckboxChange}
                        title={"Auto Follow-up"}
                        disabled={false}
                      />
                    </div>
                  )}
                </div>
                {isRunningDripSelect && selectedDrip && (
                  <div className="bg-yellow-100 mt-5 border-l-4  border-yellow-500 text-yellow-700 p-2 rounded-md shadow-md">
                    <div className="flex items-center">
                      <ShieldExclamationIcon className="h-5 w-5 text-yellow-700 mr-2" />
                      <p className="font-bold text-xs">Warning:</p>
                      <p className="ml-1 mt-1 text-xs">
                        This lead is already on the selected drip campaign. If
                        you reassign it, the lead will start from the beginning
                        again.
                      </p>
                    </div>
                    <p className="ml-8 mt-1 font-semibold text-sm">
                      Are you sure you want to reassign the drip?
                    </p>
                  </div>
                )}
                <div className="flex justify-end pt-6">
                  <SpinnerButton
                    className="inline-flex w-full justify-center rounded-md btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                    title={"Skip Nodes"}
                    action={() => handleSkipNodes()}
                    type={"submit"}
                    isDisable={!isValidObject(selectedDrip)}
                    toolTip="Skip Nodes"
                  />
                  <SpinnerButton
                    className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                    title={"Approve"}
                    action={() => handleAction()}
                    type={"button"}
                    loading={false}
                    isDisable={!isValidObject(selectedDrip)}
                    toolTip={
                      isValidObject(selectedDrip)
                        ? "Approve Suggested Drip"
                        : "Please Select Drip"
                    }
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
