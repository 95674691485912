import React from "react";

const LoadingModal = () => {
  // const loadingMessage = useSelector((state) => state?.theme?.loadingMessage);

  return (
    <div className="flex justify-center items-center absolute top-0 bottom-0 left-0 right-0 z-[999999] cursor-progress bg-black/50 transition-opacity duration-300 ease-in-out">
      <div className="bg-white w-[480px] h-[230px] rounded-lg flex flex-col justify-center items-center p-6 shadow-xl transform transition-transform duration-300 ease-in-out scale-105">
        <div className="flex space-x-2 mb-6">
          <div className="h-7 w-7 bg-orange-400 rounded-full animate-bounce"></div>
          <div className="h-7 w-7 bg-orange-400 rounded-full animate-bounce [animation-delay:-0.8s]"></div>
          <div className="h-7 w-7 bg-orange-400 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
        </div>
        <p className="text-lg font-semibold text-center text-gray-700">
          {/* {loadingMessage ?? "Loading..."} */}
          Please wait, your request is being processed...
        </p>
      </div>
    </div>
  );
};

export default LoadingModal;
