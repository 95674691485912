import { ScaleLoader } from "react-spinners";

const SpinnerButton = (props) => {
  const {
    title = "button",
    className = "",
    action = () => {},
    type = "button",
    loading = false,
    isDisable = false,
    toolTip = "",
  } = props;
  return (
    <button
      onClick={action}
      disabled={loading || isDisable}
      type={type}
      title={toolTip}
      className={`flex w-full justify-center  bg-gray px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-gray-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  ${className}`}
    >
      {loading ? (
        <span className="h-[20px] flex justify-center items-center">
          <ScaleLoader color="#ffff" size={8} width={4} height={16} />
        </span>
      ) : (
        <span className="h-[20px] flex justify-center items-center">
          {title}
        </span>
      )}
    </button>
  );
};

export default SpinnerButton;
