export const gqlBody = {
  accountSetup(payload) {
    return {
      query: `mutation UserAccountSetup($input: UserAccountSetupInput) {
        userAccountSetup(input: $input) {
          type
          message
          code
          token
        }
      }`,
      variables: { input: payload },
    };
  },
};
