export const gqlBody = {
  getCallByLeadId(payload) {
    return {
      query: `query GetCallByLeadId($input: inputSingleLead) {
        getCallByLeadId(input: $input) {
          type
          message
          code
          callResponse {
            _id
            userId
            leadId
            agentId
            userPhoneNo
            leadPhoneNo
            callSid
            userCallResponse
            leadCallResponse
            userCallStatus
            userCallDuration
            leadCallStatus
            leadCallDuration
            createdAt
            updatedAt
            type
            note
            callType
          }
        }
      }`,
      variables: { input: payload },
    };
  },
  addCallLogs(payload) {
    return {
      query: `mutation AddCallLogs($input: CallLogInput) {
        addCallLogs(input: $input) {
          type
          message
          code
        }
      }`,
      variables: { input: payload },
    };
  },
  deleteCall(payload) {
    return {
      query: `mutation DeleteCall($input: DeleteCallInput) {
        deleteCall(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
};
