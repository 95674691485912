import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { NewspaperIcon } from "@heroicons/react/24/solid";
import CustomDatePicker from "Components/Common/Fields/CustomDatePicker";
import SpinnerButton from "Components/Common/Fields/SpinnerButton";
import useEscapeClose from "Components/Common/Hooks/useEscapeClose";
import { showErrorMsg, showSuccessMsg } from "Modules/util";
import { useCloseLeadMutation } from "Redux/Leads/lead";
import { useFormik } from "formik";
import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router";
import * as Yup from "yup";

const ClosedLeadInfoModal = ({
  isModalOpen,
  setModalOpen,
  leadId,
  LeadIsClosed,
  leadData,
}) => {
  // close Lead Mutation
  const [closeLead, { isLoading }] = useCloseLeadMutation();
  useEscapeClose(setModalOpen); // Custom hook to close a modal when the Escape key is pressed.
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      closeDate: leadData?.closeDate ? leadData?.closeDate : "",
      closingGift: leadData?.closingGift ? true : false,
      leftReview: leadData?.leftReview ? true : false,
      socialMediaReview: leadData?.socialMediaReview || "no",
      requestedReview: leadData?.requestedReview || "no",
    },
    validationSchema: Yup.object().shape({
      closeDate: Yup.string().required("Closed Date is required."),
    }),
    onSubmit: (values, { resetForm }) => {
      let closeLeadPayLoad = {
        ...values,
        closingGift:
          values?.closingGift === false || values?.closingGift
            ? values?.closingGift
            : null,
        leftReview:
          values?.leftReview === false || values?.leftReview
            ? values?.leftReview
            : null,
        leadId,
      };
      closeLead(closeLeadPayLoad)
        .then((response) => {
          if (response?.data?.data?.closeLead?.type === "success") {
            setModalOpen(false);
            // resetForm();
            navigate("/leads");
            showSuccessMsg(response?.data?.data?.closeLead?.message);
          } else if (response?.data?.data?.closeLead?.type === "error") {
            showErrorMsg(response?.data?.data?.closeLead?.message);
          } else if (response?.data?.errors) {
            showErrorMsg("Error occurred while Closing Lead.");
          }
        })
        .catch((error) => showErrorMsg("Error occurred while Closing Lead."));
    },
  });

  useEffect(() => {
    if (isModalOpen) {
      formik.resetForm();
    }
  }, [isModalOpen]);

  return (
    <Transition.Root appear show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[1111]"
        onClose={() => {
          //   formik.resetForm();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-2xl px-5 py-2">
                <div className="">
                  <div className="mt-1 border-b-2 py-2 sm:mt-0 flex justify-between items-center">
                    <div className="sm:text-left ">
                      <Dialog.Title
                        as="div"
                        className="text-lg font-medium leading-6 text-gray-800 flex justify-between items-center"
                      >
                        <h6 className="flex">
                          <NewspaperIcon className="w-5 text-orange-500 mr-2" />
                          Close Lead Information
                        </h6>
                      </Dialog.Title>
                    </div>
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 "
                      onClick={() => {
                        // formik.resetForm();
                        setModalOpen(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mb-2">
                    <div className="mt-2 ">
                      <form
                        action="#"
                        method="POST"
                        className="space-y-4"
                        onSubmit={formik.handleSubmit}
                      >
                        <div className="w-full">
                          <CustomDatePicker
                            formik={formik}
                            label="Close Date"
                            initialValue={
                              formik?.values?.closeDate || new Date()
                            }
                            name="closeDate"
                            placeholder="Select Closed Date"
                          />
                        </div>
                        <div className=" w-full space-x-1.5">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Got Closing Gift
                          </label>
                          <div className="flex items-center space-x-1.5">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Yes
                            </label>
                            <input
                              name="closingGift"
                              type="radio"
                              className="text-orange-400 focus:outline-orange-400 focus:ring-0 focus:boder-none cursor-pointer"
                              checked={formik?.values?.closingGift === true}
                              onChange={() =>
                                formik?.setFieldValue("closingGift", true)
                              }
                            />
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              No
                            </label>
                            <input
                              name="closingGift"
                              type="radio"
                              className="text-orange-400 focus:outline-orange-400 focus:ring-0 focus:boder-none cursor-pointer"
                              checked={formik?.values?.closingGift === false}
                              onChange={() =>
                                formik?.setFieldValue("closingGift", false)
                              }
                            />
                          </div>
                        </div>
                        <div className="w-full space-x-1.5">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Requested Review
                          </label>

                          <div className="flex items-center space-x-1.5">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Yes
                            </label>
                            <input
                              name="requestedReview"
                              type="radio"
                              className="text-orange-400 focus:outline-orange-400 focus:ring-0 focus:boder-none cursor-pointer"
                              checked={
                                formik?.values?.requestedReview === "yes"
                              }
                              onChange={() =>
                                formik?.setFieldValue("requestedReview", "yes")
                              }
                            />
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              No
                            </label>

                            <input
                              name="requestedReview"
                              type="radio"
                              className="text-orange-400 focus:outline-orange-400 focus:ring-0 focus:boder-none cursor-pointer"
                              checked={formik?.values?.requestedReview === "no"}
                              onChange={() =>
                                formik?.setFieldValue("requestedReview", "no")
                              }
                            />
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Will Not Request
                            </label>

                            <input
                              name="requestedReview"
                              type="radio"
                              className="text-orange-400 focus:outline-orange-400 focus:ring-0 focus:boder-none cursor-pointer"
                              checked={
                                formik?.values?.requestedReview ===
                                "will not request"
                              }
                              onChange={() =>
                                formik?.setFieldValue(
                                  "requestedReview",
                                  "will not request"
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="w-full space-x-1.5">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Left Review
                          </label>

                          <div className="flex items-center space-x-1.5">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Yes
                            </label>
                            <input
                              name="leftReview"
                              type="radio"
                              className="text-orange-400 focus:outline-orange-400 focus:ring-0 focus:boder-none cursor-pointer"
                              checked={formik?.values?.leftReview === true}
                              onChange={() =>
                                formik?.setFieldValue("leftReview", true)
                              }
                            />
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              No
                            </label>

                            <input
                              name="leftReview"
                              type="radio"
                              className="text-orange-400 focus:outline-orange-400 focus:ring-0 focus:boder-none cursor-pointer"
                              checked={formik?.values?.leftReview === false}
                              onChange={() =>
                                formik?.setFieldValue("leftReview", false)
                              }
                            />
                          </div>
                        </div>
                        <div className="w-full space-x-1.5">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Added To Social Media
                          </label>

                          <div className="flex items-center space-x-1.5">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Yes
                            </label>
                            <input
                              name="socialMediaReview"
                              type="radio"
                              className="text-orange-400 focus:outline-orange-400 focus:ring-0 focus:boder-none cursor-pointer"
                              checked={
                                formik?.values?.socialMediaReview === "yes"
                              }
                              onChange={() =>
                                formik?.setFieldValue(
                                  "socialMediaReview",
                                  "yes"
                                )
                              }
                            />
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              No
                            </label>

                            <input
                              name="socialMediaReview"
                              type="radio"
                              className="text-orange-400 focus:outline-orange-400 focus:ring-0 focus:boder-none cursor-pointer "
                              checked={
                                formik?.values?.socialMediaReview === "no"
                              }
                              onChange={() =>
                                formik?.setFieldValue("socialMediaReview", "no")
                              }
                            />
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              They Don't Have Social Media
                            </label>

                            <input
                              name="socialMediaReview"
                              type="radio"
                              className="text-orange-400 focus:outline-orange-400 focus:ring-0 focus:boder-none cursor-pointer"
                              checked={
                                formik?.values?.socialMediaReview ===
                                "don't have social media"
                              }
                              onChange={() =>
                                formik?.setFieldValue(
                                  "socialMediaReview",
                                  "don't have social media"
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                          <SpinnerButton
                            className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                            title={"Submit"}
                            action={() => {
                              formik?.handleSubmit();
                            }}
                            type={"submit"}
                            loading={
                              isLoading && formik?.values?.closeDate !== null
                            }
                          />
                          <SpinnerButton
                            className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                            title={"Open Lead"}
                            action={() => {
                              if (
                                LeadIsClosed === "true" ||
                                LeadIsClosed === true
                              ) {
                                formik?.setFieldValue("closeDate", null);
                                formik?.handleSubmit();
                              }
                            }}
                            type={"submit"}
                            isDisable={
                              LeadIsClosed === "false" || LeadIsClosed === false
                            }
                            loading={
                              isLoading && formik?.values?.closeDate === null
                            }
                          />
                          <button
                            type="button"
                            className=" w-full justify-center rounded bg-gray px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-gray-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:ml-3 sm:w-auto"
                            onClick={() => {
                              setModalOpen(false);
                              formik.resetForm();
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ClosedLeadInfoModal;
