import { getTimeZone, isValidArray } from "Modules/util";
import moment from "moment-timezone";
export const itemTypes = {
  type: "Task",
};

export const dateButtonData = [
  {
    title: "Today",
    day: 0,
  },
  {
    title: "Tomorrow",
    day: 1,
  },
  {
    title: "2 Days",
    day: 2,
  },
  {
    title: "3 Days",
    day: 3,
  },
  {
    title: "1 Week",
    day: 7,
  },
  {
    title: "2 Weeks",
    day: 14,
  },
  {
    title: "3 Weeks",
    day: 21,
  },
  {
    title: "1 Month",
    day: 30,
  },
  {
    title: "2 Months",
    day: 60, // 30 days * 2 months
  },
  {
    title: "3 Months",
    day: 90, // 30 days * 3 months
  },
  {
    title: "6 Months",
    day: 180, // 30 days * 6 months
  },
  {
    title: "1 Year",
    day: 365, // 365 days in a year (approximate)
  },
];

export function getTodoList(getTodos) {
  let todos = getTodos?.data?.listToDo?.response;
  if (isValidArray(todos)) {
  } else {
    todos = [];
  }
  return todos?.map((category) => {
    return {
      label: category?.title,
      value: category?._id,
    };
  });
}

export function convertDatetoDateAndtime(inputDate) {
  // Parse inputDate using Moment.js
  const dateObject = moment.tz(inputDate, "YYYY-MM-DD", getTimeZone());

  // Get current time in the specified timezone
  const currentTime = moment.tz(getTimeZone());

  // Set the time part of dateObject to the current time
  dateObject.set({
    hour: currentTime?.get("hour"),
    minute: currentTime?.get("minute"),
    second: currentTime?.get("second"),
  });

  // Format the combined date and time to the desired string format
  return dateObject.toISOString();
}

export function durationOptions() {
  return [
    {
      label: "Day(s)",
      value: "day",
    },
    {
      label: "Week(s)",
      value: "week",
    },
    {
      label: "Month(s)",
      value: "month",
    },
  ];
}

export const typeTask = [
  {
    label: "Past Due",
    variable: "pastDue",
    sortVariable: "pastdue", // sending to API
    sortBy: "pastDueIndex", // get for Sorting by Index
  },
  {
    label: "Today's Tasks",
    variable: "todayTasks",
    sortVariable: "today",
    sortBy: "todayIndex",
  },
  {
    label: "Upcoming Tasks",
    variable: "upcomingTasks",
    sortVariable: "upcoming",
    sortBy: "upcomingIndex",
  },
  {
    label: "Completed Tasks",
    variable: "completeTask",
    sortVariable: "completed",
    sortBy: "complateIndex",
  },
];

export function addTimeToDate(dateTimeString, timeString) {
  // Parse the date-time string into a moment object with the specified time zone
  const dateTime = moment(dateTimeString)?.tz(getTimeZone());

  // Parse the time string into hours and minutes
  const [hours, minutes] = timeString?.split(":")?.map(Number);

  // Set the hours and minutes of the moment object
  dateTime?.set({ hour: hours, minute: minutes });

  // Return the updated date-time as a string
  return dateTime?.toISOString();
}

export const ascBucket = (bucketList) => {
  return [...bucketList].sort((a, b) => {
    const order = { day: 1, week: 2, month: 3 };
    return order[a.duration] - order[b.duration] || a.count - b.count;
  });
};

/**
 * Format a timestamp to display only the time portion.
 * @param {string} timestamp - The timestamp to be formatted.
 * @returns {string} - The formatted time.
 */
export const formatTimestamp = (timestamp) => {
  if (!timestamp) {
    return "00:00";
  }
  const inputFormat = "YYYY-MM-DDTHH:mm:ss.SSSZ";
  const outputFormat = "HH:mm";
  const parsedTimestamp = moment.tz(timestamp, inputFormat, getTimeZone());
  return parsedTimestamp.format(outputFormat);
};

/**
 * Format a date string to include both date and time.
 * @param {string} input - The date string to be formatted.
 * @returns {string} - The formatted date and time.
 */
export const formatDatestamp = (input) => {
  let momentObj = null;
  if (getTimeZone()) {
    momentObj = moment
      .tz(input, getTimeZone())
      .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  } else {
    momentObj = moment(input).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  }
  return momentObj;
};

/**
 * Convert a date string to a formatted date without time.
 * @param {string} inputDate - The date string to be formatted.
 * @returns {string} - The formatted date without time.
 */
export const convertDate = (inputDate) => {
  const formattedDate = moment
    .tz(inputDate, getTimeZone())
    .format("YYYY-MM-DD");
  return formattedDate;
};

/**
 * Get the current time formatted as a date string.
 * @returns {string} - The formatted current time.
 */
export const getCurrentTime = () => {
  const currentTime = moment().tz(getTimeZone()); // Get the current time using the provided timeZone
  const formattedTime = currentTime.format("YYYY-MM-DD");
  return formattedTime;
};

/**
 * Sorts an array of objects by their 'indexId' property.
 * @param {Array} data - The array of objects to be sorted.
 * @returns {Array} - The sorted array by 'indexId', or the original array if it's not valid.
 */
export function sortByIndexId(data = [], type = "none") {
  return isValidArray(data)
    ? data?.slice()?.sort((a, b) => {
        // Move items with null indexId to the end
        if (a?.taskOrderResponse[0]?.[type] === null) return 1;
        if (b?.taskOrderResponse[0]?.[type] === null) return -1;

        // Regular sorting by indexId
        return (
          a?.taskOrderResponse[0]?.[type] - b?.taskOrderResponse[0]?.[type]
        );
      })
    : data;
}

export const defaultTodoMatch = [
  "followup",
  "Followup",
  "FollowUp",
  "follow-up",
  "Follow-up",
  "Follow-Up",
];

export const checkUnComplateTaskLength = (
  taskList,
  completedTask,
  isCompleted
) => {
  if (isValidArray(completedTask)) {
    let myTask = taskList?.filter(
      (task) => !completedTask?.includes(task?._id)
    );
    return isCompleted
      ? myTask?.length + completedTask?.length
      : myTask?.length;
  } else {
    return taskList?.length || 0;
  }
};
