import { serverApi } from "Redux/serverApi";
import { gqlBody } from "./gqlBody";

// Create a Email History object using serverApi.injectEndpoints
const EmailHistory = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    getEmailHistoryByLeadId: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.getEmailHistoryByLeadId(payload),
        };
      },
      providesTags: ["EmailHistoryByLeadId"],
    }),
  }),
  // Do not override existing endpoints
  overrideExisting: false,
});

export const { useGetEmailHistoryByLeadIdQuery } = EmailHistory;
