export const gqlBody = {
  getTaskByLeadId(payload) {
    return {
      query: `query GetTaskByLeadId($input: inputSingleLead) {
          getTaskByLeadId(input: $input) {
            type
            message
            code
            taskResponse {
              _id
              date
              description
              isCompleted
              toDoId
              agent
              leadId
              errorURL
              errorCode
              errorMessage
              isTime
              dripId
              isDripAdded
              dripTitle
              dripDescription
              isLeadFollowUp
            }
          }
        }`,
      variables: { input: payload },
    };
  },
};
