import {
  getLocalStorage,
  setLocalStorage,
  showErrorMsg,
  showSuccessMsg,
} from "Modules/util";
import { useSaveLeadDetailsSegmentsMutation } from "Redux/Leads/lead";
import React, { useEffect } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useSelector } from "react-redux";
import CustomDragLayer from "../CustomDragLayer";
// import ResizableDiv from "../Lead/ResizableSidebar";

export const Box = function Box({
  dragData,
  LeadSegments,
  setDropChange,
  children,
  canDrop,
}) {
  const [SaveLeadDetailsSegmentsMutation] =
    useSaveLeadDetailsSegmentsMutation();

  const loginUser = useSelector((state) => state?.user?.currentUser); //getting current logged in user

  // function to get lead segments if new permission is added it takes lead segments otherwise it's take local storage lead segments
  function getLeadSegments() {
    const sortData = LeadSegments?.map(({ container, name }) => ({
      container,
      name,
    }));

    const leadProfileSortStore = getLocalStorage("leadProfileSort");

    if (sortData?.length !== JSON.parse(leadProfileSortStore || `[]`)?.length) {
      return sortData;
    } else {
      return JSON.parse(leadProfileSortStore || `[]`);
    }
  }

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: "TRUE",
      item: { dragData },
      end: ({ dragData }, monitor) => {
        const binData = monitor?.getDropResult()?.binData;

        if (
          dragData?.name &&
          dragData?.container &&
          binData?.container &&
          binData?.name
        ) {
          let segments = getLeadSegments();
          const newSortData = segments?.map((s) => {
            if (s?.name === dragData?.name) {
              return { ...s, container: binData?.mapIndex };
            } else if (s?.name === binData?.name) {
              return { ...s, container: dragData?.mapIndex };
            }
            return s;
          });

          setLocalStorage("leadProfileSort", JSON.stringify(newSortData));

          let payload = {
            userId: loginUser?._id,
            leadSegment: newSortData,
          };

          let validateMovement = dragData?.container !== binData?.container;
          if (validateMovement) {
            // save segments mutation
            SaveLeadDetailsSegmentsMutation(payload)
              .then((response) => {
                if (response?.data?.data?.saveLeadSegment?.type === "success") {
                  showSuccessMsg(
                    response?.data?.data?.saveLeadSegment?.message
                  );
                } else if (
                  response?.data?.data?.saveLeadSegment?.type === "error"
                ) {
                  showErrorMsg(response?.data?.data?.saveLeadSegment?.message);
                } else if (response?.error) {
                  showErrorMsg("Error occurred while Dragging Segment");
                }
              })
              .catch((error) =>
                showErrorMsg("Error occurred while Dragging Segment.")
              );
          }

          setDropChange((prev) => !prev);
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }),
    [dragData]
  );
  const opacity = isDragging ? 0.4 : 1;

  // Clone the child element and pass additional props to it
  const childWithProps = React.cloneElement(children, {
    drag,
    canDrop,
    isDragging,
  });

  // Setup the drag preview for better visual feedback
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // <ResizableDiv classId={dragData?.container}>
    <>
      <div
        className="relative rounded-lg"
        style={{ opacity }}
        data-testid={`box`}
      >
        <CustomDragLayer preview={preview} />
        <div>{childWithProps}</div>
      </div>
    </>
    // </ResizableDiv>
  );
};
