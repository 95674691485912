import { Dialog, Transition } from "@headlessui/react";
import SpinnerButton from "Components/Common/Fields/SpinnerButton";
import { showErrorMsg, showSuccessMsg } from "Modules/util";
import { addCallLogValidationForm } from "Modules/validation";
import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { callStatusOptions } from "../assetData";
import TextArea from "Components/Common/Fields/TextArea";
import { useAddCallLogsMutation } from "Redux/Leads/Call/call";
import useEscapeClose from "Components/Common/Hooks/useEscapeClose";

const AddCallLogModal = ({ isOpen, setIsOpen, leadData, refetch }) => {
  const [addCallLogs] = useAddCallLogsMutation(); // Mutation hook for adding call logs
  const [isSubmitLoading, setIsSubmitLoading] = useState(false); // State to manage loading state during form submission
  useEscapeClose(setIsOpen); // Custom hook to close a modal when the Escape key is pressed.

  // Formik hook for form handling, validation, and submission
  const formik = useFormik({
    initialValues: {
      leadId: leadData?._id,
      status: "",
      note: "",
    },
    validationSchema: addCallLogValidationForm,
    onSubmit: async (values) => {
      try {
        setIsSubmitLoading(true); // Set loading state to true during form submission
        const response = await addCallLogs(values); // Make the API call to add call logs
        const addCallLogsResult = response?.data?.data?.addCallLogs;
        // Handle success or error response
        if (addCallLogsResult?.type === "success") {
          showSuccessMsg(addCallLogsResult?.message);
          // Close the modal, refetch data, and reset the form on success
          setIsOpen(false);
          refetch();
          formik.resetForm();
        } else {
          showErrorMsg("Error in adding call logs");
        }
      } catch (error) {
        showErrorMsg("Unexpected error in adding call logs");
      }
      setIsSubmitLoading(false); // Set loading state back to false after form submission
    },
    enableReinitialize: true,
  });

  // Effect to reset the form when the modal is opened
  useEffect(() => {
    if (isOpen) {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[1111]" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-visible rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-5">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 flex justify-between items-center mb-5"
                >
                  Add Call
                  <button
                    onClick={() => setIsOpen(false)}
                    className="focus:outline-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-gray-500 hover:text-gray-700 transition duration-300"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3.293 3.293a1 1 0 011.414 0L10 8.586l5.293-5.293a1 1 0 111.414 1.414L11.414 10l5.293 5.293a1 1 0 01-1.414 1.414L10 11.414l-5.293 5.293a1 1 0 01-1.414-1.414L8.586 10 3.293 4.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </Dialog.Title>
                <div>
                  {/* Form for adding call logs */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="px-2">
                      <div className="space-y-2 mb-2">
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Select Status
                        </label>
                        <div className="flex justify-between flex-wrap">
                          {callStatusOptions?.map(
                            (option, i) =>
                              option?.customStatus === true && (
                                <div
                                  key={option?.value}
                                  className="flex items-center justify-start w-1/2 mb-2"
                                >
                                  <input
                                    id={`status-${option.value}`}
                                    name="status"
                                    type="radio"
                                    value={option.value}
                                    checked={
                                      formik.values.status === option.value
                                    }
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className="focus:ring-orange-500 h-4 w-4 text-orange-600 border-gray-300 p-0"
                                  />
                                  <label
                                    htmlFor={`status-${option.value}`}
                                    className="flex ml-2 text-sm text-gray-900 items-center"
                                  >
                                    {option.icon}
                                    {option.label}
                                  </label>
                                </div>
                              )
                          )}
                        </div>
                        {formik.touched?.status && formik.errors?.status && (
                          <div className="text-red-500 text-sm mt-1">
                            {formik.errors?.status}
                          </div>
                        )}
                      </div>
                      <TextArea
                        id="note"
                        label="Note"
                        name="note"
                        formik={formik}
                        className="border-1 border-gray-300 bg-gray-100 placeholder-gray-700"
                        rows={2}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="note"
                        placeholder="Enter Note"
                      />
                    </div>
                    <div className="w-full flex gap-3 justify-end mt-6">
                      <button
                        type="button"
                        className="bg-gray-400 hover:bg-gray-500 text-white font-medium text-sm py-2 px-4 rounded-md"
                        onClick={() => setIsOpen(false)}
                      >
                        Cancel
                      </button>
                      <div className="w-14 h-8">
                        <SpinnerButton
                          type="Submit"
                          className="btn-orange"
                          title="Add"
                          loading={isSubmitLoading}
                          isDisable={!formik.dirty}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddCallLogModal;
