import moment from "moment-timezone";
import { toast } from "react-toastify";
export const DATAGRID_KEY = process.env.REACT_APP_DATAGRID_KEY;

export const isValidArray = (data) => {
  return data && Array.isArray(data) && data.length > 0;
};

export const isValidObject = (data) => {
  return (
    typeof data === "object" && data !== null && Object.keys(data).length !== 0
  );
};

export const getAuthToken = () => {
  return localStorage.getItem("AUTH_TOKEN") ?? "";
};

export const setAuthToken = (data) => {
  return localStorage.setItem("AUTH_TOKEN", data);
};

export const removeAuthToken = () => {
  return localStorage.removeItem("AUTH_TOKEN");
};

export const showSuccessMsg = (msg = "", isAlert = false) => {
  toast.success(msg);
};

export const showErrorMsg = (msg = "Something went wrong.") => {
  toast.error(msg ?? "Something went wrong.");
};

export const setUser = (data) => {
  return localStorage.setItem("USER", data);
};

export const getUser = () => {
  return localStorage.getItem("USER") ?? "";
};

export const setUserId = (data) => {
  return localStorage.setItem("USER_Id", data);
};

export const getUserId = () => {
  return localStorage.getItem("USER_Id") ?? "";
};

export const removeUser = (data) => {
  return localStorage.removeItem("USER");
};

export const removeUserId = () => {
  return localStorage.removeItem("USER_Id") ?? "";
};

export const setLocalStorage = (key, data) => {
  return localStorage.setItem(key, data);
};

export const removeLocalStorageItem = (key) => {
  return localStorage.removeItem(key);
};

export const getLocalStorage = (key) => {
  return localStorage.getItem(key) ?? "";
};

export const removeLocalStorage = (key) => {
  return localStorage.removeItem(key || "none") ?? "";
};

export const getTimeZone = () => {
  return localStorage.getItem("timezone") || "America/Los_Angeles";
};

export const setLeadTableConfig = (data) => {
  return localStorage.setItem("LEAD_TABLE_CONFIG", data);
};

export const getLeadTableConfig = () => {
  return localStorage.getItem("LEAD_TABLE_CONFIG") ?? "";
};
export const removeLeadTableConfig = () => {
  return localStorage.removeItem("LEAD_TABLE_CONFIG") ?? "";
};

export const dismissToast = () => {
  toast.dismiss();
};

export const convertToUTC = (dateString, timeZone) => {
  // Convert to system timezone which is selected in setting & after conver it into UTC
  let utcTime = moment.tz(dateString, timeZone).utc().format();
  return utcTime;
};

export const removeKeysWithoutValue = (obj, key, conditionValue) => {
  if (obj?.key) {
    if (
      obj?.[key] === conditionValue ||
      obj?.[key] === null ||
      obj?.[key] === undefined
    ) {
      delete obj?.[key];
    }
  }
  return obj;
};

export const createFirstLetterCapital = (value) => {
  return value ? value?.charAt(0)?.toUpperCase() + value?.slice(1) : "";
};

export const showSimpleDateFormat = (input) => {
  return moment(input).format("MMMM Do, YYYY");
};

export const showDateFormat = (input) => {
  const timeZone = getTimeZone();
  const momentObj = moment.utc(input).tz(timeZone);
  const now = moment().tz(timeZone);

  return momentObj.calendar(now, {
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    lastDay: "[Yesterday]",
    nextWeek: "Do MMMM YYYY",
    lastWeek: function (now) {
      const daysAgo = now.diff(this, "days");
      if (daysAgo === 1) {
        return "[Yesterday]";
      } else if (daysAgo >= 2 && daysAgo <= 6) {
        return `[${daysAgo} days ago]`;
      } else {
        return `Do MMMM YYYY`;
      }
    },
    sameElse: "Do MMMM YYYY",
  });
};

export const showDateAndTimeFormat = (input) => {
  if (!input) {
    return "";
  }
  const timeZone = getTimeZone();
  const momentObj = moment.utc(input).tz(timeZone);
  const now = moment().tz(timeZone);

  return momentObj.calendar(now, {
    sameDay: "[Today at] h:mm A",
    nextDay: "[Tomorrow at] h:mm A",
    lastDay: "[Yesterday at] h:mm A",
    nextWeek: "Do MMMM YYYY, h:mm A",
    lastWeek: function (now) {
      const daysAgo = now.diff(this, "days");
      if (daysAgo === 1) {
        return "[Yesterday at] h:mm A";
      } else if (daysAgo >= 2 && daysAgo <= 6) {
        return `[${daysAgo} days ago] [at] h:mm A`;
      } else {
        return `Do MMMM YYYY, h:mm A`;
      }
    },
    sameElse: "Do MMMM YYYY, h:mm A",
  });
};

export const showTaskDateFormat = (input) => {
  const timeZone = getTimeZone();
  const momentObj = moment.utc(input).tz(timeZone);
  const now = moment().tz(timeZone);

  return momentObj.calendar(now, {
    sameDay: `[Today: ${momentObj.format("dddd, MMMM Do, YYYY")}]`,
    nextDay: `[Tomorrow: ${momentObj.format("dddd, MMMM Do, YYYY")}]`,
    lastDay: `[Yesterday: ${momentObj.format("dddd, MMMM Do, YYYY")}]`,
    nextWeek: "dddd, MMMM Do, YYYY",
    lastWeek: function (now) {
      const daysAgo = now.diff(this, "days");
      if (daysAgo === 1) {
        return `[Yesterday: ${momentObj.format("dddd, MMMM Do, YYYY")}]`;
      } else if (daysAgo >= 2 && daysAgo <= 6) {
        return `[${daysAgo} days ago: ${momentObj.format(
          "dddd, MMMM Do, YYYY"
        )}]`;
      } else {
        return `dddd, MMMM Do, YYYY`;
      }
    },
    sameElse: "dddd, MMMM Do, YYYY",
  });
};

export const showTaskDateTimeFormat = (input) => {
  const timeZone = getTimeZone();
  const momentObj = moment.utc(input).tz(timeZone);
  const now = moment().tz(timeZone);

  return momentObj.calendar(now, {
    sameDay: `[Today: ${momentObj.format("dddd, MMMM Do, YYYY, h:mm A")}]`,
    nextDay: `[Tomorrow: ${momentObj.format("dddd, MMMM Do, YYYY, h:mm A")}]`,
    lastDay: `[Yesterday: ${momentObj.format("dddd, MMMM Do, YYYY, h:mm A")}]`,
    nextWeek: "dddd, MMMM Do, YYYY, h:mm A",
    lastWeek: function (now) {
      const daysAgo = now.diff(this, "days");
      if (daysAgo === 1) {
        return `[Yesterday: ${momentObj.format(
          "dddd, MMMM Do, YYYY, h:mm A"
        )}]`;
      } else if (daysAgo >= 2 && daysAgo <= 6) {
        return `[${daysAgo} days ago: ${momentObj.format(
          "dddd, MMMM Do, YYYY, h:mm A"
        )}]`;
      } else {
        return `dddd, MMMM Do, YYYY, h:mm A`;
      }
    },
    sameElse: "dddd, MMMM Do, YYYY, h:mm A",
  });
};

export const showTaskDateHalfMonthFormat = (input) => {
  const timeZone = getTimeZone();
  const momentObj = moment.utc(input).tz(timeZone);
  const now = moment().tz(timeZone);

  return momentObj.calendar(now, {
    sameDay: `[Today: ${momentObj.format("ddd, MMM Do, YYYY")}]`,
    nextDay: `[Tomorrow: ${momentObj.format("ddd, MMM Do, YYYY")}]`,
    lastDay: `[Yesterday: ${momentObj.format("ddd, MMM Do, YYYY")}]`,
    nextWeek: "ddd, MMM Do, YYYY",
    lastWeek: function (now) {
      const daysAgo = now.diff(this, "days");
      if (daysAgo === 1) {
        return `[Yesterday: ${momentObj.format("ddd, MMM Do, YYYY")}]`;
      } else if (daysAgo >= 2 && daysAgo <= 6) {
        return `[${daysAgo} days ago: ${momentObj.format(
          "ddd, MMM Do, YYYY"
        )}]`;
      } else {
        return `ddd, MMM Do, YYYY`;
      }
    },
    sameElse: "ddd, MMM Do, YYYY",
  });
};

export const showTaskDateTimeHalfMonthFormat = (input) => {
  const timeZone = getTimeZone();
  const momentObj = moment.utc(input).tz(timeZone);
  const now = moment().tz(timeZone);

  return momentObj.calendar(now, {
    sameDay: `[Today: ${momentObj.format("ddd, MMM Do, YYYY, h:mm A")}]`,
    nextDay: `[Tomorrow: ${momentObj.format("ddd, MMM Do, YYYY, h:mm A")}]`,
    lastDay: `[Yesterday: ${momentObj.format("ddd, MMM Do, YYYY, h:mm A")}]`,
    nextWeek: "ddd, MMM Do, YYYY, h:mm A",
    lastWeek: function (now) {
      const daysAgo = now.diff(this, "days");
      if (daysAgo === 1) {
        return `[Yesterday: ${momentObj.format("ddd, MMM Do, YYYY, h:mm A")}]`;
      } else if (daysAgo >= 2 && daysAgo <= 6) {
        return `[${daysAgo} days ago: ${momentObj.format(
          "ddd, MMM Do, YYYY, h:mm A"
        )}]`;
      } else {
        return `ddd, MMM Do, YYYY, h:mm A`;
      }
    },
    sameElse: "ddd, MMM Do, YYYY, h:mm A",
  });
};

export const showLeadDateTimeFormat = (input) => {
  if (!input) {
    return "-";
  }
  const timeZone = getTimeZone();
  const dateTime = moment(input).tz(timeZone).format("MMMM Do, YYYY, h:mm A");
  return dateTime;
};

export const showLeadDateFormat = (input) => {
  if (!input) {
    return "-";
  }
  const timeZone = getTimeZone();
  const dateTime = moment(input).tz(timeZone).format("MMMM Do, YYYY");
  return dateTime;
};

export function getFilterData(data, type) {
  let list = data?.filter(function (user) {
    return user?.role?.roleName === type;
  });

  return getOptionData(list);
}

export function getOptionData(data) {
  let list = [];
  if (!isValidArray(data)) {
    return [];
  }
  data?.map(
    (value, index) =>
      (list[index] = {
        value: value?._id ?? value?.id,
        label: value?.name ?? value?.title,
      })
  );
  return list;
}

export function getSendItemsOption(data, name) {
  let list = [];
  if (!isValidArray(data)) {
    return [];
  }
  data?.map(
    (value, index) =>
      (list[index] = {
        value: value,
        label: `(${name}) - ${formatPhoneNumber(value)}`,
      })
  );
  return list;
}
export function getUserPhoneOption(data) {
  let list = [];
  if (!isValidArray(data)) {
    return [];
  }
  data?.map(
    (value, index) => (list[index] = { value: value, label: `${formatPhoneNumber(value)}` })
  );
  return list;
}

export function getOptions(data, key, label) {
  let list = [];
  if (!isValidArray(data)) {
    return [];
  }
  data?.map(
    (value, index) =>
      (list[index] = {
        id: value?._id,
        value: value?.[key],
        label:
          `(${value?.firstName}) -${value?.[label]}` ??
          value?.name ??
          value?.title,
      })
  );
  return list;
}

// Use the includes method to check if moduleName exists in the userPermissions array
export function isPermission(userPermissions, moduleName) {
  return userPermissions?.includes(moduleName);
}

// Function to parse mentions in a text
export const parseMentions = (text, IsGreen) => {
  // eslint-disable-next-line no-useless-escape
  const mentionRegex = /\@\[([^\]]+?)\]\(([^)]+?)\)/g;
  return text?.split(mentionRegex)?.map((part, index) => {
    if (index % 3 === 1) {
      // This is the username between @[]() - Apply styles as needed
      return (
        <span
          className={`text-bold ${
            IsGreen ? "text-emerald-600" : "text-orange-500"
          }  `}
          key={index}
        >
          @{part}
        </span>
      );
    } else if (index % 3 === 2) {
      // This is the user ID between @[]() - You can access it if needed
      return null;
    } else {
      // This is the regular text
      return part;
    }
  });
};

export const convertCapitalize = (str) => {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

export const createSelectOptions = (data = []) => {
  return isValidArray(data)
    ? data?.map((agent) => {
        return { label: agent?.name, value: agent?._id };
      })
    : [];
};

// export const createQueryParams = ({
//   search = "",
//   category = [],
//   lender = "",
//   agent = [],
//   sort = "asc",
//   leadType = [],
//   includeTags = [],
//   tagType = "or",
//   excludeTags = [],
//   communication = [],
//   registerStartDate = "",
//   registerEndDate = "",
//   registerAllTime = true,
//   taskType = [],
//   taskDate = "",
//   closeStartDate = "",
//   closeEndDate = "",
//   closeAllTime = true,
//   dripId = [],
//   source = [],
//   communicationStatus = "",
//   includeDrips = [],
//   excludeDrips = [],
//   dripStatus = "",
//   filterEmailStatus = "",
//   filterPhoneStatus = "",
//   filterTextStatus = "",
//   filterCommunicationFlag = null,
//   optedOutType = [],
//   messageStartDate = null,
//   messageEndDate = null,
// }) => {
//   let queryString = "";
//   if (isValidArray(category)) {
//     queryString += `${queryString ? "&" : "?"}category=${JSON.stringify(
//       category
//     )}`;
//   }
//   if (search) {
//     queryString += `${queryString ? "&" : "?"}search=${search}`;
//   }
//   if (isValidArray(agent)) {
//     queryString += `${queryString ? "&" : "?"}agent=${JSON.stringify(agent)}`;
//   }
//   if (lender) {
//     queryString += `${queryString ? "&" : "?"}lender=${lender}`;
//   }
//   if (sort) {
//     queryString += `${queryString ? "&" : "?"}sort=${sort}`;
//   }
//   if (isValidArray(leadType)) {
//     queryString += `${queryString ? "&" : "?"}leadType=${JSON.stringify(
//       leadType
//     )}`;
//   }
//   if (isValidArray(includeTags)) {
//     queryString += `${queryString ? "&" : "?"}includeTags=${JSON.stringify(
//       includeTags
//     )}`;
//   }
//   if (tagType) {
//     queryString += `${queryString ? "&" : "?"}tagType=${tagType}`;
//   }
//   if (isValidArray(excludeTags)) {
//     queryString += `${queryString ? "&" : "?"}excludeTags=${JSON.stringify(
//       excludeTags
//     )}`;
//   }
//   if (isValidArray(communication)) {
//     queryString += `${queryString ? "&" : "?"}communication=${JSON.stringify(
//       communication
//     )}`;
//   }
//   if (communicationStatus) {
//     queryString += `${
//       queryString ? "&" : "?"
//     }communicationStatus=${communicationStatus}`;
//   }
//   if (registerStartDate) {
//     queryString += `${
//       queryString ? "&" : "?"
//     }registerStartDate=${registerStartDate}`;
//   }
//   if (registerEndDate) {
//     queryString += `${
//       queryString ? "&" : "?"
//     }registerEndDate=${registerEndDate}`;
//   }
//   queryString += `${queryString ? "&" : "?"}registerAllTime=${registerAllTime}`;
//   if (isValidArray(taskType)) {
//     queryString += `${queryString ? "&" : "?"}taskType=${JSON.stringify(
//       taskType
//     )}`;
//   }
//   if (taskDate) {
//     queryString += `${queryString ? "&" : "?"}taskDate=${taskDate}`;
//   }
//   if (closeStartDate) {
//     queryString += `${queryString ? "&" : "?"}closeStartDate=${closeStartDate}`;
//   }
//   if (closeEndDate) {
//     queryString += `${queryString ? "&" : "?"}closeEndDate=${closeEndDate}`;
//   }
//   queryString += `${queryString ? "&" : "?"}closeAllTime=${closeAllTime}`;
//   if (isValidArray(dripId)) {
//     queryString += `${queryString ? "&" : "?"}dripId=${JSON.stringify(dripId)}`;
//   }
//   if (isValidArray(source)) {
//     queryString += `${queryString ? "&" : "?"}source=${JSON.stringify(source)}`;
//   }
//   if (isValidArray(includeDrips)) {
//     queryString += `${queryString ? "&" : "?"}includeDrips=${JSON.stringify(
//       includeDrips
//     )}`;
//   }
//   if (isValidArray(excludeDrips)) {
//     queryString += `${queryString ? "&" : "?"}excludeDrips=${JSON.stringify(
//       excludeDrips
//     )}`;
//   }
//   if (dripStatus) {
//     queryString += `${queryString ? "&" : "?"}dripStatus=${dripStatus}`;
//   }
//   if (filterEmailStatus) {
//     queryString += `${
//       queryString ? "&" : "?"
//     }filterEmailStatus=${filterEmailStatus}`;
//   }
//   if (filterPhoneStatus) {
//     queryString += `${
//       queryString ? "&" : "?"
//     }filterPhoneStatus=${filterPhoneStatus}`;
//   }
//   if (filterTextStatus) {
//     queryString += `${
//       queryString ? "&" : "?"
//     }filterTextStatus=${filterTextStatus}`;
//   }
//   if (filterCommunicationFlag) {
//     queryString += `${
//       queryString ? "&" : "?"
//     }filterCommunicationFlag=${filterCommunicationFlag}`;
//   }
//   if (isValidArray(optedOutType)) {
//     queryString += `${queryString ? "&" : "?"}optedOutType=${JSON.stringify(
//       optedOutType
//     )}`;
//   }
//   if (messageStartDate && messageEndDate) {
//     queryString += `&messageStartDate=${messageStartDate}&messageEndDate=${messageEndDate}`;
//   }
//   return queryString;
// };

// Checks if an object has non-empty values in it.
export function hasNonEmptyValuesInObj(obj) {
  const keyChange = [
    "closeEndDate",
    "closeStartDate",
    "dripId",
    "lender",
    "leadType",
    "communication",
    "excludeTags",
    "communicationStatus",
    "includeTags",
    "registerEndDate",
    "registerStartDate",
    "search",
    "agent",
    "source",
    "category",
    "taskDate",
    "taskType",
    "dripStatus",
    "includeDrips",
    "excludeDrips",
    "filterPhoneStatus",
    "filterEmailStatus",
    "filterTextStatus",
    "filterCommunicationFlag",
    "optedOutType",
    "messageStartDate",
    "messageEndDate",
    "undeliveredErrorCode",
  ];
  // Create a copy of the object without the "isClosed" key, if present
  const filterObj =
    obj?.hasOwnProperty("isClosed") || obj?.hasOwnProperty("sort")
      ? { ...obj, isClosed: undefined, sort: null }
      : obj;
  // Check if any non-empty value is present in the filtered object
  for (const key in filterObj) {
    const value = filterObj[key];
    // Check if the value is a non-empty string or a non-empty array
    if (
      (typeof value === "string" && value.trim() !== "") ||
      (Array.isArray(value) && value.length > 0)
    ) {
      if (keyChange?.includes(key)) {
        return true; // Return true if a non-empty value is found
      }
    }
  }
  return false; // Return false if all values are empty
}

// Function to convert input time to UTC start time
export const convertToTimezonetoUTCStart = (inputTime) => {
  const startTime = moment(inputTime)
    .tz(getTimeZone()) // Apply the specified time zone
    .utc()
    .startOf("day")
    .format();
  return startTime;
};

// Function to convert input time to UTC end time
export const convertToTimezonetoUTCEnd = (inputTime) => {
  const endTime = moment(inputTime)
    .tz(getTimeZone()) // Apply the specified time zone
    .utc()
    .endOf("day")
    .format();
  return endTime;
};
export const removePluseOne = (str) => {
  return str?.replace(/^(\+1)/, "");
};
export function createOptions(data) {
  let list = [];
  data?.map(
    (value, index) =>
      (list[index] = {
        value: value,
        label: value,
      })
  );
  return list;
}
export function createMonthsOptions(data) {
  let list = [];
  data?.map(
    (value, index) =>
      (list[index] = {
        value: index,
        label: value,
      })
  );
  return list;
}

export const leadTypes = {
  Renter: "R",
  Seller: "S",
  Buyer: "B",
};

export function deleteAllCookies() {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
  }
}

export function flushLocalStorage() {
  removeAuthToken();
  removeUser();
  removeUserId();
  removeLocalStorageItem("allAgent");
  removeLocalStorageItem("isPhoneNumber");
  removeLocalStorageItem("leadProfileSort");
}

export const getHighlightedText = (text, highlight) => {
  const escapedHighlight = highlight?.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
  if (!!text && !!highlight) {
    // Split text on highlight term, include term itself into parts, ignore case
    text = String(text);
    const parts = text?.split(new RegExp(`(${escapedHighlight})`, "gi"));
    return (
      <span>
        {parts?.map((part, index) =>
          part?.toLowerCase() === highlight?.toLowerCase() ? (
            <span className="font-semibold" key={index}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  }
};

export const getFastLetter = (str1, str2) => {
  // Initialize the result string
  let result = "";

  // Check if str1 is truthy and has length greater than 0
  if (str1?.length) {
    // Append the first character of str1 to the result
    result += str1.charAt(0);
  }

  // Check if str2 is truthy and has length greater than 0
  if (str2?.length) {
    // Append the first character of str2 to the result
    result += str2.charAt(0);
  }

  // Return the concatenated result
  return result?.toUpperCase();
};

export const isUseOther = ["#createTask", "#editTask"];

export const getDripStatusColor = {
  2: "text-lime-600",
  3: "text-red-500",
  4: "text-blue-500",
  5: "text-gray-600",
};

export const SMSTemplateVaribleReplacer = (currentUser) => {
  return {
    firstName: "John",
    lastName: "Mack",
    phone: "555-555-5555",
    email: "email@xyz.com",
    tags: "my tags",
    categories: "my catergories",
    agentFirstName: currentUser?.firstName,
    agentLastName: currentUser?.lastName,
    lender: "Jack",
    companyName: "XYZCompany",
  };
};

export const handleRemoveSpace = (values) => {
  const removeSpace = values?.replace(/\s+/g, " ")?.trim();
  return removeSpace;
};

export const isEqualData = (arr1, arr2) => {
  // Check if arrays are of equal length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Iterate through the arrays and compare data objects
  for (let i = 0; i < arr1.length; i++) {
    const dataA = arr1[i]?.data;
    const dataB = arr2[i]?.data;

    // Compare data objects
    if (JSON.stringify(dataA) !== JSON.stringify(dataB)) {
      return false; // Data objects are not equal
    }
  }

  return true; // All data objects are equal
};

// Checks if an object has non-empty values in it.
export function checkAnyFilterApplyed(obj) {
  const keyChange = [
    // "closeEndDate",
    // "closeStartDate",
    "dripId",
    "lender",
    "leadType",
    "communication",
    "excludeTags",
    "communicationStatus",
    "includeTags",
    "registerEndDate",
    "registerStartDate",
    "agent",
    "source",
    "taskDate",
    "taskType",
    "dripStatus",
    "includeDrips",
    "excludeDrips",
    "filterPhoneStatus",
    "filterEmailStatus",
    "filterTextStatus",
    "filterCommunicationFlag",
    "optedOutType",
    "messageStartDate",
    "messageEndDate",
    "undeliveredErrorCode",
  ];
  // Create a copy of the object without the "isClosed" key, if present
  let { closeEndDate, closeStartDate, ...filterObj } = obj;
  filterObj =
    obj?.hasOwnProperty("isClosed") || obj?.hasOwnProperty("sort")
      ? { ...obj, isClosed: undefined, sort: null }
      : obj;
  // Check if any non-empty value is present in the filtered object
  for (const key in filterObj) {
    const value = filterObj[key];
    // Check if the value is a non-empty string or a non-empty array
    if (
      (typeof value === "string" && value.trim() !== "") ||
      (Array.isArray(value) && value.length > 0)
    ) {
      if (keyChange?.includes(key)) {
        return true; // Return true if a non-empty value is found
      }
    }
  }
  return false; // Return false if all values are empty
}

// Check if filterData and categoryList are valid and have necessary properties
export const checkIsCloseCategoryApplied = (filterData, categoryList = []) => {
  if (
    filterData?.category?.length === 1 && // Check if only one category is selected
    categoryList.length > 0 // Check if categoryList has items
  ) {
    // Get the ID of the only selected category from filterData
    const selectedCategoryId = filterData.category[0];
    // Find a category in categoryList that is 'close' and get its ID
    const closeCategoryId = categoryList.find((c) => c?.close)?._id;
    // Check if the selected category matches the close category ID
    return selectedCategoryId === closeCategoryId;
  }
  // Return false if conditions are not met
  return false;
};

// Function to handle showing the last columns in a table configuration
export const handleShowLastColumn = () => {
  const leadTableConfig = getLeadTableConfig() || `[]`;
  let coulmnsData = JSON.parse(leadTableConfig); // Get the current table columns configuration from storage or initialize as empty array
  const colIdOrder = [
    "closeDate",
    "anniversaryDate",
    "closingGift",
    "requestedReview",
    "leftReview",
    "socialMediaReview",
  ]; // Define the order of columns that should appear at the end of the table

  // Sort columns based on their position in colIdOrder
  let updatedColumns = coulmnsData.sort((a, b) => {
    const aIndex = colIdOrder.indexOf(a.colId);
    const bIndex = colIdOrder.indexOf(b.colId);
    return aIndex - bIndex;
  });

  // Set pinned to null for columns in colIdOrder
  updatedColumns?.forEach((col) => {
    if (colIdOrder?.includes(col?.colId)) {
      col.pinned = null;
    }
  });
  setLeadTableConfig(JSON.stringify(updatedColumns)); // Update the table configuration in storage with the updatedColumns
};

export const createSliceOptions = (
  data = [],
  label = "name",
  value = "_id"
) => {
  return isValidArray(data)
    ? data?.map((item) => ({ label: item?.[label], value: item?.[value] }))
    : [];
};
export function getSentTimeType(sentTimeType) {
  const timeTypes = {
    day: "days",
    days: "days",
    hour: "hours",
    hours: "hours",
    minute: "minutes",
    minutes: "minutes",
    week: "weeks",
    weeks: "weeks",
    month: "months",
    months: "months",
    year: "years",
    years: "years",
  };
  return timeTypes[sentTimeType] || "days";
}
export function removeUndefined(obj) {
  if (!isValidObject(obj)) {
    return {};
  }
  // Iterate over the object properties
  for (const key in obj) {
    // If the property value is undefined, delete the property
    if (obj[key] === undefined) {
      delete obj[key];
    }
  }
  return obj;
}

export function cleanObject(obj) {
  return Object.entries(obj)
    .filter(
      ([key, value]) =>
        value !== null &&
        value !== "" &&
        !(Array.isArray(value) && value.length === 0)
    )
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
}
export const aiDripValidateNodesData = (data) => {
  // Check if data exists and is an array
  if (!isValidArray(data)) {
    return false;
  }
  const filteredNodes = data?.filter((node) => parseInt(node?.sentTime) === 0); // Filter nodes with sentTime equal to 0
  if (filteredNodes.length === 0) {
    return true;
  }
  // Check if exactly one node meets the condition
  if (filteredNodes.length === 1) {
    const node = filteredNodes[0];
    // Check node properties
    if (parseInt(node?.id) === 1 && node.sentTimeType === "minutes") {
      return true;
    }
  }
  return false;
};

export function getKeysWithTrueValues(obj) {
  return Object.keys(obj)?.filter((key) => obj[key] === true);
}

export function getKeysWithFalseValues(obj) {
  return Object.keys(obj)?.filter((key) => obj[key] === false);
}

export function getTimezoneWiseDate(date) {
  return moment(date)?.tz(getTimeZone())?.format("D-MM-YYYY");
}
export function replaceNewlinesWithBR(inputString) {
  return inputString?.replace(/\n/g, "<br>");
}
// Function to convert input date to string format if it is a Date object
export const dateConvertToString = (inputDate) => {
  if (inputDate instanceof Date) {
    const stringDate = inputDate?.toString();
    return stringDate;
  }
  return inputDate;
};

export const hasMeaningfulContent = (html) => {
  // Remove all tags
  const textOnly = html?.replace(/<[^>]*>/g, "")?.trim();
  return textOnly.length > 0;
};

export function isObjHaveValue(obj) {
  return Object.values(obj).every((value) => Boolean(value));
}
// Function to format phone number
export const formatPhoneNumber = (number) => {
  const cleaned = ("" + number).replace(/\D/g, "");
  if (cleaned.length === 10) {
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
  }
  return number;
};

export function isNotEmptyString(value) {
  return typeof value === "string" && value?.trim() !== "";
}

export const leadStoryPointsDateFormat = (input) => {
  if (!input) {
    return "-";
  }
  const timeZone = getTimeZone();
  const dateTime = moment(input).tz(timeZone).format("M/D/YY");
  return dateTime;
};

export function getOrdinalSuffix(number) {
  const suffixes = ["th", "st", "nd", "rd"];
  const value = number % 100;

  // Check if the number ends in 11, 12, or 13, which all use "th"
  if (value >= 11 && value <= 13) {
    return number + "th";
  }

  // Otherwise, use the appropriate suffix
  return number + (suffixes[value % 10] || "th");
}
export function getParsedLocalStorage(key) {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
}

export const getEmailOptions = (domainList, loginUser) => {
  let domainOptions = isValidArray(domainList?.data?.domainList?.domains)
    ? domainList?.data?.domainList?.domains?.map((domain) => ({
        label: `${loginUser?.firstName?.toLowerCase()}@${domain?.toLowerCase()}`,
        value: `${loginUser?.firstName?.toLowerCase()}@${domain?.toLowerCase()}`,
      }))
    : [];

  if (
    loginUser?.appPassword &&
    isValidArray(domainList?.data?.domainList?.domains)
  ) {
    domainOptions = [
      {
        label: loginUser?.email,
        value: loginUser?.email,
      },
      ...domainOptions,
    ];
  }
  return domainOptions;
};
