import React, { useEffect, useState } from "react";
import AddBirthdayModal from "../Modal/AddBirthdayModal";
import {
  isPermission,
  isValidArray,
  isValidObject,
  showErrorMsg,
  showSuccessMsg,
} from "Modules/util";
import { useLeadBirthdayMutation } from "Redux/Leads/lead";
import { useSelector } from "react-redux";
import moment from "moment";

export default function BirthdayInfo({
  leadId,
  lead,
  setIsOpenDoubleNestModal = () => {},
}) {
  const [isOpenAddBirthdayModal, setIsOpenAddBirthdayModal] = useState(false);
  const [leadBirthday, { isLoading }] = useLeadBirthdayMutation(); //leadBirthday mutation

  // State to hold selected values
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [daysOptions, setDaysOptions] = useState([]);
  const [isEdit, setIsEdit] = useState(false); // State to manage edit mode
  const [validationErros, setValidationErrors] = useState({}); // State to manage validation errors
  const userPermissions = useSelector((state) => state?.user?.permission); // Get user permissions from Redux store

  // Function to validate birthdate
  function validateBirthdate(birthDate, birthMonth, birthYear) {
    // Convert birthdate to Date object
    const birthdate = new Date(`${birthMonth} ${birthDate}, ${birthYear}`);

    // Get current date
    const currentDate = new Date();

    // Compare birthdate with current date
    if (birthdate > currentDate) {
      return false; // Birthdate is in the future
    } else {
      return true; // Birthdate is valid (not in the future)
    }
  }
  // useeEffect hook to handle double nested modal open
  useEffect(() => {
    if (isOpenAddBirthdayModal) {
      setIsOpenDoubleNestModal(true);
    } else {
      setIsOpenDoubleNestModal(false);
    }
  }, [isOpenAddBirthdayModal]);
  // Function to handle lead birthday updates
  function handleLeadBirthday(flag) {
    let birthdayPayload = {};
    let isValid = true;
    // Validate selected month, day, and year
    if (!selectedMonth) {
      setValidationErrors((prev) => ({
        ...prev,
        selectedMonth: "Month is required",
      }));
      isValid = false;
    }
    if (!selectedDay) {
      setValidationErrors((prev) => ({
        ...prev,
        selectedDay: "Day is required",
      }));
      isValid = false;
    }
    if (!selectedYear) {
      setValidationErrors((prev) => ({
        ...prev,
        selectedYear: "Year is required",
      }));
      isValid = false;
    }
    if (!flag) {
      // Check if all inputs are valid
      if (!isValid) {
        return null; // Exit function if inputs are invalid
      }
      // Check if birthdate is valid
      if (
        !validateBirthdate(
          selectedDay?.label,
          selectedMonth?.label,
          selectedYear?.label
        )
      ) {
        setValidationErrors({ errors: "Invalid Lead Birthday" });
        return null; // Exit function if birthdate is invalid
      }
    }
    if (!flag) {
      birthdayPayload = {
        leadId: leadId,
        birthDate: selectedDay?.label ?? "",
        birthMonth: selectedMonth?.label ?? "",
        birthYear: selectedYear?.label ?? "",
        isRemoveBirthDay: flag,
      };
    } else {
      birthdayPayload = {
        leadId: leadId,
        isRemoveBirthDay: flag,
      };
    }

    leadBirthday(birthdayPayload)
      .then((response) => {
        if (response?.data?.data?.leadBirthDay?.type === "success") {
          showSuccessMsg(response?.data?.data?.leadBirthDay?.message);
          setIsOpenAddBirthdayModal(false);
          setSelectedMonth(null);
          setSelectedDay(null);
          setSelectedYear(null);
          setDaysOptions(null);
        } else if (response?.data?.data?.leadBirthDay?.type === "error") {
          showErrorMsg(response?.data?.data?.leadBirthDay?.message);
        }
      })
      .catch((error) => showErrorMsg("Error occurred while Adding Tags."));
    setIsOpenAddBirthdayModal(false);
  }

  // useEffect hook to handle validation errors based on input changes
  useEffect(() => {
    if (isValidObject(validationErros)) {
      setValidationErrors(() => ({
        // Update validation errors based on input values and birthdate validation
        ...(!selectedDay ? { selectedDay: validationErros?.selectedDay } : {}),
        ...(!selectedMonth
          ? { selectedMonth: validationErros?.selectedMonth }
          : {}),
        ...(!selectedYear
          ? { selectedYear: validationErros?.selectedYear }
          : {}),
        ...(!validateBirthdate(
          selectedDay?.label,
          selectedMonth?.label,
          selectedYear?.label
        )
          ? { errors: "Invalid Lead Birthday" }
          : {}),
      }));
    }
  }, [selectedDay, selectedMonth, selectedYear]);

  // useEffect hook to reset validation errors when modal opens
  useEffect(() => {
    if (isOpenAddBirthdayModal) {
      setValidationErrors({});
    }
  }, [isOpenAddBirthdayModal]);

  return (
    <div>
      <AddBirthdayModal
        isOpen={isOpenAddBirthdayModal}
        setIsOpen={setIsOpenAddBirthdayModal}
        leadId={leadId}
        handleLeadBirthday={handleLeadBirthday}
        isLoading={isLoading}
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        daysOptions={daysOptions}
        setDaysOptions={setDaysOptions}
        setIsEdit={setIsEdit}
        isEdit={isEdit}
        lead={lead}
        validationErros={validationErros}
      />
      <div className="flex mt-4 space-x-2">
        {isValidArray(lead?.leadResponse) &&
        !lead?.leadResponse?.[0]?.birthDate ? (
          <div className="flex space-x-2">
            <label className="block text-xs font-medium leading-6 text-gray-900">
              Lead Birthday
            </label>

            {isPermission(userPermissions, "edit_leads") ? (
              <button
                className="bg-orange-400 rounded text-white px-3 text-xs"
                onClick={() => setIsOpenAddBirthdayModal(true)}
              >
                Add
              </button>
            ) : (
              <label className="block text-xs font-medium leading-6 text-gray-900">
                -
              </label>
            )}
          </div>
        ) : (
          <div className="flex space-x-2">
            <label className="block text-xs font-medium leading-6 text-gray-900">
              Lead Birthday
            </label>
            <span className="bg-orange-100 border border-orange-400 rounded px-1 space-x-1 !text-xs font-medium text-nowrap flex items-center">
              <label className="block text-xs font-medium leading-6 text-gray-900">
                {lead?.leadResponse?.[0]?.birthMonth &&
                lead?.leadResponse?.[0]?.birthDate &&
                lead?.leadResponse?.[0]?.birthYear
                  ? moment(
                      `${lead?.leadResponse?.[0]?.birthMonth} ${lead?.leadResponse?.[0]?.birthDate}, ${lead?.leadResponse?.[0]?.birthYear}`
                    )?.format("MMMM Do, YYYY")
                  : "-"}
              </label>
            </span>
            {isPermission(userPermissions, "edit_leads") ? (
              <>
                <button
                  className="bg-orange-400 rounded text-white px-3 text-xs"
                  onClick={() => {
                    setIsOpenAddBirthdayModal(true);
                    setIsEdit(true);
                  }}
                >
                  Edit
                </button>
                <button
                  className="bg-orange-400 rounded text-white px-3 text-xs"
                  onClick={() => {
                    handleLeadBirthday(true);
                    setIsEdit(false);
                  }}
                >
                  Remove
                </button>
              </>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
