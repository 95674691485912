import { serverApi } from "Redux/serverApi";
import { gqlBody } from "./gqlBody";

// Create a pendingMessagesQuerys object using serverApi.injectEndpoints
const pendingMessagesQuerys = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    PendingMessageList: builder.query({
      query: (payload) => ({
        method: "POST",
        body: gqlBody.PendingMessageList(payload),
      }),
      providesTags: ["PendingMessagesList"],
    }),
    LeadDripMessageSave: builder.mutation({
      query: (payload) => ({
        method: "POST",
        body: gqlBody.LeadDripMessageSave(payload),
      }),
      invalidatesTags: ["PendingMessagesList"],
    }),
  }),
  // Do not override existing endpoints
  overrideExisting: false,
});

export const { usePendingMessageListQuery, useLeadDripMessageSaveMutation } =
  pendingMessagesQuerys;
