import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  deleteAllCookies,
  flushLocalStorage,
  getAuthToken,
  isValidArray
} from "Modules/util";

// Create a base query with common configurations
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASEURL,
  headers: { "Content-Type": "application/json" },
  // Prepare headers function to add Authorization header if AUTH_TOKEN exists
  prepareHeaders: (headers) => {
    const authToken = localStorage.getItem("AUTH_TOKEN");
    if (authToken) {
      headers.set("Authorization", `Bearer ${getAuthToken("AUTH_TOKEN")}`);
    }
    return headers;
  },
});

// Custom base query with reauthentication logic
const baseQueryWithReauth = async (args, api, extraOptions) => {
  try {
    // Call the base query
    let result = await baseQuery(args, api, extraOptions);

    // Check for errors in the response data
    if (isValidArray(result?.data?.errors)) {
      const errorCode = result?.data?.errors[0]?.extensions?.code;

      // Handle INVALID_TOKEN error
      if (errorCode === "INVALID_TOKEN") {
        // Remove user-related information and reload the window
        flushLocalStorage();
        deleteAllCookies();
        window.location.reload();
      }
    }

    return result; // Return the result
  } catch (error) {
    throw error; // Throw the caught error
  }
};

// Create the serverApi using createApi from @reduxjs/toolkit/query
export const serverApi = createApi({
  reducerPath: "serverApi", // Specify the reducer path
  baseQuery: baseQueryWithReauth, // Use the custom base query with reauthentication logic
  refetchOnMountOrArgChange: true,
  endpoints: () => ({}), // Define your API endpoints here if needed
});
