import { serverApi } from "Redux/serverApi";
import { gqlBody } from "./gqlBody";

// Create a History object using serverApi.injectEndpoints
const SMS = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    getSMSByLeadId: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.getSMSByLeadId(payload),
        };
      },
      providesTags: ["SMSByLeadId"],
    }),
  }),
  // Do not override existing endpoints
  overrideExisting: false,
});

export const { useGetSMSByLeadIdQuery } = SMS;
