export const gqlBody = {
  getDripsList(payload) {
    return {
      query: `query Drips($input: GetAllDripsInput) {
        drips(input: $input) {
          drips {
            id
            title
            description
            status
            createdAt
            updatedAt
            DripAIPrompt
            isSuggested
          }
          code
          message
          totalCount
          type
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  getDrip(payload) {
    return {
      query: `query Drip($input: GetDripDetailInput!) {
        drip(input: $input) {
          drip {
            id
            title
            description
            status
            createdAt
            updatedAt
          }
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  addDrip(payload) {
    return {
      query: `mutation AddDrip($input: AddDripInput!) {
        addDrip(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  editDrip(payload) {
    return {
      query: `mutation Mutation($input: UpdateDripInput!) {
        updateDrip(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  getNodesAndEdges(payload) {
    return {
      query: `query NodeAndEdgesDetails($input: GetNodeAndEdgesDetails!) {
        nodeAndEdgesDetails(input: $input) {
          edgesData {
            id
            source
            target
          }
          nodesData {
            position {
              x
              y
            }
            data {
              scheduled
              sent
              target
              source
              templateId
              time
              type
              toDoId
              agentId
              title
              description
              isCustom
              isOpenAI
              openAiId
            }
            id
            type
          }
          lastNodeId
          lastEdgeId
          type
          message
          code
          title
          DripAIPrompt
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  addNodesAndEdges(payload) {
    return {
      query: `mutation Mutation($input: AddNodeInput!) {
        addNodeAndEdge(input: $input) {
          type
          message
          code
          id
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  deleteNodesAndEdges(payload) {
    return {
      query: `mutation Mutation($input: DeleteNodeInput!) {
        deleteNodeAndEdge(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  editNodeMailMessage(payload) {
    return {
      query: `mutation NodeEmailSms($input: NodeEmailSMSInput!) {
        nodeEmailSms(input: $input) {
          nodeTemplate {
            _id
            title
            subject
            body
            dripId
            nodeId
            type
            isSent
            templateId
          }
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  changeDripStatus(payload) {
    return {
      query: `mutation Mutation($input: ChangeDripStatusInput) {
        changeDripStatus(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  scheduleDripLeads(payload) {
    return {
      query: `query ListScheduleDripleads($input: GetAllScheduleDripLeadsInput) {
        listScheduleDripleads(input: $input) {
          scheduleDripLeads {
            leadName
            email
            phone
            scheduleTime
            nodeId
            dripName
            agent
            isPlayLead
            isTerminated
            skipNodes
            scheduledNodes
          }
          totalCount
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  sentScheduleDripLeads(payload) {
    return {
      query: `query SentScheduleDripleads($input: GetAllSentScheduleDripLeadsInput) {
        listSentScheduleDripleads(input: $input) {
          SentScheduleDripleads {
            leadName
            email
            phone
            sentTime
            nodeId
            dripName
            agent
          }
          code
          message
          totalCount
          type
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  nodeWiseTemplate(payload) {
    return {
      query: `query NodeTemplate($input: nodeTemplateDetailInput!) {
        nodeTemplateDetail(input: $input) {
          nodeTemplate {
            _id
            title
            subject
            body
            dripId
            nodeId
            type
            isSent
            templateId
          }
          code
          message
          type
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  removeLeadFromDrips(payload) {
    return {
      query: `mutation RemoveLeadFromDrips($input: RemoveLeadFromDripsInput) {
        removeLeadFromDrips(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  addPrompt(payload) {
    return {
      query: `mutation AddPrompt($input: promptInput) {
        addPrompt(input: $input) {
          type
          message
          code
          response {
            dripId
            nodeId
            prompt
            openAiId
          }
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  getSingleOpenAI(payload) {
    return {
      query: `query GetSingleOpenAI($input: inputOpenAI) {
        getSingleOpenAI(input: $input) {
          type
          message
          code
          response {
            _id
            dripId
            leadId
            nodeId
            promptMessage
          }
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  deleteLeadFromDrips(payload) {
    return {
      query: `mutation DeleteLeadFromDrips($input: DeleteLeadFromDripsInput) {
        deleteLeadFromDrips(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  createNodeFromAI(payload) {
    return {
      query: `mutation CreateNodeFromAI($input: CreateNodeFromAIInput) {
        createNodeFromAI(input: $input) {
          type
          message
          code
          dripDescription
          nodeResponseAI {
            type
            sentTimeType
            sentTime
            Template
            preview
          }
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  createPreviewForAITemplates(payload) {
    return {
      query: `mutation CreatePreviewForAITemplates($input: CreatePreviewForAITemplatesInput) {
        createPreviewForAITemplates(input: $input) {
          preview
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  PreviewForAINode(payload) {
    return {
      query: `mutation PreviewForAINode($input: PreviewForAINodeInput) {
        previewForAINode(input: $input) {
          code
          message
          preview
          type
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  DripDelete(payload) {
    return {
      query: `mutation DripDelete($input: DripDeleteInput) {
        dripDelete(input: $input) {
          code
          message
          type
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  DripClone(payload) {
    return {
      query: `mutation DripClone($input: DripCloneInput) {
  dripClone(input: $input) {
    code
    message
    type
      }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  EditDripSuggestion(payload) {
    return {
      query: `mutation EditDripSuggestions($input: EditDripSuggestionsInput) {
        editDripSuggestions(input: $input) {
          code
          message
          type
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
};
