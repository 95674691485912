const TextField = ({
  label = "",
  name = "",
  type = "text",
  value = "",
  placeholder = "",
  className = "",
  id = "",
  ...props
}) => {
  return (
    <div className="mb-2 w-full ">
      {/* Label for the input field */}
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="mt-2">
        {/* Input element with Formik integration */}
        <input
          id={id}
          name={name}
          type={type}
          value={value}
          disabled={true}
          placeholder={placeholder}
          className={` block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#475361] sm:text-sm sm:leading-6 disabled:opacity-75 select-none ${className}`}
          {...props}
        />
      </div>
    </div>
  );
};

export default TextField;
