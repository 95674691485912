import React, { useEffect, useState } from "react";
import { useDrop } from "react-dnd";

export const Container = ({
  initialWidth,
  containerHeight,
  binData,
  children,
}) => {
  const [width, setWidth] = useState(initialWidth); // Initial width
  const [height, setHeight] = useState(containerHeight); // Initial height
  const [isResizing, setIsResizing] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const minWidth = 500;
  const maxWidth = 800;
  const minHeight = 200;
  const maxHeight = 700;

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: "TRUE",
      drop: () => ({ binData }),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [binData]
  );

  const isActive = canDrop && isOver;
  let backgroundColor = "";
  if (isActive) {
    backgroundColor = "";
  } else if (canDrop) {
    backgroundColor = "#5bf586";
  }
  // const handleMouseDown = (e) => {
  //   const { clientX, clientY } = e;
  //   const { offsetLeft, offsetTop, offsetWidth, offsetHeight } = e.target;

  //   // Calculate distances to each border
  //   const rightDist = offsetWidth - (clientX - offsetLeft);
  //   const bottomDist = offsetHeight - (clientY - offsetTop);

  //   // Check if the click is near any border
  //   if (rightDist <= 20 || bottomDist <= 20) {
  //     setIsResizing(true);
  //     setStartX(clientX);
  //     setStartY(clientY);
  //   }
  // };

  // const handleMouseUp = () => {
  //   setIsResizing(false);
  // };

  // const handleMouseMove = (e) => {
  //   if (!isResizing) return;
  //   const { clientX, clientY } = e;
  //   let newWidth = width + (clientX - startX);
  //   let newHeight = height + (clientY - startY);

  //   // Apply min/max constraints for width
  //   newWidth = Math.min(Math.max(newWidth, minWidth), maxWidth);

  //   // Apply min/max constraints for height
  //   newHeight = Math.min(Math.max(newHeight, minHeight), maxHeight);

  //   setWidth(newWidth);
  //   setHeight(newHeight);
  //   setStartX(clientX);
  //   setStartY(clientY);
  // };

  useEffect(() => {
    const handleOutsideClick = () => {
      if (isResizing) {
        setIsResizing(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isResizing]);

  // Clone the child element and pass additional props to it
  const childWithProps = React.cloneElement(children, {
    // backgroundColor,
    canDrop: isOver,
  });

  return (
    <div
      className="space-x-4 transition duration-300 rounded-3xl"
      ref={drop}
      style={
        {
          // width: `${width}px`,
          // height: `${height < minHeight ? minHeight : height}px`,
          // minHeight: `${minHeight}px`,
          // maxHeight: `${maxHeight}px`,
          // // minWidth: `${minWidth}px`,
          // maxWidth: `${maxWidth}px`,
          // resize: "both",
          // overflow: "hidden",
          // backgroundColor: backgroundColor,
        }
      }
      // onMouseDown={handleMouseDown}
      // onMouseUp={handleMouseUp}
      // onMouseMove={handleMouseMove}
      data-testid="dustbin"
    >
      {childWithProps}
    </div>
  );
};
