export const gqlBody = {
  addTask(payload) {
    return {
      query: `mutation AddTask($input: AddTaskInput!) {
        addTask(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  listTasks(payload) {
    return {
      query: `query ListTasks($input: listTaskInput) {
        listTasks(input: $input) {
          pastDue {
            _id
            date
            description
            isLeadFollowUp
            errorURL
            errorCode
            Lead {
              _id
              contactId
              firstName
              lastName
              email
              phone
              description
              isDeleted
              createdAt
              updatedAt
              leadType
              address
              city
              state
              zipCode
              isClosed
              isFollowUp
              drips
            }
            Agent {
              _id
              name
            }
            Todos {
              _id
              title
              type
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            isTime
            isCompleted
            taskOrderResponse {
              _id
              taskId
              pastDueIndex
              todayIndex
              upcomingIndex
            }
            dripId
            isDripAdded
            dripTitle
            dripDescription
          }
          todayTasks {
            _id
            date
            description
            isLeadFollowUp
            errorURL
            errorCode
            Lead {
              _id
              contactId
              firstName
              lastName
              email
              phone
              description
              isDeleted
              createdAt
              updatedAt
              leadType
              address
              city
              state
              zipCode
              isClosed
              isFollowUp
            }
            Agent {
              _id
              name
            }
            Todos {
              _id
              title
              type
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            isTime
            isCompleted
            taskOrderResponse {
              _id
              taskId
              pastDueIndex
              todayIndex
              upcomingIndex
            }
            dripId
            isDripAdded
            dripTitle
            dripDescription
          }
          upcomingTasks {
            _id
            date
            description
            isLeadFollowUp
            errorURL
            errorCode
            Lead {
              _id
              contactId
              firstName
              lastName
              email
              phone
              description
              isDeleted
              createdAt
              updatedAt
              leadType
              address
              city
              state
              zipCode
              isClosed
              isFollowUp
            }
            Agent {
              _id
              name
            }
            Todos {
              _id
              title
              type
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            isTime
            isCompleted
            taskOrderResponse {
              _id
              taskId
              pastDueIndex
              todayIndex
              upcomingIndex
            }
            dripId
            isDripAdded
            dripTitle
            dripDescription
          }
          completeTask {
            _id
            date
            description
            isLeadFollowUp
            errorURL
            errorCode
            Lead {
              _id
              contactId
              firstName
              lastName
              email
              phone
              description
              isDeleted
              createdAt
              updatedAt
              leadType
              address
              city
              state
              zipCode
              isFollowUp
            }
            Agent {
              _id
              name
            }
            Todos {
              _id
              title
              type
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            isTime
            isCompleted
            taskOrderResponse {
              _id
              taskId
              pastDueIndex
              todayIndex
              upcomingIndex
            }
            dripId
            isDripAdded
            dripTitle
            dripDescription
          }
          totalCount
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  deleteTask(payload) {
    return {
      query: `mutation DeleteTask($input: DeleteTaskInput!) {
        deleteTask(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  getTask(payload) {
    return {
      query: `query Task($input: getTaskInput!) {
        task(input: $input) {
          response {
            _id
            date
            description
            Lead {
              _id
              contactId
              firstName
              lastName
              email
              phone
              description
              isDeleted
              createdAt
              updatedAt
              leadType
              address
              city
              state
              zipCode
              isAiReply
              source
              isClosed
              closeDate
              anniversaryDate
              closingGift
              leftReview
              socialMediaReview
              requestedReview
              birthDate
              birthMonth
              birthYear
              leadSource {
                _id
                title
              }
              contact {
                _id
                phone
                callStatus
                smsStatus
                fromOptedOutNumber
                manuallyOptedOutNo
              }
              emailStatusDetail {
                _id
                email
                emailStatus
              }
              isFollowUp
              followUpScheduledDateTime
              followUpCount
              lastDripId
              story
              storyHighlights {
                highlight
                date
              }
            }
            Agent {
              _id
              name
              agentTwilioNumber
            }
            Todos {
              _id
              title
              type
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            isTime
            isCompleted
            indexId
            taskOrderResponse {
              _id
              taskId
              pastDueIndex
              todayIndex
              upcomingIndex
            }
            errorURL
            errorCode
            errorMessage
            dripId
            isDripAdded
            dripDescription
            dripTitle
            isLeadFollowUp
          }
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  editTask(payload) {
    return {
      query: `mutation UpdateTask($input: UpdateTaskInput!) {
        updateTask(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  listBucket(payload) {
    return {
      query: `query ListBucket {
      listBucket {
        response {
          title
          count
          duration
          createdAt
          updatedAt
          _id
        }
        totalCount
        type
        message
        code
      }
    }`,
    };
  },
  addBucket(payload) {
    return {
      query: `mutation AddBucket($input: AddBucketInput!) {
        addBucket(input: $input) {
          code
          message
          type
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  deleteBucket(payload) {
    return {
      query: `mutation DeleteBucket($input: DeleteBucketInput!) {
        deleteBucket(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  completeTask(payload) {
    return {
      query: `mutation CompleteTask($input: CompleteTaskInput!) {
        completeTask(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  listTodo() {
    return {
      query: `query ListTasks {
        listToDo {
          response {
            _id
            title
            type
          }
          totalCount
          type
          message
          code
        }
      }`,
    };
  },
  addTodo(payload) {
    return {
      query: `mutation AddToDo($input: ToDoInput!) {
        addToDo(input: $input) {
          type
          message
          code
          id
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  sortTask(payload) {
    return {
      query: `mutation TaskOrder($input: TaskOrderInput) {
        taskOrder(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  taskOrder(payload) {
    return {
      query: `mutation TaskOrder($input: TaskOrderInput) {
        taskOrder(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
};
