import { createSliceOptions, isValidArray } from "Modules/util";
import { useGetDripsListQuery } from "Redux/Drip/drip";
import { useLeadSourceQuery } from "Redux/Leads/lead";
import {
  agentOption,
  dripOption,
  lenderOption,
  sourceOption,
  setRefetchNeeded,
  dripList,
} from "Redux/slice/optionSlice";
import {
  useAgentAndAgentManagerListQuery,
  useLeanderUserListQuery,
} from "Redux/user/user";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const OptionContext = () => {
  const dispatch = useDispatch(); // Initializing dispatch function
  const refetchNeeded = useSelector((s) => s?.option?.refetchNeeded);
  // Custom Redux hooks for fetching agent, lender, lead source, and drip data
  const {
    data: agent,
    isSuccess: agentSuccess,
    refetch: agentRefetch,
  } = useAgentAndAgentManagerListQuery();
  const {
    data: lender,
    isSuccess: lenderSuccess,
    refetch: lenderRefetch,
  } = useLeanderUserListQuery();
  const {
    data: souce,
    isSuccess: sourceSuccess,
    refetch: sourceRefetch,
  } = useLeadSourceQuery();
  const {
    data: drip,
    isSuccess: dripSuccess,
    refetch: dripRefetch,
  } = useGetDripsListQuery({
    page: -1,
    search: "",
  });

  // Effect to dispatch agent options when agent data is successfully fetched
  useEffect(() => {
    if (
      isValidArray(agent?.data?.AgentAndAgentManager?.response) &&
      agentSuccess
    ) {
      dispatch(
        agentOption(
          createSliceOptions(
            agent?.data?.AgentAndAgentManager?.response,
            "name",
            "_id"
          )
        )
      );
    }
  }, [agent]); // Dependency array to trigger effect when agent data changes

  // Effect to dispatch lender options when lender data is successfully fetched
  useEffect(() => {
    if (isValidArray(lender?.data?.lender?.response) && lenderSuccess) {
      dispatch(
        lenderOption(
          createSliceOptions(lender?.data?.lender?.response, "name", "_id")
        )
      );
    }
  }, [lender]); // Dependency array to trigger effect when lender data changes

  // Effect to dispatch lead source options when lead source data is successfully fetched
  useEffect(() => {
    if (isValidArray(souce?.data?.leadSource?.response) && sourceSuccess) {
      dispatch(
        sourceOption(
          createSliceOptions(souce?.data?.leadSource?.response, "title", "_id")
        )
      );
    }
  }, [souce]); // Dependency array to trigger effect when lead source data changes

  // Effect to dispatch drip options when drip data is successfully fetched
  useEffect(() => {
    if (dripSuccess && isValidArray(drip?.data?.drips?.drips)) {
      dispatch(
        dripOption(createSliceOptions(drip?.data?.drips?.drips, "title", "id"))
      );
      dispatch(dripList(drip?.data?.drips?.drips));
    }
  }, [drip]); // Dependency array to trigger effect when drip data changes

  // Effect to refetch data if refetchNeeded state indicates a need for refetching
  useEffect(() => {
    let needsReset = false;
    if (refetchNeeded?.agent) {
      agentRefetch(); // Refetch agent data
      needsReset = true; // Set flag to true
    }
    if (refetchNeeded?.lender) {
      lenderRefetch(); // Refetch lender data
      needsReset = true; // Set flag to true
    }
    if (refetchNeeded?.source) {
      sourceRefetch(); // Refetch lead source data
      needsReset = true; // Set flag to true
    }
    if (refetchNeeded?.drip) {
      dripRefetch(); // Refetch drip data
      needsReset = true; // Set flag to true
    }
    if (needsReset) {
      // Reset refetchNeeded state to false for all data types
      dispatch(
        setRefetchNeeded({
          agent: false,
          lender: false,
          source: false,
          drip: false,
        })
      );
    }
  }, [refetchNeeded]); // Dependency array to trigger effect when refetchNeeded state changes

  return <></>;
};

export default OptionContext;
