import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  ExclamationCircleIcon,
  ShieldExclamationIcon,
} from "@heroicons/react/24/solid";
import classNames from "classnames";
import SpinnerButton from "Components/Common/Fields/SpinnerButton";
import useEscapeClose from "Components/Common/Hooks/useEscapeClose";
import { capitalize } from "lodash";
import {
  getKeysWithFalseValues,
  getKeysWithTrueValues,
  isValidArray,
  isValidObject,
} from "Modules/util";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetNodesAndEdgesListQuery } from "Redux/Drip/drip";
export default function ModifyDripFlowModal(props) {
  const {
    isOpen,
    setIsOpen,
    dripId,
    scheduleDripData,
    setScheduleDripData = () => {},
    setIsAssignDripPreviewModal = () => {},
    previousDripFlowData = {},
  } = props;

  useEscapeClose(setIsOpen); // Custom hook to close a modal when the Escape key is pressed.
  const dripsList = useSelector((s) => s?.option?.drip);

  // State to track deselected tasks
  const [selectedNodes, setSelectedNodes] = useState({});
  const dripName = dripsList?.filter((drip) => drip?.value === dripId)?.[0]
    ?.label;
  // state for node
  const [nodes, setNodes] = useState([]);

  const {
    data: getNodesAndEdges,
    isLoading: isNodeAndedgesLoading,
    refetch,
  } = useGetNodesAndEdgesListQuery(
    { id: dripId },
    { skip: !dripId ? true : false }
  );

  useEffect(() => {
    if (!isNodeAndedgesLoading) {
      const nodesData =
        getNodesAndEdges?.data?.nodeAndEdgesDetails?.nodesData ?? [];
      let nodeInitialValue = {};
      // check previous drip reflow for reassign drip
      if (
        isValidObject(previousDripFlowData) &&
        isValidArray(previousDripFlowData?.scheduledNodes)
      ) {
        nodeInitialValue = createPreviousDripFlow(
          previousDripFlowData?.skipNodes,
          previousDripFlowData?.scheduledNodes
        );
      } else {
        nodeInitialValue = createNodeInitialValue(nodesData);
      }
      setSelectedNodes(nodeInitialValue);
      setNodes(nodesData);
    }
  }, [getNodesAndEdges]);

  // manage node changes
  useEffect(() => {
    if (isValidObject(selectedNodes)) {
      const scheduledNodes = getKeysWithTrueValues(selectedNodes);
      const skipNodes = getKeysWithFalseValues(selectedNodes);
      setScheduleDripData({ scheduledNodes, skipNodes });
    }
  }, [selectedNodes]);

  // creating initial data for node
  function createNodeInitialValue(data) {
    const result = {};
    data?.forEach((node) => {
      if (node?.id) {
        result[node?.id] = true;
      }
    });
    return result;
  }
  // creating previous floe data for node
  function createPreviousDripFlow(skipNodes, scheduledNodes) {
    const result = {};
    skipNodes?.forEach((item) => {
      result[item] = false;
    });

    scheduledNodes?.forEach((item) => {
      result[item] = true;
    });
    return result;
  }

  // handle for reset( select all node) button
  function resetToNodeInitialValue(data) {
    const result = {};
    Object.keys(data).forEach((key) => {
      result[key] = true; // Set each key's value to true
    });
    setSelectedNodes(result);
  }

  // Handle checkbox toggle
  const handleCheckboxChange = (nodeId, flag) => {
    setSelectedNodes((prev) => ({ ...prev, [nodeId]: !flag }));
  };

  // validating node selection
  function validateNodeSelection() {
    return !isValidArray(scheduleDripData?.scheduledNodes);
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[1111]" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-6xl border-b-2 py-2 transform overflow-visible rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-5 ">
                <div className="mt-1 border-b-2 py-2 sm:mt-0 flex justify-between items-center">
                  <div className="sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-800 flex justify-between items-center"
                    >
                      Set drip flow for {dripName ?? "Drip"}
                    </Dialog.Title>
                  </div>
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 "
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="flex mb-4 mt-4 h-[280px] max-h-[320px] overflow-x-auto pr-5 border-2 rounded-md py-3 px-7">
                    {/* Nodes */}
                    {nodes?.map((node, index) => {
                      return (
                        <div key={index} className="flex items-center ">
                          {/* Node Content */}
                          <div
                            className={classNames(
                              "w-[150px] h-[55px] rounded-md px-2 text-xs space-y-1.5 shadow-md shrink-0",
                              {
                                "bg-blue-300":
                                  node?.type === "task" &&
                                  selectedNodes?.[node?.id],
                                "bg-blue-200":
                                  node?.type === "task" &&
                                  !selectedNodes?.[node?.id],
                                "bg-gray-300":
                                  node?.type === "mail" &&
                                  selectedNodes?.[node?.id],
                                "bg-gray-200":
                                  node?.type === "mail" &&
                                  !selectedNodes?.[node?.id],
                                "bg-orange-300":
                                  node?.type === "message" &&
                                  selectedNodes?.[node?.id],
                                "bg-orange-200":
                                  node?.type === "message" &&
                                  !selectedNodes?.[node?.id],
                              }
                            )}
                          >
                            <div className="flex justify-between mt-2">
                              <div className="flex items-center">
                                <b className="mr-1">{capitalize(node?.type)}</b>{" "}
                                (ID: {node?.id})
                              </div>
                              <div className="flex items-center">
                                <input
                                  type="checkbox"
                                  name="checkbox"
                                  className="text-orange-500 focus:ring-orange-400"
                                  checked={selectedNodes?.[node?.id]}
                                  onChange={() =>
                                    handleCheckboxChange(
                                      node?.id,
                                      selectedNodes?.[node?.id]
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div>
                              <b>Sent After:</b> {node?.data?.time}{" "}
                              {node?.data?.type}
                            </div>
                          </div>

                          {/* Horizontal Line */}
                          {index < nodes?.length - 1 && (
                            <div className="w-[80px] border-t border-gray-700"></div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <div className="bg-blue-100 mb-1 border-l-4  border-blue-500 text-blue-700 p-2 rounded-md shadow-md m-1">
                    <div className="flex items-center">
                      <ExclamationCircleIcon className="h-5 w-5 text-blue-700 mr-1" />
                      <p className="font-bold text-xs">Note:</p>
                      <p className="ml-1 text-xs">
                        Unchecked node will be skipped.
                      </p>
                    </div>
                  </div>
                </div>
                {validateNodeSelection() ? (
                  <div className="bg-red-100 border-l-4  border-red-500 text-red-700 p-2 rounded-md shadow-md m-1 mt-2">
                    <div className="flex items-center">
                      <ShieldExclamationIcon className="h-5 w-5 text-red-700 mr-1" />
                      <p className="font-bold text-xs">Warning:</p>
                      <p className="ml-1 text-xs">
                        Please select at least one node.
                      </p>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="flex justify-end mt-5">
                  <SpinnerButton
                    className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                    title={"See Drip Campaign"}
                    action={() => window.open(`/drip-flow/${dripId}`, "_blank")}
                    type={"button"}
                    loading={false}
                    isDisable={false}
                    toolTip="See Drip Campaign"
                  />
                  <SpinnerButton
                    className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                    title={"Reset Selection"}
                    action={() => resetToNodeInitialValue(selectedNodes)}
                    type={"button"}
                    loading={false}
                    isDisable={false}
                    toolTip="Reset Selection"
                  />
                  <SpinnerButton
                    className="inline-flex w-full justify-center rounded-md btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                    title={"Save Drip Flow"}
                    action={() => setIsAssignDripPreviewModal(true)}
                    type={"submit"}
                    loading={false}
                    isDisable={validateNodeSelection()}
                    toolTip="Save Drip Flow"
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
