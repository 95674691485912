import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";

const PasswordField = ({
  formik,
  label = "",
  name = "",
  // type = "text",
  id = "",
  // onChange = () => {},
  placeholder = "",
  className = "",
  autoComplete = "current-password",
}) => {
  const [isPasswordShow, setPasswordShow] = useState(false);
  const togglePasswordShow = () => setPasswordShow(!isPasswordShow);
  return (
    <div>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="mt-2 relative select-none">
        <input
          id={id}
          name={name}
          type={isPasswordShow ? "text" : "password"}
          onChange={formik.handleChange}
          autoComplete={autoComplete}
          onBlur={(e) => {
            const values = e.target.value;
            const removeSpace = values.replace(/\s+/g, " ").trim();
            formik.handleChange(e);
            formik.setFieldValue(name, removeSpace);
            formik.handleBlur(e);
          }}
          value={formik.values[`${name}`]}
          placeholder={placeholder}
          className={`${className} block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#475361] sm:text-sm sm:leading-6`}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3 select-none">
          {isPasswordShow ? (
            <EyeIcon
              onClick={togglePasswordShow}
              className="text-gray-800 h-5 w-5 shrink-0 cursor-pointer"
            />
          ) : (
            <EyeSlashIcon
              onClick={togglePasswordShow}
              className="text-gray-800 h-5 w-5 shrink-0 cursor-pointer"
            />
          )}
        </div>
      </div>
      {formik.touched[name] && formik.errors[name] && (
        <div className="text-red-500 select-none text-sm">
          {formik.errors[name]}
        </div>
      )}
    </div>
  );
};

export default PasswordField;
