import React from "react";
import { ScaleLoader } from "react-spinners";

export default function LoadingButton(props) {
  const {
    label,
    title,
    action = () => {},
    isDisable = false,
    className = "",
    isLoading,
    type = "button",
  } = props;

  return (
    <button
      className={`${className} ${isLoading ? "!cursor-wait" : ""}`}
      disabled={isDisable}
      onClick={action}
      title={title}
      type={type}
    >
      {isLoading ? (
        <ScaleLoader
          className="flex items-center"
          color="#ffff"
          size={20}
          width={10.5}
          height={8}
        />
      ) : (
        label
      )}
    </button>
  );
}
