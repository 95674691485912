import { serverApi } from "Redux/serverApi";
import { gqlBody } from "./gqlBody";

// Create a settingsQuery object using serverApi.injectEndpoints
const settingsQuery = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    getConfiguration: builder.query({
      query: () => ({
        method: "POST",
        body: gqlBody.getConfiguration(),
      }),
    }),
    editConfiguration: builder.mutation({
      query: (payload) => ({
        method: "POST",
        body: gqlBody.editConfiguration(payload),
      }),
    }),
  }),
  // Do not override existing endpoints
  overrideExisting: false,
});

export const { useGetConfigurationQuery, useEditConfigurationMutation } =
  settingsQuery;
