import { CalendarDaysIcon, UserIcon } from "@heroicons/react/24/solid";
import editIcon from "Assets/icons/editIcon.svg";
import trashIcon from "Assets/icons/trashIcon.svg";
import Button from "Components/Common/Buttons/Button";
import {
  isPermission,
  isValidArray,
  showErrorMsg,
  showSuccessMsg,
  showTaskDateHalfMonthFormat,
  showTaskDateTimeHalfMonthFormat,
} from "Modules/util";
import { useCompleteTaskMutation } from "Redux/Task/TaskQuery";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function TaskCard({
  task,
  key,
  refetchLead,
  setDeleteTaskId,
  userPermissions,
  setEditTaskId,
  setaddTaskModal,
  setIsDeleteModal,
  setCompletedTaskList = () => {},
  completedTaskList = [],
  isModal = false,
  isCompleteTaskInProcess,
  isOpen,
  setIsCompleteTaskInProcess = () => {},
  latestCompletedTask = "",
  setLatestCompetedTask = () => {},
  agents = [],
}) {
  const [completeTask] = useCompleteTaskMutation(); //Complete Task Muation
  const [completeTaskDelay, setCompleteTaskDelay] = useState(false);
  const [lastCompletedTask, setLastCompletedTask] = useState(false);
  const [taskAgent, setTaskAgent] = useState(null);
  const [isCompleteTaskLoading, setIsCompleteTaskLoading] = useState(false);

  useEffect(() => {
    let taskAgent = agents?.find((agent) => agent?._id === task?.agent);
    setTaskAgent(taskAgent);
  }, [agents, task?.agent]);

  // Function to handle the Complete of a task
  async function handleCompleteTask(task, status) {
    setIsCompleteTaskLoading(true);
    setLatestCompetedTask(task?._id);
    // if (completedTaskList && setCompletedTaskList) {
    if (!status && !completedTaskList?.includes(task?._id)) {
      setCompletedTaskList((prev) => [...prev, task?._id]);
    } else {
      setCompletedTaskList((prev) =>
        prev?.filter((taskId) => taskId !== task?._id)
      );
      // }
    }

    try {
      // Call the completeTask mutation with the specified parameters
      let res = await completeTask({
        _id: task?._id,
        isCompleted: status ? false : true,
      });

      // Extract and handle the response from the mutation
      const responseData = res?.data?.data?.completeTask;
      if (responseData) {
        if (responseData?.type === "success") {
          showSuccessMsg(responseData?.message);
        } else if (responseData?.type === "error") {
          showErrorMsg(responseData?.message);
        }
      }
      if (status === true) {
        refetchLead();
      } else {
        if (isModal) {
          refetchLead();
        } else {
          setCompleteTaskDelay(!completeTaskDelay);
          setIsCompleteTaskInProcess(true);
        }
      }
      setCompleteTaskDelay(!completeTaskDelay);
      setDeleteTaskId(null);
    } catch (error) {
      showErrorMsg("An error occurred while Task.");
    }
    setIsCompleteTaskLoading(false);
  }

  const scroll = (taskId) => {
    setTimeout(() => {
      const section = document.getElementsByClassName(taskId);
      section[0]?.scrollIntoView({ behavior: "smooth", block: "center" });
    }, 500);
  };
  //managing 2 second dealy for task complete
  useEffect(() => {
    setTimeout(() => {
      if (completeTaskDelay) {
        refetchLead(); // Refetch tasks after completing and close the modal
        setCompleteTaskDelay(!completeTaskDelay);
        setIsCompleteTaskInProcess(false);
        scroll(latestCompletedTask);
      }
    }, 1000);
  }, [completeTaskDelay]);

  //set the last completed task in state ( show highlighted card )
  useEffect(() => {
    if (isValidArray(completedTaskList)) {
      setLastCompletedTask(completedTaskList[completedTaskList?.length - 1]);
    }
  }, [completedTaskList]);

  //reset lastcompleted task to null
  useEffect(() => {
    if (isOpen) {
      setLastCompletedTask(null);
    }
  }, [isOpen]);

  // handle drip info button visiblity
  function isAutoFolloUpVisible() {
    return (
      task?.hasOwnProperty("isLeadFollowUp") && task?.isLeadFollowUp !== null
    );
  }

  return (
    <>
      <div
        key={key + 1}
        className={`${
          task?.isCompleted || completeTaskDelay ? "bg-gray-300" : ""
        } ${task?._id} m-2 p-2 bg-gray-50 shadow-md rounded-lg space-y-1 text-xs
        ${
          latestCompletedTask === task?._id && "bg-gray-200 hover:!bg-gray-300"
        } 
        `}
      >
        <div
          className={`flex justify-between ${
            task?.isCompleted ? "line-through" : ""
          }`}
        >
          <span className="flex items-center space-x-1 w-[100%]">
            <CalendarDaysIcon className="!w-4 !h-4 flex-shrink-0 text-red-600" />
            <label className="">
              {" "}
              {task?.isTime
                ? showTaskDateTimeHalfMonthFormat(task?.date)
                : showTaskDateHalfMonthFormat(task?.date)}
            </label>
          </span>
          <span className="flex items-center w-[50%]">
            <label className="flex">
              {" "}
              <UserIcon className="!w-4 !h-4 flex-shrink-0 text-red-600" />
            </label>
            <label className="text-gray line-clamp-1">
              {taskAgent?.name ?? "-"}
            </label>
          </span>
          <div className="flex justify-end space-x-2 w-[33.33%]">
            <div>
              {isPermission(userPermissions, "edit_task") ? (
                <div className="bg-white rounded-md p-1">
                  <img
                    alt="edit-icon"
                    src={editIcon}
                    className={`h-4 w-4 rounded-md ${
                      task?.isCompleted
                        ? "opacity-50 hover:cursor-not-allowed"
                        : "hover:cursor-pointer"
                    }`}
                    style={{
                      filter:
                        "invert(9%) sepia(86%) saturate(7500%) hue-rotate(12deg) brightness(104%) contrast(104%)",
                    }}
                    onClick={() => {
                      if (!task?.isCompleted) {
                        setEditTaskId(task?._id);
                        setaddTaskModal(true);
                      }
                    }}
                    title={
                      task?.isCompleted ? "Reopen task to edit" : "Edit task"
                    }
                    isDisabled={task?.isCompleted}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div>
              {isPermission(userPermissions, "delete_task") ? (
                <div className="bg-white rounded-md p-1">
                  <img
                    alt="delete-icon"
                    src={trashIcon}
                    className="h-4 w-4 hover:cursor-pointer rounded-md"
                    onClick={() => {
                      setIsDeleteModal(true);
                      setDeleteTaskId(task?._id);
                    }}
                    title={"Delete Task"}
                    isDisabled={false}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <div
            className={`flex space-x-1 w-[80%] ${
              task?.isCompleted ? "line-through" : ""
            }`}
          >
            <label className="font-bold">Description:</label>
            <label className="text-justify">
              {task?.description ? task?.description : "N/A"}
              {task?.errorURL ? (
                <span className="text-xs">
                  <Link
                    className="text-red-500 hover:text-red-600 underline pl-1"
                    to={task?.errorURL}
                    target="_blank"
                  >
                    (ERROR: {task?.errorCode})
                  </Link>
                </span>
              ) : (
                <></>
              )}
            </label>
          </div>

          <div className="flex flex-col space-x-2 space-y-2">
            <div className="flex bg-lime-300">
              {isPermission(userPermissions, "complete_task") ? (
                <Button
                  label={
                    task?.isCompleted ||
                    (completeTaskDelay && lastCompletedTask === task?._id)
                      ? "Re-Open"
                      : "Complete"
                  }
                  title={
                    task?.isCompleted ||
                    (completeTaskDelay && lastCompletedTask === task?._id)
                      ? "Re-Open Task"
                      : "Complete Task"
                  }
                  isDisable={completeTaskDelay || isCompleteTaskLoading}
                  className={`py-1 px-3 bg-red-600 text-white shadow-sm shadow-gray-300; text-xs font-medium rounded w-full ${
                    task?.isCompleted ||
                    (completeTaskDelay && lastCompletedTask === task?._id)
                      ? "!bg-red-600 text-white hover:bg-red-100 hover:text-white !no-line-through"
                      : " bg-red-600 text-red-600"
                  }
                ${
                  completeTaskDelay &&
                  lastCompletedTask === task?._id &&
                  " !pointer-event-none hover:!cursor-not-allowed line-through"
                }`}
                  action={() =>
                    // !isCompleteTaskLoading &&
                    handleCompleteTask(task, task?.isCompleted)
                  }
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div>
          {task?.dripTitle ? (
            <p>
              <b>Suggested Drip:</b> {task?.dripTitle}
            </p>
          ) : (
            <></>
          )}
        </div>
        <div>
          {isAutoFolloUpVisible() ? (
            <div>
              <p>
                <b>Auto Follow-up :</b> {task?.isLeadFollowUp ? "On" : "Off"}
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
