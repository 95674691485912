import trashIcon from "Assets/icons/trashIcon.svg";
import ConfirmationModal from "Components/Common/Modals/ConfirmationModal";
import {
  isPermission,
  isValidArray,
  showErrorMsg,
  showSuccessMsg,
} from "Modules/util";
import { usePhoneStatusUpdateMutation } from "Redux/Leads/lead";
import { useEffect, useRef, useState } from "react";
import {
  SmsOptOutOptions,
  anyValueIsTrueInObject,
  phoneOptOutOptions,
} from "../assetData";
import { OptOutDropDown } from "./OptOutDropDown";
import { useSelector } from "react-redux";
import { formatPhoneNumber } from "Modules/util";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import EditPhoneEmailInLeadProfile from "Components/Common/Fields/EditPhoneEmailInLeadProfile";
import EditMultiInputField from "Components/Common/Fields/EditMultiInputField";
import FormatPhoneField from "Components/Common/Fields/FormatPhoneField";
export default function Phone(props) {
  const {
    phoneList,
    handleSubmit,
    isSubmit = true,
    name,
    leadId,
    addPhoneInput = true,
    scrollBarTheme = "orange",
    format,
    isModal,
    setEditFiled = () => {},
    editFiled = {},
    setIsOpenNestModal = () => {},
    setIsOpenDoubleNestModal = () => {},
  } = props;

  const [limit, setLimit] = useState(true);
  const [List, setList] = useState([]);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [isRemoveValueModalOpen, setRemoveValueModalOpen] = useState(false);
  const [payload, setPayload] = useState({});
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const userPermissions = useSelector((state) => state?.user?.permission); // Get user permissions from Redux store
  const [isWrongNumberConfirmationModal, setWrongNumberConfirmationModal] =
    useState(false);

  const [updatePhoneStatus] = usePhoneStatusUpdateMutation();

  function generatePhoneList(phoneList) {
    const phoneInfoList = phoneList?.map((obj) => {
      const callStatusObj = phoneOptOutOptions?.find(
        (opt) => opt?.value === obj?.callStatus
      );
      const smsStatusObj = SmsOptOutOptions?.find(
        (opt) => opt?.value === obj?.smsStatus
      );
      if (callStatusObj && smsStatusObj) {
        return {
          ...obj,
          callIcon: callStatusObj?.icon,
          callIconColor: callStatusObj?.iconColor,
          smsIcon: smsStatusObj?.icon,
          smsIconColor: smsStatusObj?.iconColor,
        };
      }
      return obj;
    });

    return phoneInfoList;
  }

  useEffect(() => {
    const phoneInfoList = generatePhoneList(phoneList);
    if (limit && phoneInfoList?.length > 3) {
      setList(phoneInfoList?.slice(0, 3));
    } else {
      setList(phoneInfoList);
    }
  }, [limit, phoneList]);

  useEffect(() => {
    setIsOpenNestModal((prev) => ({
      ...prev,
      changePhoneStatusModal: isConfirmationModalOpen,
      deletePhoneModal: isRemoveValueModalOpen,
    }));
  }, [isConfirmationModalOpen, isRemoveValueModalOpen]);

  useEffect(() => {
    if (isConfirmationModalOpen || isRemoveValueModalOpen) {
      setIsOpenDoubleNestModal(true);
    } else {
      setIsOpenDoubleNestModal(false);
    }
  }, [isConfirmationModalOpen, isRemoveValueModalOpen]);

  const removeValue = () => {
    const newList = [...phoneList];
    newList?.splice(deleteIndex, 1);
    const phoneInfoList = generatePhoneList(newList);
    setList(phoneInfoList);
    let phones = phoneInfoList?.map((phoneInfo) => phoneInfo?.phone);
    if (isSubmit) {
      handleSubmit({
        [name]: phones,
      });
    }
    setDeleteIndex(null);
  };

  function handleStatusChange(values) {
    let payload = {
      leadId: leadId,
      callStatus:
        values?.type === "callStatus" ? values?.value : values.callStatus,
      smsStatus:
        values?.type === "smsStatus" ? values?.value : values.smsStatus,
      contactId: values?.id,
    };

    updatePhoneStatus(payload)
      .then((response) => {
        if (response?.data?.data?.phoneStatusUpdate?.type === "success") {
          showSuccessMsg(response?.data?.data?.phoneStatusUpdate?.message);
        } else if (response?.data?.data?.phoneStatusUpdate?.type === "error") {
          showErrorMsg(response?.data?.data?.phoneStatusUpdate?.message);
        }
      })
      .catch((error) =>
        showErrorMsg("Error occurred while updating email status.")
      );
    setConfirmationModalOpen(false);
  }

  function handleConfirmationModal(values) {
    if (values?.value === "Cannot Be Texted") {
      setConfirmationModalOpen(true);
    } else if (values?.value === "Wrong Number") {
      setWrongNumberConfirmationModal(true);
    } else if (
      values?.value === "Can Be Texted" &&
      values?.callStatus === "Wrong Number"
    ) {
      showErrorMsg("SMS status cannot be updated");
    } else {
      handleStatusChange(values);
    }
  }

  const inputRef = useRef(null);

  const [editingPhoneId, setEditingPhoneId] = useState(null); // Track the phone being edited
  const [inputValue, setInputValue] = useState(""); // Track the input value
  const [currentPhone, setCurrentPhone] = useState(""); // state for current phone value for matching with phoneNumber in phoneList for edit

  // Handle double-click to enter edit mode
  const handleDoubleClick = (index, phone) => {
    setEditingPhoneId(index);
    setInputValue(phone);
    setCurrentPhone(phone);
  };
  // Handle input change
  const handleChange = (e) => {
    setInputValue(e?.value);
  };
  // Save the changes
  const handleSave = () => {
    let updatedPhone = [];
    let trimmedValue = inputValue.trim();
    if (name === "phone") {
      trimmedValue = trimmedValue?.replace(/\+\d+(?=.*\()|\s|[()+-]/g, "");
    }
    const isSamePhoneNumber = phoneList
      ?.map((p) => p?.phone)
      ?.includes(trimmedValue);
    if (isSamePhoneNumber) {
      setEditingPhoneId(null);
      setInputValue("");
      return;
    }
    isValidFormat(trimmedValue) &&
      (updatedPhone = phoneList?.map((phone, inx) =>
        phone?.phone === currentPhone ? trimmedValue : phone?.phone
      ));

    if (isSubmit && updatedPhone?.length > 0) {
      handleSubmit({
        [name]: updatedPhone,
      });
      setEditingPhoneId(null);
      setInputValue("");
    } else {
      if (!trimmedValue) {
        setEditingPhoneId(null);
        setInputValue("");
      } else {
        showErrorMsg(
          `Please enter a valid ${name === "phone" ? "phone number" : name}`
        );
      }
    }
  };

  // Function to check if the input value is valid
  function isValidFormat(value) {
    return format?.test(value);
  }

  // Cancel phone editing
  const handleCancel = () => {
    setInputValue(inputValue || "");
    setEditingPhoneId(null);
  };
  // Handle click outside to close input box
  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      handleSave();
    }
  };
  // Handle Enter key press to save
  const handleKeyDown = (e) => {
    if (e?.key === "Enter" || e?.key === "NumpadEnter") {
      handleSave();
    }
  };
  useEffect(() => {
    // Add event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputValue]);

  useEffect(() => {
    setEditFiled((prev) => ({
      ...prev,
      editPhone: editingPhoneId === 0 ? true : !!editingPhoneId,
    }));
  }, [editingPhoneId]);

  return (
    <div
      className={`w-full ${!isValidArray(List) && " flex items-center"}
      ${!isModal && "max-w-[260px]"}
      flex-wrap`}
    >
      <ConfirmationModal
        isModalOpen={isRemoveValueModalOpen}
        setModalOpen={setRemoveValueModalOpen}
        handleAction={() => removeValue()}
        isLoading={false}
      />
      <ConfirmationModal
        isModalOpen={isConfirmationModalOpen}
        setModalOpen={setConfirmationModalOpen}
        message='
        By changing text status to "Cannot Be Texted" you will no longer be able to send text messages to this number.'
        handleAction={() => handleStatusChange(payload)}
        isLoading={false}
      />
      <ConfirmationModal
        isModalOpen={isWrongNumberConfirmationModal}
        setModalOpen={setWrongNumberConfirmationModal}
        message='By changing call status to "Wrong Number" you will be permanently locked out of texting this number.'
        handleAction={() => handleStatusChange(payload)}
        isLoading={false}
      />
      {isModal ? (
        <EditMultiInputField {...props} />
      ) : (
        <EditPhoneEmailInLeadProfile {...props} setLimit={setLimit} />
      )}
      <div
        className={`w-[250px] rounded-md placeholder:pl-2 placeholder:text-gray-400 text-xs  ${
          !limit && "max-h-[100px] py-2 overflow-y-auto overflow-x-hidden"
        }
         ${
           (editingPhoneId === 0 ? true : !!editingPhoneId)
             ? "max-w-[260px] w-[240px] px-2"
             : "max-w-[220px] w-[215px]"
         }
         ${!isModal && "px-3"}
         ${scrollBarTheme === "blue" && "scrollbar-blue"}
        `}
      >
        {isValidArray(List) ? (
          List?.map((phone, index) => (
            <div
              className="w-full flex items-center space-x-2 mt-3"
              key={index}
            >
              <OptOutDropDown
                Icon={phone?.callIcon}
                iconColor={phone?.callIconColor}
                Options={phoneOptOutOptions}
                setPayload={setPayload}
                action={handleConfirmationModal}
                id={phone?._id}
                extraProps={{
                  smsStatus: phone?.smsStatus,
                  type: "callStatus",
                }}
                status={phone?.callStatus}
                disabled={!isPermission(userPermissions, "edit_leads")}
              />
              <OptOutDropDown
                Icon={phone?.smsIcon}
                iconColor={phone?.smsIconColor}
                Options={SmsOptOutOptions}
                setPayload={setPayload}
                action={handleConfirmationModal}
                id={phone?._id}
                extraProps={{
                  callStatus: phone?.callStatus,
                  type: "smsStatus",
                }}
                status={phone?.smsStatus}
                disableStatus={
                  isValidArray(phone?.fromOptedOutNumber)
                    ? "Cannot Be Texted"
                    : ""
                }
                disabled={!isPermission(userPermissions, "edit_leads")}
              />
              {/* phone with onDoubleClick enable editing */}
              {/* {isPermission(userPermissions, "edit_leads") && ( */}
              <>
                {editingPhoneId === index ? (
                  <div className="relative" ref={inputRef}>
                    <FormatPhoneField
                      name="phone"
                      value={inputValue}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      className={`h-6 w-full px-0.5 pr-12 outline-none rounded border border-gray-300 shadow-sm py-2 bg-white ring-gray-300 focus:border-gray-800 focus:ring-gray-500 placeholder:pl-2 placeholder:text-gray-400 sm:text-sm sm:leading-6 ${
                        editingPhoneId === index ? "bg-white" : "bg-gray-100"
                      } 
                      `}
                      autoComplete="off"
                    />
                    {editingPhoneId === index && (
                      <>
                        <button
                          onClick={() => {
                            handleCancel();
                          }}
                          className="absolute right-[26px] w-[18px] h-[18px] top-3 transform -translate-y-1/2 bg-red-100 rounded-md border border-red-400 text-red-700 hover:bg-red-200 hover:text-red-900"
                        >
                          <XMarkIcon className="w-[16px] h-[16px] font-semibold" />
                        </button>
                        <button
                          onClick={() => handleSave()}
                          className="absolute right-1 w-[18px] h-[18px] top-3 transform -translate-y-1/2 bg-green-100 rounded-md border border-green-400 text-green-700 hover:bg-green-200 hover:text-green-900"
                        >
                          <CheckIcon className="w-[15px] h-[15px] font-semibold" />
                        </button>
                      </>
                    )}
                  </div>
                ) : (
                  <span
                    title={phone?.phone}
                    className="!line-clamp-1 cursor-pointer"
                    onDoubleClick={() =>
                      anyValueIsTrueInObject(editFiled) &&
                      handleDoubleClick(index, phone?.phone)
                    }
                  >
                    {formatPhoneNumber(phone?.phone)}
                  </span>
                )}
              </>
              {/* )} */}
              {editingPhoneId !== index && (
                <>
                  {isPermission(userPermissions, "edit_leads") && (
                    <div className="bg-white rounded-md cursor-pointer">
                      <img
                        alt="edit-icon"
                        src={trashIcon}
                        className="w-4 h-4"
                        onClick={() => {
                          setDeleteIndex(index);
                          setRemoveValueModalOpen(true);
                        }}
                        title={"Remove phone"}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          ))
        ) : (
          <div className="flex justify-center w-full mt-3">
            Lead doesn't have any number
          </div>
        )}
      </div>
      <div className="px-3 mt-1">
        {phoneList?.length > 3 && (
          <span
            className="text-blue-500 text-xs hover:cursor-pointer hover:underline "
            onClick={() => setLimit(!limit)}
          >
            {limit ? "Show More" : "Show Less"}
          </span>
        )}
      </div>
    </div>
  );
}
