import Loading from "Components/Common/Loading";
import { isPermission, isValidArray } from "Modules/util";
import { lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import InvitedUser from "./Components/Front/InvitedUser";
import PendingMessages from "Components/PendingMessages/PendingMessages";
import LoadingModal from "Components/Common/LoadingModal";
const Dashboard = lazy(() => import("Components/Dashboard/Dashboard"));
const DripFlow = lazy(() => import("Components/Drips/DripFlow"));
const AIDripForm = lazy(() => import("Components/Drips/AIDripForm/AIDripForm"));
const Drips = lazy(() => import("Components/Drips/Drips"));
const ScheduledLeadDeatils = lazy(() =>
  import("Components/Drips/ScheduledLeadDeatils")
);
const EmailTemplateList = lazy(() =>
  import("Components/EmailTemplate/EmailTemplateList")
);
const ForgotPassword = lazy(() => import("Components/Front/ForgotPassword"));
const FrontTheme = lazy(() => import("Components/Front/FrontTheme"));
const Login = lazy(() => import("Components/Front/Login"));
const AuthLayout = lazy(() => import("Components/Layoutes/AuthLayout"));
const NoAuthLayout = lazy(() => import("Components/Layoutes/NoAuthLayout"));
const LeadDetails = lazy(() =>
  import("Components/Leads/LeadDetails/LeadDetails")
);
const LeadList = lazy(() => import("Components/Leads/LeadList"));
const RoleList = lazy(() => import("Components/Role/RoleList"));
const SMSTemplateList = lazy(() =>
  import("Components/SMSTemplate/SMSTemplateList")
);
const Settings = lazy(() => import("Components/Settings/Settings"));
const TaskList = lazy(() => import("Components/Tasks/TaskList"));
const UserList = lazy(() => import("Components/Users/UserList"));
const NoMatch = lazy(() => import("Components/Common/NoMatch"));
const Notification = lazy(() => import("Components/Notification/Notification"));

const RoutesFile = () => {
  const userPermissions = useSelector((state) => state?.user?.permission); // Get user permissions from Redux store
  const [permissionsLoaded, setPermissionsLoaded] = useState(false); // State to track if user permissions are loaded
  const isLoading = useSelector((state) => state?.theme?.isLoading);

  // useEffect to check if userPermissions are available
  useEffect(() => {
    if (userPermissions) {
      setPermissionsLoaded(true);
    }
  }, [userPermissions]);

  // If user permissions are not loaded, render a loading state
  if (!permissionsLoaded) {
    return <Loading />;
  }

  return (
    <>
      {isLoading ? <LoadingModal /> : <></>}
      <Routes>
        {/* Public routes */}
        <Route element={<NoAuthLayout />}>
          <Route
            path="/"
            element={
              <FrontTheme>
                <Login />
              </FrontTheme>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <FrontTheme>
                <ForgotPassword />
              </FrontTheme>
            }
          />
        </Route>
        <Route
          path="/account-setup"
          element={
            <FrontTheme>
              <InvitedUser />
            </FrontTheme>
          }
        />
        {/* Authenticated routes based on user permissions */}
        <Route element={<AuthLayout />}>
          {<Route path="/dashboard" element={<Dashboard />} />}
          {isValidArray(userPermissions) ? (
            <>
              <Route path="/notification" element={<Notification />} />
              {/* Conditional rendering based on user permissions */}
              {isPermission(userPermissions, "list_leads") ? (
                <Route path="/leads" element={<LeadList />} />
              ) : (
                <></>
              )}
              {isPermission(userPermissions, "detail_leads") ? (
                <Route
                  path="/leads/detail/:id"
                  element={<LeadDetails isModal={false} />}
                />
              ) : (
                <></>
              )}
              {isPermission(userPermissions, "list_task") ? (
                <Route path="/tasks" element={<TaskList />} />
              ) : (
                <></>
              )}
              {isPermission(userPermissions, "list_users") ? (
                <Route path="/users" element={<UserList />} />
              ) : (
                <></>
              )}
              <></>
              {isPermission(userPermissions, "list_drip") ? (
                <Route path="/drips" element={<Drips />} />
              ) : (
                <></>
              )}
              <Route path="/drip-flow/:id" element={<DripFlow />} />
              <Route path="/ai-drip-flow" element={<AIDripForm />} />
              <Route
                path="/drip-flow/:id/:node/:type"
                element={<ScheduledLeadDeatils />}
              />
              {isPermission(userPermissions, "list_emailTemplate") ? (
                <Route path="/email-template" element={<EmailTemplateList />} />
              ) : (
                <></>
              )}
              {isPermission(userPermissions, "list_smsTemplate") ? (
                <Route path="/sms-template" element={<SMSTemplateList />} />
              ) : (
                <></>
              )}
              {isPermission(userPermissions, "manage_configurations") ? (
                <Route path="/settings" element={<Settings />} />
              ) : (
                <></>
              )}
              {isPermission(userPermissions, "list_roles") ? (
                <Route path="/role" element={<RoleList />} />
              ) : (
                <></>
              )}
              {isPermission(userPermissions, "pending_message") ? (
                <Route path="/pending-messages" element={<PendingMessages />} />
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </Route>
        {/* Fallback route for unmatched paths */}
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </>
  );
};

export default RoutesFile;
