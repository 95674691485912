import { CheckIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { useEffect, useState } from "react";
import Select from "react-select";
import { components } from "react-select";

export default function CustomSelectField({
  initialvalue,
  options,
  name,
  id,
  isOpen,
  optionClassname,
  setNewSelectedOption,
  isDisabled = false,
  classNames,
  placeholder = "Select...",
  onlyValue = false,
}) {
  const controlStyles = {
    base: "border rounded-lg bg-white hover:cursor-pointer w-full",
    focus: "border-gray-400 ring-1 ring-gray-500",
    nonFocus: "border-gray-300 hover:border-gray-400",
  };
  const placeholderStyles = "text-gray-500 pl-1 py-0.5 ";
  const selectInputStyles = "pl-1 py-0.5";
  const valueContainerStyles = "p-1 gap-1";
  const singleValueStyles = "leading-7 ml-1";
  const multiValueStyles =
    "bg-gray-100 rounded items-center py-0.5 pl-2 pr-1 gap-1.5";
  const multiValueLabelStyles = "leading-6 py-0.5";
  const multiValueRemoveStyles =
    "border border-gray-200 bg-white hover:bg-red-50 hover:text-red-800 text-gray-500 hover:border-red-300 rounded-md";
  const indicatorsContainerStyles = "p-1 gap-1";
  const clearIndicatorStyles =
    "text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800";
  const indicatorSeparatorStyles = "bg-gray-300";
  const dropdownIndicatorStyles =
    "p-1 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black";
  const menuStyles = "p-1 mt-2 border border-gray-200 bg-white rounded-lg";
  const groupHeadingStyles = "ml-3 mt-2 mb-1 text-gray-500 text-sm";
  const optionStyles = {
    base: "hover:cursor-pointer px-3 py-2 rounded",
    focus: "bg-orange-100 active:bg-orange-400",
    selected: "after:text-orange-400 text-gray-500",
  };
  const noOptionsMessageStyles =
    "text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm";

  const [selectedOption, setSelectedOption] = useState(initialvalue);

  useEffect(() => {
    setSelectedOption(initialvalue);
  }, [initialvalue]);

  function handleChange(e) {
    setSelectedOption(e);
    if (setNewSelectedOption) {
      if (selectedOption === e.value) {
        return null;
      }
      if (name === "leadPhoneNo" || onlyValue) {
        setNewSelectedOption(e.value);
      } else {
        setNewSelectedOption({ _id: id, [name]: e.value });
      }
    }
  }
  const { Option } = components;
  const IconOption = (props) => {
    return (
      <Option
        {...props}
        className={`${optionClassname} !overflow-hidden `}
      >
        <div className="flex items-center gap-1 !text-sm ">
          {props?.data?.label}
          {props?.isSelected && <CheckIcon className="w-4 h-4 text-black !shrink-0" />}
        </div>
      </Option>
    );
  };

  return (
    <div className="w-full px-2" onClick={(e) => e.stopPropagation()}>
      <Select
        menuPortalTarget={isOpen ? "" : document.body}
        name={name}
        value={options?.find(function (option) {
          return option?.value === selectedOption;
        })}
        onChange={(e) => {
          handleChange(e);
        }}
        unstyled
        className={` ${classNames} select-none`}
        options={options}
        isSearchable={false}
        placeholder={placeholder}
        components={{ Option: IconOption }}
        classNames={{
          control: ({ isFocused }) =>
            clsx(
              isFocused ? controlStyles.focus : controlStyles.nonFocus,
              controlStyles.base
            ),
          // controlStyles.focus : isFocused == true, controlStyles.base, isFocused == false : controlStyles.base
          placeholder: () => placeholderStyles,
          input: () => selectInputStyles,
          valueContainer: () => valueContainerStyles,
          singleValue: () => singleValueStyles,
          multiValue: () => multiValueStyles,
          multiValueLabel: () => multiValueLabelStyles,
          multiValueRemove: () => multiValueRemoveStyles,
          indicatorsContainer: () => indicatorsContainerStyles,
          clearIndicator: () => clearIndicatorStyles,
          indicatorSeparator: () => indicatorSeparatorStyles,
          dropdownIndicator: () => dropdownIndicatorStyles,
          menu: () => menuStyles,
          groupHeading: () => groupHeadingStyles,
          option: ({ isFocused, isSelected }) =>
            clsx(
              isFocused && optionStyles.focus,
              isSelected && optionStyles.selected,
              optionStyles.base
            ),
          noOptionsMessage: () => noOptionsMessageStyles,
        }}
        isDisabled={isDisabled}
      />
    </div>
  );
}
