export default function Select({
  className,
  options,
  onChange,
  value,
  selectOption = true,
  onBlur = () => {},
}) {
  return (
    <>
      <select
        className={`${className} h-[38px] flex text-sm items-center bg-white ring-0 outline-none border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500`}
        value={value ?? ""}
        onChange={onChange}
        onBlur={onBlur}
      >
        {selectOption && (
          <option disabled={true} value="" className="text- bg-gray-300">
            Select an option
          </option>
        )}
        {options?.map((option) => (
          <option value={option?.value} key={option?.value}>
            {option?.label}
          </option>
        ))}
      </select>
    </>
  );
}
