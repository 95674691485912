export const gqlBody = {
  deleteHistory(payload) {
    return {
      query: `mutation DeleteHistory($input: DeleteHistoryInput) {
        deleteHistory(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  getHistoryByLeadId(payload) {
    return {
      query: `query GetHistoryByLeadId($input: inputSingleLeadHistory) {
        getHistoryByLeadId(input: $input) {
          type
          message
          code
          historiesResponse {
            _id
            changes
            date
            note
            module
            todoBy
          }
        }
      }`,
      variables: { input: payload },
    };
  },
};
