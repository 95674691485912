import { CheckIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { useEffect, useRef } from "react";
import Select, { components } from "react-select";

export default function SelectField({
  initialvalue,
  options,
  placeholder,
  label,
  setFieldValue,
  onBlur,
  formik,
  isSearchable,
  isSubmit,
  handleSubmit,
  labelClassname,
  className,
  name,
  isDisabled = false,
  isClearable = false,
  optedOutNumber = "",
  optionClassname,
  isLabelValidation = false,
  autoFocus = false, // Add a prop for auto-focus
}) {
  // Define the isDisabled variable appropriately in your context
  // const isDisabled = false; // Or pass this as a prop or state
  // Create a reference for the select input
  const selectRef = useRef(null);

  const controlStyles = {
    base: `border rounded-lg bg-white ${
      isDisabled ? "opacity-75 cursor-not-allowed" : "hover:cursor-pointer"
    }`,
    focus: "border-gray-400 ring-1 ring-gray-500",
    nonFocus: "border-gray-300 hover:border-gray-400",
  };

  const placeholderStyles = "text-gray-500 pl-1 py-0.5";
  const selectInputStyles = "pl-1 py-0.5";
  const valueContainerStyles = ` p-1 gap-1 ${
    isDisabled ? " !cursor-not-allowed" : ""
  }`;
  const singleValueStyles = `leading-7 ml-1 ${
    isDisabled ? " !cursor-not-allowed" : ""
  }`;
  const multiValueStyles =
    "bg-gray-100 rounded items-center py-0.5 pl-2 pr-1 gap-1.5";
  const multiValueLabelStyles = "leading-6 py-0.5";
  const multiValueRemoveStyles =
    "border border-gray-200 bg-white hover:bg-red-50 hover:text-red-800 text-gray-500 hover:border-red-300 rounded-md";
  const indicatorsContainerStyles = "p-1 gap-1";
  const clearIndicatorStyles =
    "text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800";
  const indicatorSeparatorStyles = "bg-gray-300";
  const dropdownIndicatorStyles =
    "p-1 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black";
  const menuStyles = "p-1 mt-2 border border-gray-200 bg-white rounded-lg";
  const groupHeadingStyles = "ml-3 mt-2 mb-1 text-gray-500 text-sm";
  const optionStyles = {
    base: `px-3 py-2 rounded ${
      isDisabled ? "opacity-75 !cursor-not-allowed" : "hover:cursor-pointer"
    }`,
    focus: "bg-orange-100 active:bg-orange-400",
    selected: "after:text-orange-400 text-gray-500",
  };
  const noOptionsMessageStyles =
    "text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm";

  const formatOptionLabel = ({ value, label }) => {
    return (
      <div>
        <span className={value === optedOutNumber ? "text-red-500" : ""}>
          {label}
        </span>
      </div>
    );
  };

  // Trigger focus on the select input when the component mounts if autoFocus is true
  useEffect(() => {
    if (autoFocus && selectRef?.current) {
      selectRef?.current?.focus();
    }
  }, [autoFocus]);

  const { Option } = components;
  const IconOption = (props) => {
    return (
      <Option
        {...props}
        className={`hover:cursor-pointer bg-white hover:bg-orange-100 active:bg-orange-400 ${optionClassname} text-justify`}
      >
        <div className="flex items-center gap-2">
          {props?.data?.label}
          {props?.isSelected && (
            <CheckIcon className="w-4 h-4 text-black !shrink-0" />
          )}
        </div>
      </Option>
    );
  };

  return (
    <div className={`mb-2 ${isDisabled ? "cursor-not-allowed" : ""}`}>
      <label
        className={` flex w-full justify-between text-sm font-medium leading-6 text-gray-900 ${labelClassname}`}
      >
        {label}{" "}
        {isLabelValidation &&
          formik?.touched?.[name] &&
          formik.errors?.[name] && (
            <div className="text-xs pt-[5px] pr-[15px] text-red-500">
              {formik?.errors?.[name]}
            </div>
          )}
      </label>
      <Select
        name={name}
        value={options?.filter(function (option) {
          return (
            option?.value === formik.values[name] ??
            option?.value === initialvalue
          );
        })}
        formatOptionLabel={formatOptionLabel}
        onChange={(e) => {
          setFieldValue(name, e?.value || null);
          if (isSubmit) {
            let key = name;
            let editFiledData = {
              [key]: e?.value || null,
            };
            handleSubmit(editFiledData);
          }
        }}
        onClick={(e) => e.stopPropagation()}
        unstyled
        placeholder={placeholder}
        className={`w-full mt-2 text-sm ${className} `}
        options={options}
        onBlur={(e) => {
          formik.setFieldTouched(name, true);
          if (onBlur) onBlur(e);
        }}
        isSearchable={isSearchable}
        isClearable={isClearable}
        components={{ Option: IconOption }}
        classNames={{
          control: ({ isFocused }) =>
            clsx(
              isFocused ? controlStyles.focus : controlStyles.nonFocus,
              controlStyles.base
            ),
          // controlStyles.focus : isFocused == true, controlStyles.base, isFocused == false : controlStyles.base
          placeholder: () => placeholderStyles,
          input: () => selectInputStyles,
          valueContainer: () => valueContainerStyles,
          singleValue: () => singleValueStyles,
          multiValue: () => multiValueStyles,
          multiValueLabel: () => multiValueLabelStyles,
          multiValueRemove: () => multiValueRemoveStyles,
          indicatorsContainer: () => indicatorsContainerStyles,
          clearIndicator: () => clearIndicatorStyles,
          indicatorSeparator: () => indicatorSeparatorStyles,
          dropdownIndicator: () => dropdownIndicatorStyles,
          menu: () => menuStyles,
          groupHeading: () => groupHeadingStyles,
          option: ({ isFocused, isSelected }) =>
            clsx(
              isFocused && optionStyles.focus,
              isSelected && optionStyles.selected,
              optionStyles.base
            ),
          noOptionsMessage: () => noOptionsMessageStyles,
        }}
        isDisabled={isDisabled}
        ref={selectRef} // Attach the ref to the Select component
      />
      {!isLabelValidation && formik.touched?.[name] && formik.errors[name] ? (
        <div className="text-red-500 text-sm">{formik.errors[name]}</div>
      ) : (
        <></>
      )}
    </div>
  );
}
