export const gqlBody = {
  getDripByLeadId(payload) {
    return {
      query: `query GetDripByLeadId($input: inputSingleLead) {
        getDripByLeadId(input: $input) {
          type
          message
          code
          singleDripResponse {
            dripsResponse {
              _id
              leadId
              dripId
              dripTitle
              dripStatus
              isPlay
              leadDripStatus
              isTerminated
              dripSortOrder
              skipNodes
              scheduledNodes
            }
            dripStepResponse {
              nodeId
              templateType
              scheduleTime
              status
              updatedAt
              dripId
              time
              type
              message
              isOpenAI
              skipNodes
              scheduledNodes
            }
          }
        }
      }`,
      variables: { input: payload },
    };
  },
  RescheduleDripLead(payload) {
    return {
      query: `mutation RescheduleDripLead($input: RescheduleDripLeadInput!) {
        rescheduleDripLead(input: $input) {
          type
          message
          code
        }
      }`,
      variables: { input: payload },
    };
  },
  DripScheduleTime(payload) {
    return {
      query: `query DripScheduleTime($input: DripScheduleTimeInput) {
        dripScheduleTime(input: $input) {
          scheduleTime
          time
          type
          nodeId
          nodeType
        }
      }`,
      variables: { input: payload },
    };
  },
};
