import { CheckIcon } from "@heroicons/react/24/solid";
import { getOptionData, isValidArray } from "Modules/util";
import clsx from "clsx";
import { useEffect, useState } from "react";
import Select from "react-select";
import { components } from "react-select";

export default function MultiSelectField({
  id,
  options,
  initialvalue,
  formik,
  name,
  isSubmit,
  setNewSelectedOption = () => {},
  setId,
  isOpen,
  defaultSelect,
  handleSubmit,
  isDisabled = false,
  classNames = "",
  isSearchable = false,
  isValueNull = false,
  isSmall = false,
  placeholder = "Select...",
}) {
  const controlStyles = {
    base: `border relative rounded-lg bg-white overflow-y-auto py-0 scroll-smooth ${
      isSmall ? "max-h-[28px]" : "max-h-[62px]"
    } ${isDisabled ? "opacity-75 cursor-not-allowed" : "cursor-pointer"}`,
    focus: "border-gray-400 ring-1 ring-gray-500",
    nonFocus: "border-gray-300 hover:border-gray-400",
  };
  const placeholderStyles = "text-gray-500 pl-1 py-0.5";
  const selectInputStyles = "pl-1 py-0.5";
  const valueContainerStyles = "p-1 gap-1";
  const singleValueStyles = "leading-7 ml-1";
  const multiValueStyles =
    "bg-gray-100 rounded items-center pl-2 pr-1 gap-1.5 bg-orange-300 h-6";
  const multiValueLabelStyles = "leading-6 py-0.5 ";
  const multiValueRemoveStyles =
    "border border-gray-200 bg-white hover:bg-red-50 hover:text-red-800 text-gray-500 hover:border-red-300 rounded-md";
  const indicatorsContainerStyles = "px-2 gap-1";
  const clearIndicatorStyles = `multi-select-sticky ${
    isSmall ? "top-[4px] bottom-[4px]" : "top-[15px] bottom-[17px]"
  } text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800`;
  const indicatorSeparatorStyles = "bg-gray-300";
  const dropdownIndicatorStyles = `multi-select-sticky ${
    isSmall ? "top-[4px] bottom-[4px]" : "top-[15px] bottom-[17px]"
  } p-1 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black`;
  const menuStyles = "p-1 mt-2 border border-gray-200 bg-white rounded-lg";
  const groupHeadingStyles = "ml-3 mt-2 mb-1 text-gray-500 text-sm";
  const optionStyles = {
    base: "hover:cursor-pointer px-3 py-2 rounded",
    focus: "bg-orange-100 active:bg-orange-400",
    selected: "after:text-orange-400 text-gray-500",
  };
  const noOptionsMessageStyles =
    "text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm";

  const [selectedOption, setSelectedOption] = useState(
    getOptionData(initialvalue)
  );

  //Set Initial Values
  useEffect(() => {
    if (isValidArray(defaultSelect)) {
      setSelectedOption([defaultSelect[0]]);
      formik?.setFieldValue(name, [defaultSelect[0]?.value]);
    }
  }, [defaultSelect]);

  // handle multiSelect change
  function handleChange(data) {
    if (isSearchable && !isValidArray(data) && !isValidArray(selectedOption)) {
      return;
    }
    let val = isValidArray(data) ? data?.map((option) => option?.value) : [];
    setSelectedOption(isValidArray(data) ? data : []);
    if (setNewSelectedOption && !formik) {
      let values = isValidArray(data)
        ? data?.map((options) => options?._id)
        : [];
      let allValues =
        isValidArray(values) && isValidArray(val) ? [...values, ...val] : [];
      setNewSelectedOption({
        _id: id,
        [name]: [...new Set(val)],
      });
    } else if (isSubmit) {
      handleSubmit({
        [name]: val,
      });
    } else {
      formik.setFieldValue(name, val);
    }
  }
  //Setting Id for send Mail & send Message
  useEffect(() => {
    if (setId) {
      let values = selectedOption?.map((options) => options?.id);
      setId(values);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (initialvalue) {
      setSelectedOption(getOptionData(initialvalue));
    }
  }, [initialvalue]);

  const { Option } = components;
  const IconOption = (props) => {
    return (
      <Option {...props}>
        <div className="flex items-center gap-2">
          {props?.data?.label}
          {props?.isSelected && <CheckIcon className="w-4 h-4 text-black !shrink-0" />}
        </div>
      </Option>
    );
  };

  useEffect(() => {
    if (!isValidArray(formik?.values?.[name]) && isValueNull) {
      setSelectedOption([]);
    }
  }, [formik?.values?.[name]]);

  return (
    <div className={`w-full ${isDisabled ? "cursor-not-allowed" : ""}`}>
      <Select
        menuPortalTarget={isOpen ? "" : document?.body}
        value={selectedOption}
        onChange={(e) => {
          handleChange(e);
        }}
        unstyled
        isMulti
        className={`min-w-[200px] text-sm ${classNames} select-none`}
        options={options}
        components={{ Option: IconOption }}
        isSearchable={isSearchable}
        classNames={{
          control: ({ isFocused }) =>
            clsx(
              isFocused ? controlStyles.focus : controlStyles.nonFocus,
              controlStyles.base
            ),
          // controlStyles.focus : isFocused == true, controlStyles.base, isFocused == false : controlStyles.base
          placeholder: () => placeholderStyles,
          input: () => selectInputStyles,
          valueContainer: () => valueContainerStyles,
          singleValue: () => singleValueStyles,
          multiValue: () => multiValueStyles,
          multiValueLabel: () => multiValueLabelStyles,
          multiValueRemove: () => multiValueRemoveStyles,
          indicatorsContainer: () => indicatorsContainerStyles,
          clearIndicator: () => clearIndicatorStyles,
          indicatorSeparator: () => indicatorSeparatorStyles,
          dropdownIndicator: () => dropdownIndicatorStyles,
          menu: () => menuStyles,
          groupHeading: () => groupHeadingStyles,
          option: ({ isFocused, isSelected }) =>
            clsx(
              isFocused && optionStyles.focus,
              isSelected && optionStyles.selected,
              optionStyles.base
            ),
          noOptionsMessage: () => noOptionsMessageStyles,
        }}
        placeholder={placeholder}
        isDisabled={isDisabled}
      />
    </div>
  );
}
