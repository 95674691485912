import crossIcon from "Assets/icons/crossIcon.svg";
import searchIcon from "Assets/icons/searchIcon.svg";
import {
  formatPhoneNumber,
  getFastLetter,
  getHighlightedText,
  isValidArray,
  isValidObject,
  leadTypes,
} from "Modules/util";
import { useGetAllLeadsQuery, useGetLeadQuery } from "Redux/Leads/lead";
import { useEffect, useRef, useState } from "react";
import Skeleton from "./Skeleton";
const SearchLeadSelect = (props) => {
  const {
    setFieldValue = () => {},
    name,
    editLeadId,
    isLeadClear,
    formik,
    selectLead = () => {},
    setSelectedLeadData = () => {},
    setLeadId = () => {},
    filterLeadIds = [],
  } = props;

  const [searchValue, setSearchValue] = useState(""); //State for Search value
  const [LeadList, setLeadList] = useState([]); // State for All Lead List
  const [leadData, setLeadData] = useState({});
  const [activeIndex, setActiveIndex] = useState(0); // State to keep track of the active dropdown item
  const [isLoading, setIsLoading] = useState(editLeadId ? true : false);
  const autoFocusRef = useRef(null);
  const { data: inititalLead, isSuccess } = useGetLeadQuery(
    {
      _id: editLeadId,
    },
    { skip: editLeadId ? false : true }
  );
  const {
    data: getAllLeads,
    isLoading: isLeadListLoading,
    isFetching: isLeadListFetching,
  } = useGetAllLeadsQuery(
    {
      search: searchValue,
      page: -1,
    },
    { skip: searchValue?.length > 0 ? false : true }
  );

  useEffect(() => {
    if (!isLeadListLoading) {
      setActiveIndex(0);
      if (isValidArray(filterLeadIds)) {
        setLeadList(
          isValidArray(getAllLeads?.data?.leadList?.response)
            ? getAllLeads?.data?.leadList?.response?.filter(
                (lead) => !filterLeadIds?.includes(lead?._id)
              )
            : []
        );
      } else {
        setLeadList(getAllLeads?.data?.leadList?.response || []);
      }
    }
    if (searchValue?.length === 0) {
      setLeadList([]);
    }
  }, [getAllLeads]);

  useEffect(() => {
    if (isSuccess) {
      let lead = inititalLead?.data?.lead?.response;
      if (isValidArray(lead)) {
        setSelectedLeadData(lead?.[0]);
        setIsLoading(false);
        setLeadData({
          ...(isValidArray(lead[0]?.leadResponse)
            ? lead[0]?.leadResponse[0]
            : {}),
          ...(isValidArray(lead[0]?.categoryResponse)
            ? { categories: lead[0]?.categoryResponse[0] }
            : {}),
        });
      }
    }
  }, [isSuccess, inititalLead]);

  // Function to handle keyboard navigation and selection
  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault(); // Prevent default scrolling behavior
      setActiveIndex((prevIndex) =>
        prevIndex < LeadList.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault(); // Prevent default scrolling behavior
      setActiveIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (e.key === "Enter" && activeIndex !== -1) {
      e?.preventDefault(); // Prevent default form submission
      handleSelectLead(LeadList[activeIndex]);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown); // Add event listener to window
    return () => {
      window.removeEventListener("keydown", handleKeyDown); // Clean up event listener on unmount
    };
  }, [activeIndex, LeadList]); // Add dependencies

  // Scroll into view for the active index
  useEffect(() => {
    const selectedElement = document.getElementById(`lead-item-${activeIndex}`);
    if (selectedElement) {
      selectedElement.scrollIntoView({
        behavior: "instant",
        block: "nearest",
      });
    }
  }, [activeIndex]);

  //Handle ClearSearch
  const clearSearch = () => {
    setSearchValue("");
  };

  //Handle Search
  const handleChange = (value) => {
    setSearchValue(value);
  };
  // Handle Navigate & Clear Search Values in State
  const handleSelectLead = (lead) => {
    if (lead?._id) {
      setSelectedLeadData(lead);
      selectLead(lead);
      setFieldValue(name, lead?._id);
      setLeadData(lead);
      setFieldValue("agent", lead?.agent?._id);
      setSearchValue("");
      setLeadId(lead?._id);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      autoFocusRef.current?.focus();
    }, 300);
  }, []);

  if (isLoading) {
    return <Skeleton counter={1} height={"70px"} />;
  }
  if (isValidObject(leadData)) {
    return (
      <div className="w-full flex justify-between items-center text-sm bg-white border-y p-4 mb-4">
        <div>
          <div className="flex flex-row">
            <div className="flex items-center justify-center w-[45px] h-[45px] rounded-full bg-slate-400 border mr-3">
              <div className="text-white text-lg font-semibold h-min">
                {getFastLetter(leadData?.firstName, leadData?.lastName)}
              </div>
            </div>
            <div>
              <h6 className="text-wrap max-w-40">
                {leadData?.firstName + " " + leadData?.lastName}
              </h6>
              {isValidArray(leadData?.phone) &&
                leadData?.phone?.slice(0, 3)?.map((phone, index) => (
                  <span className="w-[100px] text-gray-500" key={index}>
                    {formatPhoneNumber(phone)}
                    {leadData?.phone?.length > 0 &&
                      index !== leadData?.phone?.slice(0, 3)?.length - 1 &&
                      ","}
                  </span>
                ))}
              {leadData?.phone?.length > 3 && "..."}
            </div>
          </div>
        </div>
        <div>
          {leadTypes[leadData?.leadType] && (
            <span
              className={`bg-white p-1 border border-gray-300  ${
                leadData?.categories?.title ? "rounded-l-md" : "rounded-md"
              }`}
            >
              {leadTypes[leadData?.leadType]}
            </span>
          )}
          {leadData?.categories?.title && (
            <span
              className={`p-1 border rounded-r-md text-sm`}
              style={{
                borderBlockColor: leadData?.categories?.color,
                color: leadData?.categories?.textColor,
                backgroundColor: leadData?.categories?.color,
              }}
            >
              {leadData?.categories?.title}
            </span>
          )}
          {isLeadClear ? (
            <button
              className="ml-4 text-red-500 rounded-md hover:text-red-600"
              onClick={() => {
                setLeadData({});
                setLeadList([]);
                setFieldValue(name, "");
                setFieldValue("agent", "");
              }}
            >
              clear
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
  return (
    <div className="relative">
      <label
        className={`block text-sm font-medium leading-6 text-gray-900 mb-2`}
      >
        Lead
      </label>
      <div className="flex relative text-gray-600 !text-xs">
        <input
          className="border-1 border-gray-300 bg-white px-2 pr-16 text-xs rounded w-full focus:border-gray-700 focus:ring-gray-700 placeholder:text-sm"
          type="text"
          name={name}
          autoComplete="off"
          placeholder={"Search lead by name, email, or phone"}
          value={searchValue}
          onChange={(e) => handleChange(e?.target?.value)}
          onBlur={formik.handleBlur}
          ref={autoFocusRef}
        />
        {!!searchValue && (
          <img
            alt="archive"
            src={crossIcon}
            className="h-5 w-5 hover:cursor-pointer absolute right-0 top-0 mt-[7px] mr-10"
            onClick={clearSearch}
          />
        )}
        <img
          alt="archive"
          src={searchIcon}
          className="h-5 w-5 absolute right-0 top-0 mt-[7px] mr-4"
          onClick={() => {}}
        />
      </div>
      {formik?.touched?.[name] &&
        formik.errors?.[name] &&
        searchValue?.length === 0 && (
          <div className="text-red-500 text-sm mt-[1px]">
            {formik.errors?.[name]}
          </div>
        )}

      <div>
        {isLeadListLoading || isLeadListFetching ? (
          <div className="absolute bg-orange-50 mt-2 w-[100%] rounded border-2 p-2 text-xs shadow-sm shadow-gray-200 z-50">
            Searching....
          </div>
        ) : isValidArray(LeadList) && searchValue?.length > 0 ? (
          <div className="absolute bg-gray-100 w-[100%] mt-2 rounded max-h-72 z-50">
            <ul
              className="max-h-72 overflow-y-auto cursor-pointer shadow-md"
              onScroll={(e) => e.preventDefault()}
            >
              {LeadList?.map((lead, index) => (
                <li
                  key={lead?._id}
                  id={`lead-item-${index}`} // Add an id for scrolling
                  onClick={() => handleSelectLead(lead)}
                  onMouseEnter={() => setActiveIndex(index)}
                  className={`border text-xs min-h-12 p-2 ${
                    activeIndex === index ? "bg-orange-200" : "bg-orange-50"
                  } hover:bg-orange-200`}
                  onKeyDown={(e) => handleKeyDown(e)} // Handle key events here
                  tabIndex={0} // Allow the li element to be focusable
                >
                  <div className="flex justify-between">
                    <div className="space-x-1 hover:underline">
                      <span className=" text-gray-600">
                        {getHighlightedText(lead?.firstName, searchValue)}
                      </span>
                      <span className=" text-gray-600">
                        {getHighlightedText(lead?.lastName, searchValue)}
                      </span>
                    </div>
                    <div>
                      {getHighlightedText(
                        lead?.phone?.map((phone) => formatPhoneNumber(phone)),
                        searchValue
                      )}
                    </div>
                  </div>
                  <div className="line-clamp-3">
                    {lead?.email.map((email, index) => (
                      <p key={index}>
                        {getHighlightedText(email, searchValue)}
                      </p>
                    ))}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          searchValue?.length > 0 &&
          !isLeadListLoading && (
            <div className="absolute bg-gray-100 mt-2 min-w-[100%] max-w-[500px] rounded border-2 p-2 text-xs">
              No Results
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default SearchLeadSelect;
