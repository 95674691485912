/* eslint-disable import/no-anonymous-default-export */
export default {
  control: {
    backgroundColor: "#f3f4f6",
    fontSize: 14,
    // color: "#000",
    borderRadius: "0.675rem", // Rounded corners
    boxShadow: "none", // Remove the box shadow

    borderColor: "#ccc", // Default border color
    "&:focus": {
      borderColor: "#000", // Black border color on focus
    },
  },

  "&multiLine": {
    control: {
      minHeight: 60,
    },
    highlighter: {
      padding: 9,
      border: "1px solid transparent",
    },
    input: {
      padding: 9,
      border: "1px solid silver",
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,

    highlighter: {
      padding: 1,
      border: "2px inset transparent",
    },
    input: {
      padding: 1,
      border: "2px inset",
    },
  },

  suggestions: {
    list: {
      backgroundColor: "#fff",
      border: "1px solid rgba(0, 0, 0, 0.15)",
      fontSize: 14,
      padding: 6,
      fontWeight: 300,
      marginBottom: "10px",
      maxHeight: "200px",
      overflowY: "scroll",
      overflowx: "hidden",
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
      "&focused": {
        backgroundColor: "#4ade80",
      },
    },
  },
};
