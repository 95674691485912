import React from "react";

const Skeleton = ({ className = "my-5", counter, height }) => {
  // Create an array based on the counter to determine how many skeleton items to render.
  const skeletonItems = new Array(counter ? counter : 1).fill(0);

  return (
    <div
      className={`skeleton-container ${
        className ? className : ""
      } animate-pulse`}
    >
      <div>
        {skeletonItems?.map((item, index) => (
          <div
            key={index}
            style={{ height: height ? height : "300px" }}
            className={`skeleton-item bg-gray-300 rounded-md p-4 cursor-progress ${className}`}
          >
            {/* You can add additional content or customize the skeleton item as needed */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Skeleton;
