import React, { useEffect, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import MagicUrl from "quill-magic-url";

Quill.register("modules/magicUrl", MagicUrl); // Register the MagicUrl module when the component mounts

// Configuration for the Quill editor toolbar
const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, true] }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"], // Button to remove formatting
    ["code-block"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    [{ align: [] }],
    ["clean"],
  ],
  clipboard: {
    matchVisual: true, // Paste settings
  },
  magicUrl: {
    // Regex used to check URLs during typing
    urlRegularExpression: /unsubscribeLink/g,
    // Regex used to check URLs on paste
    globalRegularExpression: /unsubscribeLink/g,
  },
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "code-block",
  "color",
  "background",
  "script",
  "align",
];

const QuillEditor = ({
  formik = {},
  name = "",
  toolbar = true,
  forwardedRef,
}) => {
  const [value, setValue] = useState(formik.values[name] || ""); // State to manage editor value
  const [isFocus, setIsFocus] = useState(false); // State to manage focus styling
  const [initialFocus, setInitialFocus] = useState(true); // State to track initial focus

  // Update formik value and local state on content change
  const handleChange = (value) => {
    const replacedString = value?.replace(
      'href="unsubscribeLink"',
      'href="{{unsubscribeLink}}"'
    );
    formik.setFieldValue(name, replacedString);
    setValue(replacedString);
  };

  // Sync local state with Formik value on mount or name change
  useEffect(() => {
    setValue(formik?.values[name] ?? "");
  }, [formik.values[name]]);

  // Handle blur event
  const handleBlur = () => {
    setInitialFocus(false);
    if (!initialFocus) {
      formik.setFieldTouched(name, true); // Mark the field as touched in Formik
      handleChange(formik.values[name] || ""); // Update value on blur
    }
    setIsFocus(false);
  };

  return (
    <div>
      <ReactQuill
        name={name}
        ref={forwardedRef}
        value={value ?? ""}
        className={`!rounded-md shadow-md border border-gray-300 ${
          isFocus ? "border-gray-700 ring-inset ring-[#475361] ring-1" : ""
        }`}
        modules={toolbar ? modules : { toolbar: false }}
        formats={formats}
        defaultValue={formik.values[name] ?? ""}
        onChange={handleChange}
        onFocus={() => setIsFocus(true)} // Handle focus event
        onBlur={handleBlur}
      />
    </div>
  );
};

export default QuillEditor;
