import { isNotEmptyString, isValidObject, showErrorMsg } from "Modules/util";
import { useEffect, useRef, useState } from "react";

export default function LeadNameEditModal(props) {
  const { leadFirstName, leadLastName, handleSubmit, outSideFocus } = props;
  const [isEditLeadName, setIsEditLeadName] = useState(false);
  const [editedValue, setEditedValue] = useState({
    firstName: leadFirstName,
    lastName: leadLastName,
  });
  const popupRef = useRef(null);

  useEffect(() => {
    setEditedValue({
      firstName: leadFirstName,
      lastName: leadLastName,
    });
  }, [leadFirstName, leadLastName, isEditLeadName]);

  useEffect(() => {
    // Add event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", listener);
    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", listener);
    };
  }, [editedValue, leadFirstName, leadLastName]);

  // Handle press Enter or NumPadEnter button on keyboard
  const listener = (event) => {
    if (
      (event.code === "Enter" || event.code === "NumpadEnter") &&
      isValidObject(editedValue)
    ) {
      if (
        leadFirstName?.trim() !== editedValue?.firstName?.trim() ||
        leadLastName?.trim() !== editedValue?.lastName?.trim()
      ) {
        saveEditedData();
      }
      setIsEditLeadName(false);
    }
  };

  // Handle click outside to close input box
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event?.target)) {
      if (
        leadFirstName?.trim() !== editedValue?.firstName?.trim() ||
        leadLastName?.trim() !== editedValue?.lastName?.trim()
      ) {
        saveEditedData();
      }
      setIsEditLeadName(false);
    }
  };

  // Submit edited data
  function saveEditedData() {
    if (
      isNotEmptyString(editedValue?.firstName) &&
      isNotEmptyString(editedValue?.lastName)
    ) {
      handleSubmit(editedValue);
    } else {
      showErrorMsg("Lead name can not be empty");
    }
  }

  useEffect(() => {
    if (outSideFocus && isEditLeadName) {
      handleClickOutside();
    }
  }, [outSideFocus]);

  return (
    <div>
      <span
        className="text-xl font-medium !line-clamp-1 text-white hover:cursor-pointer"
        onDoubleClick={() => setIsEditLeadName(true)}
      >
        {leadFirstName + " " + leadLastName}
      </span>
      {isEditLeadName ? (
        <div
          className="flex absolute p-2 !h-16 !bg-white border-2 border-blue-400 shadow-lg z-40"
          onClick={(e) => {
            e.stopPropagation(); // Prevent event from propagating up
          }}
          ref={popupRef}
        >
          <div className="mr-2">
            <label className="mt-2 mb-1 text-xs text-gray-700">
              First Name
            </label>
            <input
              type="text"
              onChange={(e) =>
                setEditedValue({
                  ...editedValue,
                  firstName: e.target.value?.trim(),
                })
              }
              defaultValue={leadFirstName}
              placeholder="Fast Name"
              className="w-40 flex-auto border rounded-sm h-6 text-sm px-3 outline-gray-300 focus:border-2 focus:border-gray-600 !ring-0 focus:outline-none block"
            />
          </div>
          <div>
            <label className="mt-2 mb-1 text-xs text-gray-700">Last Name</label>
            <input
              type="text"
              className="w-40 flex-auto border rounded-sm h-6 text-sm px-3 outline-gray-300 focus:border-2 focus:border-gray-600 !ring-0 focus:outline-none block"
              onChange={(e) =>
                setEditedValue({
                  ...editedValue,
                  lastName: e.target.value?.trim(),
                })
              }
              defaultValue={leadLastName}
              placeholder="Last Name"
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
